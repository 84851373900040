import SVGInject from "@iconfu/svg-inject"
import dayjs from "dayjs"
import { useContext, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import calendar from "../../../../assets/images/calendar-home.svg"
import close from "../../../../assets/images/close.svg"
import key from "../../../../assets/images/key.svg"
import email from "../../../../assets/images/mail.svg"
import phone from "../../../../assets/images/phone.svg"
import { AuthContext } from "../../../../context/AuthProvider"
import { BankingNavigationContext } from "../../../../context/BankingNavigationContext"
import { useResposive } from "../../../../hooks/useResponsive"
import services from "../../../../services/svcmesh"
import { ClientePfStatus } from "../../../../utils/constants"
import { CustomModal, PrimaryButton } from "k4n-components"
import Extrato from "../extrato"
import HomeBankingMobile from "./mobile"
import "./styles.scss"

require(`dayjs/locale/pt-br`)

const HomeBanking = () => {
  const [showServiceChannel, setShowServiceChannel] = useState(false)
  const [showDigitalSignature, setShowDigitalSignature] = useState(false)
  const navigate = useNavigate()
  const { user, hasDigitalSignature } = useContext(AuthContext)
  const { isTabletOrMobile } = useResposive()

  useEffect(
    function () {
      if (user && !hasDigitalSignature) {
        setTimeout(function () {
          setShowDigitalSignature(true)
        }, 1000)
      }
    },
    [user],
  )

  useEffect(function () {
    const setClientStatus = async (cpf: string) => {
      await services.bankingService.setClientPfStatus4(cpf)
    }
    if (user && user.status) {
      if (Number(user.status) === Number(ClientePfStatus.FIRST_LOGIN)) {
        setClientStatus(user.cpf)
      }
    }
  }, [])

  return (
    <>
      {isTabletOrMobile ? (
        <>
          <CustomModal
            isOpen={showDigitalSignature}
            onClose={() => {
              setShowDigitalSignature(false)
            }}
          >
            <div style={{ width: `120px`, margin: `0 auto` }}>
              <img
                src={key}
                alt="/"
                width="120"
                height="120"
                className="key-vector"
                onLoad={(e) => SVGInject(e.target)}
              />
            </div>
            <h1 className="digital-signature-h1">
              Crie agora sua assinatura digital <br /> e aproveite todos os
              recursos de sua conta!
            </h1>
            <div className="flex-center mt-3">
              <Link style={{ textDecoration: `none` }} to="/digital-signature">
                <button
                  onClick={() => setShowDigitalSignature(false)}
                  className="home-bold-btn"
                >
                  Criar
                </button>
              </Link>
            </div>
          </CustomModal>
          <div className="home-mobile-wrapper">
            <HomeBankingMobile />
          </div>
        </>
      ) : (
        <div className="home-banking-wrapper">
          <CustomModal
            isOpen={showDigitalSignature}
            onClose={() => {
              setShowDigitalSignature(false)
            }}
            isBanking={true}
          >
            <div style={{ width: `120px`, margin: `0 auto` }}>
              <img
                src={key}
                alt="/"
                width="120"
                height="120"
                className="key-vector"
                onLoad={(e) => SVGInject(e.target)}
              />
            </div>
            <h1 className="digital-signature-h1">
              Crie agora sua assinatura digital <br /> e aproveite todos os
              recursos de sua conta!
            </h1>
            <div className="flex-center mt-3">
              <Link style={{ textDecoration: `none` }} to="/digital-signature">
                <button
                  onClick={() => setShowDigitalSignature(false)}
                  className="home-bold-btn"
                >
                  Criar
                </button>
              </Link>
            </div>
          </CustomModal>
          {showServiceChannel === false ? (
            <>
              <div className="home-banking-card">
                <img
                  className="home-banking-card-img"
                  src={calendar}
                  onLoad={(e) => SVGInject(e.target)}
                />
                <h1 className="home-banking-card-h1">
                  {`Hoje, ` +
                    dayjs().locale(`pt-br`).format(`D [de] MMMM [de] YYYY`)}
                </h1>
              </div>
              <div className="home-banking-content">
                <h1 className="home-banking-content-h1">Histórico</h1>
                <div className="home-banking-content-extrato">
                  <Extrato isHome={true} />
                </div>
                <div className="home-align-btn">
                  <PrimaryButton
                    width={`279px`}
                    height={`60px`}
                    onClick={() => navigate(`/banking/extrato`)}
                  >
                    Ver histórico completo
                  </PrimaryButton>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="home-banking-card space-between">
                <h1 className="home-banking-service-channel-h1">
                  Canais de atendimento
                </h1>
                <img
                  className="home-banking-img-close"
                  src={close}
                  alt="/"
                  onClick={() => setShowServiceChannel(false)}
                  // onLoad={(e) => SVGInject(e.target)}
                />
              </div>
              <div className="home-banking-content">
                <h1 className="service-channel-content-h1">
                  Nossos canais de atendimento
                </h1>
                <div className="inline-flex">
                  <div className="service-channel-card">
                    <h1 className="service-channel-card-h1">
                      Nossos telefones
                    </h1>
                    <div className="service-channel-card-img-txt">
                      <img
                        className="service-channel-card-img"
                        src={phone}
                        alt="/"
                        // onLoad={(e) => SVGInject(e.target)}
                      />
                      <h1 className="service-channel-card-txt">
                        (11) 2366-0816
                      </h1>
                    </div>
                    <div className="service-channel-card-border">
                      <h1 className="service-channel-card-txt">
                        Atendimento de segunda a sexta, dás 9:00 ás 16:00
                      </h1>
                    </div>
                  </div>
                  <div className="service-channel-card">
                    <h1 className="service-channel-card-h1">E-mail</h1>
                    <div className="service-channel-card-img-txt">
                      <img
                        className="service-channel-card-img"
                        src={email}
                        alt="/"
                        // onLoad={(e) => SVGInject(e.target)}
                      />
                      <h1 className="service-channel-card-txt">
                        contato@kikkin.com.br
                      </h1>
                    </div>
                    <div className="service-channel-card-border">
                      <h1 className="service-channel-card-txt">
                        Atendimento de segunda a sexta, dás 9:00 ás 16:00
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}
export default HomeBanking
