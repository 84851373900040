import { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import CheckFailed from "../../../../assets/images/check-failed.svg"
import { AuthContext } from "../../../../context/AuthProvider"
import services from "../../../../services/svcmesh"
import "./styles.scss"

export interface Props {
  handleSuccess: () => void
}

const DigitalSignature: React.FC<Props> = ({ handleSuccess }) => {
  const { user, addHasDigitalSignature } = useContext(AuthContext)
  const [errorDescription, setErrorDescription] = useState(``)
  const [isLoading, setIsLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data: any) => {
    setIsLoading(true)
    if (!user) alert(`User not logged in.`)
    else {
      try {
        const sanitizeCpf = user[`cpf`].replaceAll(`.`, ``).replace(`-`, ``)
        const res = await services.digitalSignatureService.srpAuth(
          sanitizeCpf,
          data.signature,
        )
        if (res && res.data && res.data.M2) {
          setIsLoading(false)
          reset()
          handleSuccess()
        }
      } catch (error: any) {
        const err = error
        // setErrorDescription(err?.response?.data?.message)
        setErrorDescription(
          `Não foi possível autorizar a transação, verifique a senha e tente novamente.`,
        )
        setIsLoading(false)
      }
    }
  }

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const re = /\D/
    if (re.test(event.target.value)) {
      return true
    } else return false
  }

  return (
    <>
      {/* <div className="signature-transaction-container"> */}
      <div className="signature-center-div">
        <h1 className="signature-title">
          Preencha sua assinatura digital numérica
        </h1>
      </div>
      {showSuccess ? (
        <div className="signature-transaction-form">
          <Link style={{ textDecoration: `none` }} to="/banking">
            <button className="signature-transaction-back">
              Voltar para o banking
            </button>
          </Link>
        </div>
      ) : (
        <form className="signature-form" onSubmit={handleSubmit(onSubmit)}>
          <input
            type="password"
            onInput={handleInput}
            placeholder="Digite sua senha"
            maxLength={8}
            className="signature-transaction-input"
            {...register(`signature`, {
              required: { value: true, message: `Preencha sua senha` },
              minLength: {
                value: 8,
                message: `Sua senha deve ter pelo menos 8 dígitos`,
              },
            })}
          />
          {errors.signature && (
            <div>
              <img
                src={CheckFailed}
                className="signature-transaction-error-check"
                alt=">"
              />
              <span className="signature-transaction-form-error-txt">
                {errors.signature?.message as string}
              </span>
            </div>
          )}

          <span className="signature-transaction-span-error">
            {errorDescription !== `` && errorDescription}
          </span>

          <button
            disabled={isLoading}
            className="signature-transaction-btn-next"
            type="submit"
          >
            {isLoading ? (
              <div className="signature-spinner" role="status"></div>
            ) : (
              <span>Autorizar</span>
            )}
          </button>
        </form>
      )}
      {/* </div> */}
    </>
  )
}
export default DigitalSignature
