import { useEffect, useState } from "react"
import { Search } from "lucide-react"
import { StepIndicator } from "../StepIndicator/step-indicator"
import { UserProfileStep2 } from "./user-profile-step2"
import { Switch } from "../../../../features/Switch/switch"
import "./styles.scss"
import { PaginationV2 } from "../../../../features/PaginationV2"
import { useNavigate, useParams } from "react-router-dom"
import Input from "../../../../features/Input/index"
import Header from "../../../../features/Header"
import services from "../../../../../services/svcmesh"
import { OperadorPj } from "k4n-svcmesh-sdk"
import Spinner from "../../../../features/Spinner"
import ErrorModal from "../../../../features/ErrorModal"

export const NewUser = () => {
  const { id } = useParams()
  const [CPFError, setCPFError] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [resultsPerPage, setResultsPerPage] = useState(10)
  const [openModalError, setOpenModalError] = useState(false)
  const [requestError, setRequestError] = useState<any>()
  const [profiles, setProfiles] = useState([] as any)
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({
    id: 0,
    // Step 1 data
    fullName: ``,
    email: ``,
    cpf: ``,
    phone: ``,
    searchGroup: ``,
    // Step 2 data
    profileType: `existing` as `existing` | `new`,
    selectedProfile: ``,
  })

  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      setIsLoading(true)
      services.bankingService
        .getOperatorByOidcId(id)
        .then((response) => {
          const userData2 = response.data
          console.log(`User data com params:`, userData2)
          setFormData((prev) => ({
            ...prev,
            id: userData2.id,
            fullName: userData2.nome,
            email: userData2.emails[0]?.email || ``,
            cpf: userData2.cpf,
            phone: userData2.phones[0]?.phone || ``,
            profileType: `existing`,
            selectedProfile: userData2.role || ``,
          }))
        })
        .catch((error) => console.error(`Erro ao buscar usuário:`, error))
        .finally(() => setIsLoading(false))
    }
  }, [id])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))

    /*
    if(name === "phone") {
      const isValidPhone = (phone: string) => {
        const phoneRegex = /^\([1-9]{2}\) [0-9]{4,5}-[0-9]{4}$/
        if (!phoneRegex.test(phone)) {
          console.log("Telefone inválido")
        }
      }
      isValidPhone(value)
    }
    */

    if (name === `cpf`) {
      const isValidCpf = (cpf: string) => {
        cpf = cpf.replace(/[^\d]+/g, ``)
        if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false
        let sum = 0
        let remainder
        for (let i = 1; i <= 9; i++)
          sum += parseInt(cpf.substring(i - 1, i)) * (11 - i)
        remainder = (sum * 10) % 11
        if (remainder === 10 || remainder === 11) remainder = 0
        if (remainder !== parseInt(cpf.substring(9, 10))) return false
        sum = 0
        for (let i = 1; i <= 10; i++)
          sum += parseInt(cpf.substring(i - 1, i)) * (12 - i)
        remainder = (sum * 10) % 11
        if (remainder === 10 || remainder === 11) remainder = 0
        if (remainder !== parseInt(cpf.substring(10, 11))) return false
        return true
      }

      if (!isValidCpf(value)) {
        setCPFError(true)
      } else {
        setCPFError(false)
      }
    }
  }

  const handleProfileTypeChange = (type: `existing` | `new`) => {
    setFormData((prev) => ({
      ...prev,
      profileType: type,
    }))
  }

  const handleProfileSelect = (profile: string) => {
    setFormData((prev) => ({
      ...prev,
      selectedProfile: profile,
    }))
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (currentStep === 1) {
      setCurrentStep(2)
    } else {
      console.log(`Form submitted:`, formData)
      setIsLoading(true)
      const payload: OperadorPj | any = {
        nome: formData.fullName,
        role: formData.selectedProfile,
        emails: [
          {
            email: formData.email,
          },
        ],
        cpf: formData.cpf,
        phones: [
          {
            phone: formData.phone,
          },
        ],
        status: 1,
        // Add other required properties with appropriate values
      }
      try {
        if (!id) {
          const response = await services.bankingService
            .createOperator2(payload)
            .then((res) => {
              console.log(res)
              setIsLoading(false)
              navigate(`/operators-management`)
            })
        } else {
          const response = await services.bankingService
            .updateOperator(formData?.id, payload)
            .then((res) => {
              console.log(res)
              setIsLoading(false)
              navigate(`/operators-management`)
            })
        }
      } catch (error) {
        setIsLoading(false)
        setOpenModalError(true)
        setRequestError((error as any)?.response?.data)
        console.log(error)
      }
    }
  }

  const handleBack = () => {
    if (currentStep === 2) {
      setCurrentStep(1)
    }
    if (currentStep === 1) {
      navigate(-1)
    }
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    // Here you would typically fetch new data based on the new page
  }

  const handleResultsPerPageChange = (value: number) => {
    setResultsPerPage(value)
    setCurrentPage(1) // Reset to first page when changing results per page
    // Here you would typically fetch new data based on the new resultsPerPage
  }

  const [switchStates, setSwitchStates] = useState<{ [key: number]: boolean }>(
    {},
  )

  useEffect(() => {
    const initialState = mockGroups.reduce((acc, group) => {
      acc[group.id] = false
      return acc
    }, {} as { [key: number]: boolean })

    setSwitchStates(initialState)
  }, [])

  const handleSwitchChange = (id: string | number, isChecked: boolean) => {
    setSwitchStates((prev) => ({
      ...prev,
      [id]: isChecked,
    }))
  }

  const mockGroups = [
    {
      id: 1,
      name: `ATENA SOLUCOES INFORMATICA LTDA`,
    },
    {
      id: 2,
      name: `ATENA SOLUCOES LTDA`,
    },
    {
      id: 3,
      name: `ATENA INFORMATICA LTDA`,
    },
    {
      id: 4,
      name: `ATENA LTDA`,
    },
  ]

  const filteredGroups = mockGroups.filter((group) =>
    group.name.toLowerCase().includes(formData.searchGroup.toLowerCase()),
  )

  const handleGetProfile = async () => {
    try {
      const profiles =
        await services.bankingService.getAuthorizationProfilesForAccount()
      setProfiles(profiles.data)
    } catch (error) {
      console.error(`Erro ao buscar perfis: `, error)
    }
  }

  useEffect(() => {
    handleGetProfile()
  }, [])

  useEffect(() => {
    console.log(`profiles: `, profiles)
  }, [profiles])

  const renderStep1 = () => (
    <>
      <div className="form-header">
        <h1 className="title">Novo usuário</h1>
        <StepIndicator currentStep={currentStep} totalSteps={2} />
      </div>
      <div className="header-divider" />

      <div className="form-row">
        <div className="form-group">
          <Input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleInputChange}
            required
            label="Nome completo"
          />
        </div>

        <div className="form-group">
          <Input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            label="E-mail"
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group">
          <div>
            <Input
              type="text"
              id="cpf"
              name="cpf"
              value={formData.cpf}
              onChange={handleInputChange}
              required
              label="CPF"
              htmlFor="cpf"
              disabled={!!id}
            />
            {CPFError && (
              <p
                style={{
                  color: `red`,
                  fontSize: `12px`,
                  marginTop: `0`,
                  marginLeft: `10px`,
                  opacity: `0.8`,
                }}
              >
                CPF inválido
              </p>
            )}
          </div>
        </div>

        <div className="form-group">
          <Input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            required
            label="Celular"
            htmlFor="phone"
          />
        </div>
      </div>

      {/*
      <div className="form-group">
        <Input
          type="date"
          id="validityDate"
          name="validityDate"
          onChange={handleInputChange}
          value={formData.validityDate}
          label="Data limite vigência"
          htmlFor="validityDate"
          required
        />
      </div>
    
      <div className="group-section">
        <h2 className="subtitle">Definição do grupo</h2>
        <p className="description">
          Selecione a quais grupos o usuário a ser cadastrado terá acesso
        </p>

        <div className="search-group">
          <input
            type="text"
            name="searchGroup"
            value={formData.searchGroup}
            onChange={handleInputChange}
            placeholder="Procurar grupo"
            className="search-input"
          />
        </div>

        <div className="company-list">
          <div className="company-item">
            <div className="company-info">
              {filteredGroups.map((group) => (
                <div key={group.id} className="company-item">
                  <span className="company-name">{group.name}</span>
                  <Switch id={group.id} onChange={handleSwitchChange} />
                </div>
              ))}
            </div>

            <PaginationV2
              currentPage={currentPage}
              totalPages={5}
              onPageChange={handlePageChange}
              totalResults={50}
              resultsPerPage={resultsPerPage}
              onResultsPerPageChange={handleResultsPerPageChange}
            />
          </div>
        </div>
      </div>
      */}
    </>
  )

  return (
    <>
      <Header />
      <div className="newuser-container user-management">
        <form onSubmit={handleSubmit} className="form">
          {currentStep === 1 ? (
            renderStep1()
          ) : (
            <UserProfileStep2
              formData={formData}
              onProfileTypeChange={handleProfileTypeChange}
              onProfileSelect={handleProfileSelect}
              profiles={profiles}
            />
          )}

          <div className="form-actions">
            <div className="btn-back">
              <button
                type="button"
                className="button-secondary"
                onClick={handleBack}
              >
                VOLTAR
              </button>
            </div>
            <div className="continue-btn">
              <button
                disabled={isLoading}
                type="submit"
                className="button-primary"
              >
                {isLoading ? (
                  <Spinner />
                ) : currentStep === 1 ? (
                  `CONTINUAR`
                ) : (
                  `SALVAR USUÁRIO`
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      <ErrorModal
        isOpen={openModalError}
        onClose={() => setOpenModalError(false)}
        width="500px"
        height="auto"
      >
        <div className="error-message">
          <h2>Erro ao salvar usuário</h2>
          <p>
            Ocorreu um erro ao tentar salvar o usuário. Por favor, tente
            novamente.
          </p>
          {requestError && <p>Erro: {requestError?.message}</p>}
        </div>
      </ErrorModal>
    </>
  )
}
