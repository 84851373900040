/* eslint-disable */
import { useState } from "react"
import { ChevronDown, TriangleAlert } from "lucide-react"
import { Transaction } from ".."

interface TransactionsTableProps {
  onSelectionChange: (count: number) => void
  searchTerm: string
  selectAll: boolean
  transactions: Transaction[]
  isFetching: boolean
  setSelectedRows: (rows: Transaction[]) => void
  selectedRows: Transaction[]
  activeTab: string
  setModalErrorMessage: (message: string) => void
  setModalException: (exception: boolean) => void
}

export const TransactionsTable = ({
  onSelectionChange,
  searchTerm,
  selectAll,
  transactions,
  isFetching,
  setSelectedRows,
  selectedRows,
  activeTab,
  setModalErrorMessage,
  setModalException,
}: TransactionsTableProps) => {
  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedRows(transactions) // Seleciona todas as transações
      onSelectionChange(transactions.length)
    } else {
      setSelectedRows([]) // Reseta a seleção
      onSelectionChange(0)
    }
  }

  const handleSelectRow = (transaction: Transaction, checked: boolean) => {
    const alreadySelected = selectedRows.some((t) => t.id === transaction.id)

    const newSelection = checked
      ? [...selectedRows, transaction] // Adiciona se não estiver na lista
      : selectedRows.filter((t) => t.id !== transaction.id) // Remove se estiver na lista

    setSelectedRows(newSelection)
    onSelectionChange(newSelection.length)
  }

  const isAllSelected =
    transactions.length > 0 && selectedRows.length === transactions.length

  const filteredRows = transactions.filter((transaction) =>
    transaction.requester.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const getStatusText = (approvalStatus: number, status) => {
    switch (approvalStatus) {
      case 1:
        return `Finalizado`
      case 3:
        return `Em processamento`
      case 4:
        return `Reprovado`
      case 5:
        return `Erro no Processamento`
      default:
        return status
    }
  }

  const openWarningModal = (message: string) => {
    setModalErrorMessage(message)
    setModalException(true)
  }

  return (
    <table className="transactions-table">
      <thead>
        <tr>
          {activeTab === `completed` || activeTab === `inProgress` ? null : (
            <th className="checkbox-column">
              <div className="cb-all-container">
                <input
                  type="checkbox"
                  id="selectAllCheck"
                  checked={isAllSelected}
                  onChange={(e) => handleSelectAll(e.target.checked)}
                />
                <label htmlFor="selectAllCheck"></label>
              </div>
            </th>
          )}
          <th>
            <div className="column-header">
              <span>Tipo de transação</span>
              <ChevronDown size={16} />
            </div>
          </th>
          <th>
            <div className="column-header">
              <span>Solicitação</span>
              <ChevronDown size={16} />
            </div>
          </th>
          <th>
            <div className="column-header">
              <span>Conta débito</span>
              <ChevronDown size={16} />
            </div>
          </th>
          <th>
            <div className="column-header">
              <span>Pagamento previsto</span>
              <ChevronDown size={16} />
            </div>
          </th>
          <th>
            <div className="column-header">
              <span>Valor total</span>
              <ChevronDown size={16} />
            </div>
          </th>
          <th>
            <div className="column-header">
              <span>Status</span>
              <ChevronDown size={16} />
            </div>
          </th>
          <th className="arrow-column"></th>
        </tr>
      </thead>
      <tbody>
        {filteredRows.map((transaction) => (
          <tr key={transaction.id}>
            {activeTab === `completed` || activeTab === `inProgress` ? null : (
              <td className="checkbox-column">
                <div className="cb-container">
                  <input
                    type="checkbox"
                    id={`transaction-${transaction.id}`}
                    checked={selectedRows.some((t) => t.id === transaction.id)}
                    onChange={(e) =>
                      handleSelectRow(transaction, e.target.checked)
                    }
                  />
                  <label htmlFor={`transaction-${transaction.id}`}></label>
                </div>
              </td>
            )}
            <td>
              <div className="transaction-info">
                <span className="primary-info">{transaction.type}</span>
                <span className="secondary-info">
                  {transaction.destinatary}
                </span>
              </div>
            </td>
            <td>
              <div className="transaction-info">
                <span className="primary-info">{transaction.requestDate}</span>
                <span className="secondary-info">{transaction.requester}</span>
              </div>
            </td>
            <td className="account-column">{transaction.accountNumber}</td>
            <td className="date-column">{transaction.paymentDate}</td>
            <td className="amount-column">{transaction.amount}</td>
            <td>
              <span className="status-badge">
                {getStatusText(transaction.approvalStatus, transaction.status)}
              </span>
            </td>
            {transaction.approvalStatus === 5 && (
              <td className="warning-approval-column">
                <TriangleAlert
                  className="approval-warning-icon"
                  onClick={() =>
                    openWarningModal(transaction.notCompletedReason)
                  }
                />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
