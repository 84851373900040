import "./styles.scss"
import { useContext, useEffect } from "react"
import SVGInject from "@iconfu/svg-inject"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import close from "../../../../../../assets/images/clear_x.svg"
import arrow from "../../../../../../assets/images/arrow_yellow.svg"
import cell from "../../../../../../assets/images/cell.svg"
import userImg from "../../../../../../assets/images/user.svg"
import mail from "../../../../../../assets/images/mail_yellow.svg"
import random_key from "../../../../../../assets/images/random_key.svg"
import { PixStorageContext } from "../../../../../../context/PixStorageProvider"
import { PrimaryButton } from "k4n-components"
import ErrorModal from "../../../../../features/ErrorModal"
import SuccessModal from "../../../../../features/SuccesModal"

const PixRegisterMobile = ({
  showRegister,
  setShowRegister,
  pixKeyType,
  setPixKeyType,
  defaultValuesPix,
  handleCreatePixKey,
  btnLoading,
  showModal,
  showModalError,
  errorRegister,
}) => {
  const { changePixEnvironment } = useContext(BankingNavigationContext)
  const { userPixKeys } = useContext(PixStorageContext)

  useEffect(() => {
    console.log(`MODAL ERROR ->`, showModalError)
  }, [])

  return (
    <div className="pix-payment-wrapper-mobile">
      <div className="pix-payment-step1-mobile">
        {!showRegister ? (
          <>
            <span
              className="pix-receipt-img-close"
              onClick={() => changePixEnvironment(1)}
            >
              <img
                src={close}
                width="24"
                height="24"
                alt="/"
                onLoad={(e) => SVGInject(e.target)}
              />
            </span>
            <h1 className="pix-receive-title-mobile">
              Selecione uma chave <br /> para registrar
            </h1>
            <h1 className="pix-payment-step1-h1-mobile mg-top-8">
              Com sua chave você pode
              <span className="bold">
                {` `}
                transferir
                <br /> e receber {` `}
              </span>
              de qualquer conta,
              <br />
              <span className="bold">independente de banco e horário.</span>
            </h1>
            <div className="pix-panel-cards-mobile Mg-top-40">
              <div
                className="pix-panel-card-mobile"
                onClick={() => {
                  setPixKeyType(2)
                  setShowRegister(true)
                }}
              >
                <span className="pix-panel-card-img-mobile">
                  <img src={cell} alt="/" onLoad={(e) => SVGInject(e.target)} />
                </span>
                <h1 className="pix-panel-card-h1-mobile">Celular</h1>
              </div>
              <div
                className="pix-panel-card-mobile"
                onClick={() => {
                  setPixKeyType(0)
                  setShowRegister(true)
                }}
              >
                <span className="pix-panel-card-img-mobile">
                  <img
                    src={userImg}
                    alt="/"
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </span>
                <h1 className="pix-panel-card-h1-mobile">CPF</h1>
              </div>
            </div>
            <div className="pix-panel-cards-mobile mg-top-16">
              <div
                className="pix-panel-card-mobile"
                onClick={() => {
                  setPixKeyType(3)
                  setShowRegister(true)
                }}
              >
                <span className="pix-panel-card-img-mobile">
                  <img src={mail} alt="/" onLoad={(e) => SVGInject(e.target)} />
                </span>
                <h1 className="pix-panel-card-h1-mobile">E-mail</h1>
              </div>
              <div
                className="pix-panel-card-mobile"
                onClick={() => {
                  setPixKeyType(4)
                  setShowRegister(true)
                }}
              >
                <span className="pix-panel-card-img-mobile">
                  <img
                    src={random_key}
                    alt="/"
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </span>
                <h1 className="pix-panel-card-h1-mobile">Chave aleatória</h1>
              </div>
            </div>
          </>
        ) : (
          <>
            <span onClick={() => setShowRegister(false)}>
              <img
                src={arrow}
                width="27"
                height="15"
                alt="arrow"
                onLoad={(e) => SVGInject(e.target)}
              />
            </span>
            {pixKeyType === 0 && (
              <>
                <h1 className="pix-receive-title-mobile">Registrar CPF</h1>
                <h1 className="pix-payment-step1-h1-mobile mg-top-8">
                  Informe o CPF que você quer utilizar <br /> para usar o Pix.
                </h1>
                <div className="pix-register-key">
                  {userPixKeys.cpf ? (
                    <span className="pix-register-span">Ativa</span>
                  ) : (
                    <span className="pix-register-span pix-register-span-disabled">
                      Desativada
                    </span>
                  )}
                  <span className="pix-register-check-txt">
                    {defaultValuesPix.cpf}
                  </span>
                </div>
                <div className="justify-center mg-top-67">
                  {!userPixKeys.cpf && (
                    <PrimaryButton
                      width={`312px`}
                      height={`60px`}
                      onClick={() => handleCreatePixKey(defaultValuesPix.cpf!)}
                    >
                      {!btnLoading ? (
                        `Registrar chave`
                      ) : (
                        <div
                          className="spinner-border spinner-pix"
                          role="status"
                        ></div>
                      )}
                    </PrimaryButton>
                  )}
                </div>
              </>
            )}
            {pixKeyType === 2 && (
              <>
                <h1 className="pix-receive-title-mobile">Registrar Celular</h1>
                <h1 className="pix-payment-step1-h1-mobile mg-top-8">
                  Confirme o número do celular que você <br /> quer utilizar
                  para usar o Pix.
                </h1>
                <div className="pix-register-key">
                  {userPixKeys.celular ? (
                    <span className="pix-register-span">Ativa</span>
                  ) : (
                    <span className="pix-register-span pix-register-span-disabled">
                      Desativada
                    </span>
                  )}
                  <span className="pix-register-check-txt">
                    {defaultValuesPix.phone}
                  </span>
                </div>
                <div className="justify-center mg-top-67">
                  {!userPixKeys.celular && (
                    <PrimaryButton
                      width={`312px`}
                      height={`60px`}
                      onClick={() =>
                        handleCreatePixKey(defaultValuesPix.phone!)
                      }
                    >
                      {!btnLoading ? (
                        `Registrar chave`
                      ) : (
                        <div
                          className="spinner-border spinner-pix"
                          role="status"
                        ></div>
                      )}
                    </PrimaryButton>
                  )}
                </div>
              </>
            )}
            {pixKeyType === 3 && (
              <>
                <h1 className="pix-receive-title-mobile">Registrar E-mail</h1>
                <h1 className="pix-payment-step1-h1-mobile mg-top-8">
                  Confirme o e-mail que você quer utilizar <br /> para usar o
                  Pix.
                </h1>
                <div className="pix-register-key">
                  {userPixKeys.email ? (
                    <span className="pix-register-span">Ativa</span>
                  ) : (
                    <span className="pix-register-span pix-register-span-disabled">
                      Desativada
                    </span>
                  )}
                  <span className="pix-register-check-txt">
                    {defaultValuesPix.email}
                  </span>
                </div>
                <div className="justify-center mg-top-67">
                  {!userPixKeys.email && (
                    <PrimaryButton
                      width={`312px`}
                      height={`60px`}
                      onClick={() =>
                        handleCreatePixKey(defaultValuesPix.email!)
                      }
                    >
                      {!btnLoading ? (
                        `Registrar chave`
                      ) : (
                        <div
                          className="spinner-border spinner-pix"
                          role="status"
                        ></div>
                      )}
                    </PrimaryButton>
                  )}
                </div>
              </>
            )}
            {pixKeyType === 4 && (
              <>
                <h1 className="pix-receive-title-mobile">
                  Registrar chave aleatória
                </h1>
                <h1 className="pix-payment-step1-h1-mobile mg-top-8">
                  Com a chave aleatória você consegue <br />
                  receber sem compartilhar seus dados.
                </h1>
                <div className="pix-register-key">
                  {userPixKeys.evp ? (
                    <span className="pix-register-span">Ativa</span>
                  ) : (
                    <span className="pix-register-span pix-register-span-disabled">
                      Desativada
                    </span>
                  )}
                  <span className="pix-register-check-txt">
                    {userPixKeys.evp}
                  </span>
                </div>
                <div className="justify-center mg-top-67">
                  {!userPixKeys.evp && (
                    <PrimaryButton
                      width={`312px`}
                      height={`60px`}
                      onClick={() => handleCreatePixKey(defaultValuesPix.evp!)}
                    >
                      {!btnLoading ? (
                        `Registrar chave`
                      ) : (
                        <div
                          className="spinner-border spinner-pix"
                          role="status"
                        ></div>
                      )}
                    </PrimaryButton>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
      <SuccessModal
        isOpen={showModal}
        width={`100vw`}
        height={`100%`}
        paddingTop={`80px`}
        onClose={() => changePixEnvironment(6)}
      >
        <h1 className="pix-modal-sucess-h1">
          Chave gerada <br /> com sucesso!
        </h1>
        <h1 className="pix-modal-sucess-3p">• • •</h1>
        <h1 className="pix-modal-sucess-txt">
          Tudo certo! Agora você pode começar <br /> a receber usando sua chave!
        </h1>
        <div className="pix-align-center">
          <PrimaryButton
            width={`312px`}
            height={`60px`}
            onClick={() => changePixEnvironment(6)}
          >
            OK
          </PrimaryButton>
        </div>
      </SuccessModal>
      <ErrorModal
        isOpen={showModalError}
        width={`100vw`}
        height={`100%`}
        paddingTop={`80px`}
        onClose={() => changePixEnvironment(6)}
      >
        <h1 className="pix-modal-sucess-h1">
          Ocorreu um erro ao registrar a chave!
        </h1>
        <h1 className="pix-modal-sucess-3p">• • •</h1>
        <h1 className="pix-modal-sucess-txt">{errorRegister}</h1>
        <div className="pix-align-center">
          <PrimaryButton
            width={`312px`}
            height={`60px`}
            onClick={() => changePixEnvironment(6)}
          >
            OK
          </PrimaryButton>
        </div>
      </ErrorModal>
    </div>
  )
}

export default PixRegisterMobile
