import { useState } from "react"
import { ChevronDown } from "lucide-react"
import { StepIndicator } from "../StepIndicator/step-indicator"
import "./user-profile-step2.scss"

// Mock data for permissions with categories and subcategories
const mockPermissions = {
  digitalAccount: {
    title: `Conta Digital`,
    categories: [
      {
        name: `Limites`,
        permissions: [`Gestão de limites`],
      },
      {
        name: `TED`,
        permissions: [`Aprovar TED`, `Cancelar TED`, `Incluir TED`],
      },
      {
        name: `Pagamento Boleto`,
        permissions: [
          `Aprovar pagamento`,
          `Cancelar pagamento`,
          `Incluir pagamento`,
        ],
      },
      {
        name: `Agendamentos`,
        permissions: [
          `Cancelar agendamento`,
          `Editar agendamento`,
          `Visualizar agendamento`,
        ],
      },
      {
        name: `Aprovações`,
        permissions: [`Visualizar transações`],
      },
      {
        name: `Cheque`,
        permissions: [`Compensar cheque por imagem`],
      },
      {
        name: `Boleto de Cobrança`,
        permissions: [`Emitir via arquivo CNAB`],
      },
    ],
  },
  investments: {
    title: `Investimentos`,
    categories: [
      {
        name: `Operações`,
        permissions: [
          `Realizar aplicações`,
          `Realizar resgates`,
          `Consultar investimentos`,
        ],
      },
      {
        name: `Consultas`,
        permissions: [`Visualizar extrato`, `Consultar rentabilidade`],
      },
    ],
  },
}

const perfisMock = [`CONTADOR`, `ADMINISTRADOR`, `USUARIO`]

interface UserProfileStepProps {
  formData: {
    profileType: `existing` | `new`
    selectedProfile: string
  }
  onProfileTypeChange: (type: `existing` | `new`) => void
  onProfileSelect: (profile: string) => void
  profiles: any
}

export const UserProfileStep2 = ({
  formData,
  onProfileTypeChange,
  onProfileSelect,
  profiles,
}: UserProfileStepProps) => {
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean
  }>({
    investments: false,
    digitalAccount: false,
  })

  const toggleSection = (section: string) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }))
  }

  return (
    <>
      <div className="form-header">
        <h1 className="title">Definição do perfil do usuário</h1>
        <StepIndicator currentStep={2} totalSteps={2} />
      </div>
      <div className="header-divider" />

      <p className="description">
        Os usuários da sua Conta Digital PJ terão acesso apenas às permissões
        configuradas para o perfil que você selecionar. Você também pode criar
        um novo perfil para que ele possa ser utilizado no cadastro de novos
        usuários.
      </p>

      <div className="profile-selection">
        <div className="radio-group">
          {/*<label className="radio-label">
            <input
              type="radio"
              name="profileType"
              checked={formData.profileType === `existing`}
              onChange={() => onProfileTypeChange(`existing`)}
            />
          </label>*/}
          <span>Selecionar perfis existente</span>

          {/*<label className="radio-label">
            <input
              type="radio"
              name="profileType"
              checked={formData.profileType === `new`}
              onChange={() => onProfileTypeChange(`new`)}
            />
            <span>Criar novo perfil</span>
          </label>*/}
        </div>

        <div className="profile-dropdown">
          <select
            value={formData.selectedProfile}
            onChange={(e) => onProfileSelect(e.target.value)}
            className="input"
          >
            <option value="">Selecionar perfil</option>
            {profiles.map((profile) => (
              <option key={profile} value={profile.nome.toLowerCase()}>
                {profile.nome}
              </option>
            ))}
          </select>
        </div>

        {/*<div className="permissions-section">
          <h3 className="subtitle">Permissões do perfil selecionado</h3>

          {Object.entries(mockPermissions).map(([key, section]) => (
            <div key={key} className="permission-item">
              <button
                type="button"
                className="permission-toggle"
                onClick={() => toggleSection(key)}
              >
                <ChevronDown
                  className={`icon ${expandedSections[key] ? `open` : ``}`}
                />
                <span>{section.title}</span>
              </button>
              {expandedSections[key] && (
                <div className="permission-content">
                  <div className="permission-grid">
                    {section.categories.map((category, index) => (
                      <div key={index} className="permission-category">
                        <div className="category-name">{category.name}</div>
                        {category.permissions.map((permission, permIndex) => (
                          <div key={permIndex} className="permission-row">
                            {permission}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>*/}
      </div>
    </>
  )
}
