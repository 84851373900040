import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"
import { useContext, useEffect } from "react"
import { AuthContext } from "../../../../../../context/AuthProvider"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import { TransferContext } from "../../../../../../context/TransferContext"
import { formattedCurrency } from "../../../../../../services/banking"
import { censorCnpj, censorCpf } from "../../../../../../utils/validation"
import { CustomModal, SecondaryButton, PrimaryButton } from "k4n-components"
import ErrorModal from "../../../../../features/ErrorModal"
import SuccessModal from "../../../../../features/SuccesModal"
import "./styles.scss"
import SVGInject from "@iconfu/svg-inject"
import DigitalSignature from "../../../../DigitalSignature/Form"
import key from "../../../../../../assets/images/key.svg"
import { useNavigate } from "react-router-dom"

dayjs.extend(isBetween)

const TransferStep4Mobile = ({
  digitalSignatureModal,
  setDigitalSignatureModal,
  authorize,
  submitLoading,
  showSuccessModal,
  scheduleCondition,
  setShowSuccessModal,
  failureModalAlert,
  setFailureModalAlert,
  customModalAlert,
  setCustomModalAlert,
  setSubmitLoading,
  handleScheduledTed,
}) => {
  const { changeTransferStep, isScheduling } = useContext(
    BankingNavigationContext,
  )

  const navigate = useNavigate()

  useEffect(() => {
    console.log(isScheduling)
  }, [isScheduling])

  const { favoriteData, transferValue, transferStep3Data } =
    useContext(TransferContext)
  const { current_account } = useContext(AuthContext)

  return (
    <div className="transfer-mobile-header">
      <CustomModal
        isOpen={digitalSignatureModal}
        onClose={() => {
          setDigitalSignatureModal(false)
        }}
        isBanking={true}
      >
        <div style={{ width: `120px`, margin: `0 auto` }}>
          <img
            src={key}
            alt="/"
            width="120"
            height="120"
            className="key-vector"
            onLoad={(e) => SVGInject(e.target)}
          />
        </div>
        <DigitalSignature handleSuccess={authorize} />
      </CustomModal>
      <div className="panel-mobile-content">
        <button
          className="transfer-mobile-close-btn"
          onClick={() => {
            navigate(`/banking`)
          }}
        ></button>
        <div className="panel-step-4-mobile-title">
          <span className="title-mobile-blue">Confirme os dados</span>
        </div>
        <div className="step-4-mobile-info-div">
          <div className="step-4-mobile-info-div-flex">
            <div className="step-4-steps-mobile-value-info">
              {/* <h1 className="info-span-bold-16">Valor a pagar</h1> */}
              <h1 className="info-span-grey-24">
                {formattedCurrency(transferValue)}
              </h1>
            </div>
            <div className="info-mobile-div">
              <h1 className="info-span-bold-16">Forma de pagamento:</h1>
              <h1 className="info-span-grey-16">
                {`Agência ${current_account?.agencia}-${
                  current_account?.agenciaDigito || `0`
                } - Conta ${current_account?.conta}-${
                  current_account?.contaDigito
                }`}
              </h1>
            </div>
          </div>
          <div className="info-mobile-div">
            <h1 className="info-span-bold-16">Data do pagamento:</h1>
            <h1 className="info-span-grey-16">
              {dayjs(transferStep3Data.date).format(`DD/MM/YYYY`)}
            </h1>
          </div>
        </div>
        <div className="transfer-mobile-contact-div">
          <div className="info-mobile-div">
            <h1 className="info-span-bold-16">Para</h1>
            <h1 className="info-span-grey-16">{favoriteData.nome}</h1>
          </div>
          <div className="info-mobile-div">
            <h1 className="info-span-bold-16">
              {favoriteData.cpf ? `CPF:` : `CNPJ:`}
            </h1>
            <h1 className="info-span-grey-16">
              {favoriteData.cpf
                ? censorCpf(favoriteData.cpf)
                : censorCnpj(favoriteData.cnpj || ``)}
            </h1>
          </div>
          <div className="info-mobile-div">
            <h1 className="info-span-bold-16">Agencia:</h1>
            <h1 className="info-span-grey-16">{`${favoriteData.agencia}-${
              favoriteData.agencia_digito || `0`
            }`}</h1>
          </div>
          <div className="info-mobile-div">
            <h1 className="info-span-bold-16">Conta:</h1>
            <h1 className="info-span-grey-16">{`${favoriteData.conta}-${favoriteData.conta_digito}`}</h1>
          </div>
          <div className="info-mobile-div">
            <h1 className="info-span-bold-16">Instituição:</h1>
            <h1 className="info-span-grey-16">
              {favoriteData.numero_banco} - {favoriteData.instituicao}
            </h1>
          </div>
        </div>
        <div className="transfer-mobile-step-3-btns-div">
          <PrimaryButton
            width={`245px`}
            height={`60px`}
            onClick={() => setDigitalSignatureModal(true)}
            disabled={submitLoading}
          >
            {submitLoading ? (
              <div
                className="spinner-border spinner-transfer"
                role="status"
              ></div>
            ) : isScheduling ? (
              `Agendar`
            ) : (
              `Confirmar`
            )}
          </PrimaryButton>
          <button className="cancel-btn" onClick={() => changeTransferStep(3)}>
            <span className="cancel-span">Voltar</span>
          </button>
        </div>
      </div>
      {showSuccessModal && (
        <SuccessModal
          isOpen={showSuccessModal}
          width={`100%`}
          height={`400px`}
          onClose={() => {
            changeTransferStep(1)
            setShowSuccessModal(false)
          }}
        >
          <h1 className="transfer-mobile-modal-sucess-h1">
            {scheduleCondition || isScheduling ? (
              <span>
                Transferência agendada com <br /> sucesso!
              </span>
            ) : (
              <span>
                Transferência realizada com <br /> sucesso!
              </span>
            )}
          </h1>
          <div className="boleto-justify-center">
            <button
              className="boleto-receipt-btn"
              onClick={() => changeTransferStep(5)}
            >
              Ver comprovante
            </button>
          </div>
        </SuccessModal>
      )}
      <ErrorModal
        isOpen={!!failureModalAlert}
        width={`100%`}
        height={`350px`}
        onClose={() => {
          setFailureModalAlert(``)
        }}
      >
        <h1 className="transfer-mobile-modal-sucess-h1">{failureModalAlert}</h1>
      </ErrorModal>
      <CustomModal
        isOpen={!!customModalAlert}
        onClose={() => {
          setCustomModalAlert(``)
        }}
      >
        <div className="modal-message transfer-mobile-custom-modal">
          {customModalAlert}
        </div>
        <div className="transfer-mobile-schedule-alert">
          <SecondaryButton
            text="Não"
            width="200px"
            height="40px"
            OnClick={() => {
              setCustomModalAlert(``)
            }}
          />
          <PrimaryButton
            width="200px"
            height="40px"
            onClick={() => {
              setSubmitLoading(true)
              setCustomModalAlert(``)
              handleScheduledTed()
            }}
          >
            Sim
          </PrimaryButton>
        </div>
      </CustomModal>
    </div>
  )
}

export default TransferStep4Mobile
