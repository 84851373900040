import dayjs, { BusinessHoursMap } from "dayjs"
import dayjsBusinessTime from "dayjs-business-time"
import html2canvas from "html2canvas"
import { useContext, useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { useForm } from "react-hook-form"
import InputMask from "react-input-mask"
import close from "../../../../../assets/images/close.svg"
import entradaDinheiro from "../../../../../assets/images/entrada_dinheiro.svg"
import saidaDinheiro from "../../../../../assets/images/saida_dinheiro.svg"
import share from "../../../../../assets/images/share_yellow.svg"
import { AuthContext } from "../../../../../context/AuthProvider"
import {
  CustomModal,
  FailureModal,
  SuccessModal,
  SecondaryButton,
  PrimaryButton,
} from "k4n-components"
import key from "../../../../../assets/images/key.svg"
import PaymentNav from "../payment-nav"
import "./styles.scss"
import SVGInject from "@iconfu/svg-inject"
import { Link } from "react-router-dom"
import DigitalSignature from "../../../DigitalSignature/Form"
import { BalanceContext } from "../../../../../context/BalanceProvider"
import services from "../../../../../services/svcmesh"
import { isDateNotEmpty } from "../../../../../utils/validation"
import CodeBarScanner from "../../../../features/Scanner/CodeBarScanner"
import { codBarras2LinhaDigitavel } from "../../../../../utils/utils"

dayjs.extend(dayjsBusinessTime)

const businessTimes: BusinessHoursMap = {
  sunday: null,
  monday: [{ start: `07:00:00`, end: `22:00:00` }],
  tuesday: [{ start: `07:00:00`, end: `22:00:00` }],
  wednesday: [{ start: `07:00:00`, end: `22:00:00` }],
  thursday: [{ start: `07:00:00`, end: `22:00:00` }],
  friday: [{ start: `07:00:00`, end: `22:00:00` }],
  saturday: null,
}

dayjs.setBusinessTime(businessTimes)

interface CamposBoleto {
  destinatario: string
  cpf_cnpj: string
  emissor: string
  dataCobranca: string
  dataVencimento: string
  dataLimPgto: string
  codigoBarras: string
  linhaDigitavel: string
  vlrTit: number
  vlrAbatimento: number
  vlrMulta: number
  vlrDesconto: number
  vlrTotal: number
  dtPgto?: string
  codigoTransacao?: string
  codigoMovimento?: string
  vlrPago?: number
  scheduledDate?: string
}

const PaymentStep1 = () => {
  const { user, current_account, hasDigitalSignature } = useContext(AuthContext)
  const [barCode, setBarCode] = useState<string>(``)
  const [isFocus, setIsOnfocus] = useState(false)
  const [callApiTrigger, setCallApiTrigger] = useState(0)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [step, setStep] = useState<number>(1)
  const [showPaymentDate, setShowPaymentDate] = useState(false)
  const [scheduledDate, setScheduledDate] = useState<Date | null>(null)
  const [modalAlert, setModalAlert] = useState(``)
  const [customModalAlert, setCustomModalAlert] = useState(``)
  const [digitalSignatureModal, setDigitalSignatureModal] = useState(false)
  const { refreshBalance } = useContext(BalanceContext)
  const [authorizeModal, setAuthorizeModal] = useState(false)
  const [info, setInfo] = useState<CamposBoleto>({
    destinatario: ``,
    cpf_cnpj: ``,
    emissor: ``,
    dataCobranca: ``,
    dataVencimento: ``,
    dataLimPgto: ``,
    linhaDigitavel: ``,
    codigoBarras: ``,
    vlrTit: 0,
    vlrAbatimento: 0,
    vlrMulta: 0,
    vlrDesconto: 0,
    vlrTotal: 0,
  })

  const [isApplyFilterButtonLoading, setIsApplyFilterButtonLoading] =
    useState(false)

  const [isBarcodeModalOpen, setIsBarcodeModalOpen] = useState(false)

  const { handleSubmit } = useForm()

  useEffect(() => {}, [scheduledDate])

  const onSubmit = () => {
    setAuthorizeModal(true)
  }

  const authorize = () => {
    setAuthorizeModal(false)
    if (scheduledDate) {
      setIsApplyFilterButtonLoading(true)
      boletoScheduling(scheduledDate)
    } else {
      if (!dayjs().isBusinessTime()) {
        setCustomModalAlert(
          `Dado o dia e/ou horário, seu pagamento será agendado para o dia ${dayjs()
            .nextBusinessTime()
            .format(
              `DD/MM/YYYY`,
            )} dentro do intervalo de 07:00 às 22:00. Deseja continuar?`,
        )
        return
      }
      setIsApplyFilterButtonLoading(true)
      boletoPayment()
    }
  }

  const barCodeInput = (e: any) => {
    const b = e.target.value.replace(/\D/g, ``)
    console.log(`trimming ${e.target.value} trimmed${b}`)
    setBarCode(b)
  }

  const handleGetBarCode = (linhaDigitavel: string) => {
    setBarCode(linhaDigitavel)
  }

  const showAlertModal = (message: string) => {
    setModalAlert(message)
  }

  const isWeekday = (date: Date) => {
    const day = dayjs(date).get(`d`)
    return day !== 0 && day !== 6
  }

  useEffect(() => {
    refreshBalance()
  }, [])

  useEffect(
    function () {
      console.log(`🚀 ~ info:`, info)
    },
    [info],
  )

  async function getBoletoInfo() {
    try {
      const response = await services.bankingService.getBoletoInfo({
        linhaDigitavel: codBarras2LinhaDigitavel(barCode, false),
      })
      setInfo({
        destinatario: response?.dadosTitulo?.nomRzSocBenfcrioOr,
        cpf_cnpj: response?.dadosTitulo?.cnpjCpfBenfcrioOr,
        emissor: response?.dadosTitulo?.emissor,
        dataCobranca: response?.dadosTitulo?.dtHrSitTit,
        dataVencimento: response?.dadosTitulo?.dtVencTit,
        dataLimPgto: response?.dadosTitulo?.dtLimPgtoTit,
        codigoBarras: response?.dadosTitulo?.numCodBarras,
        linhaDigitavel: response?.dadosTitulo?.numLinhaDigtvl,
        vlrTit: response?.dadosTitulo?.vlrTit,
        vlrAbatimento: response?.dadosTitulo?.vlrAbattTit,
        vlrMulta: response?.multa?.total,
        vlrDesconto: response?.descontos[0]?.total,
        vlrTotal: response?.vlrTitTotal,
      })
      setIsApplyFilterButtonLoading(false)
      setStep(2)
    } catch (error: any) {
      if (error?.response?.status === 500) {
        showAlertModal(
          `Não foi possível consultar esse boleto. Verifique o código de barras e tente novamente.`,
        )
      } else {
        console.log(`error: `, error?.response?.data?.message)
        if (error?.response?.data?.message.startsWith(`Linha`)) {
          return showAlertModal(`Linha digitável inválida`)
        }
        showAlertModal(
          error?.response?.data?.message || error?.message || error,
        )
      }
      setIsApplyFilterButtonLoading(false)
    }
  }

  async function boletoPayment() {
    try {
      const obj = {
        pagador: {
          agencia: current_account?.agencia || ``,
          agenciaDigito: current_account?.agencia || ``,
          conta: current_account?.conta || ``,
          contaDigito: current_account?.contaDigito || ``,
          contaPgto: String(current_account?.contaPgto || ``),
          tipoConta: Number(current_account?.tipoConta),
        },
        tipoProcessamento: 4,
        titulo: {
          linhaDigitavel: info.linhaDigitavel,
        },
        valorTitulo: info.vlrTit,
        dataVencimento: info.dataVencimento,
        valorPagamento: info.vlrTotal,
        dataPagamento: dayjs().format(),
        codOperacaoCli: ``,
        descCliente: ``,
      }
      const response = await services.bankingService.boletoPayment(obj)
      setIsApplyFilterButtonLoading(false)
      setInfo({
        ...info,
        dtPgto: dayjs().format(`DD/MM/YYYY - HH:mm:ss`),
        vlrPago: response?.data?.valorTransacao,
        codigoMovimento: response?.data?.codigoMovimento,
        codigoTransacao: response?.data?.codigoTransacao,
      })
      if (response?.data?.sucesso) {
        setShowSuccessModal(true)
      }
    } catch (error: any) {
      showAlertModal(error?.response?.data?.message || error?.message || error)
      setIsApplyFilterButtonLoading(false)
    }
  }

  async function boletoScheduling(date: Date) {
    try {
      const obj = {
        customer_pf: { id: Number(user?.id) },
        pagador_agencia: current_account?.agencia || ``,
        pagador_agencia_digito: current_account?.agenciaDigito || ``,
        pagador_conta: current_account?.conta || ``,
        pagador_conta_digito: current_account?.contaDigito || ``,
        pagador_conta_pgto: String(current_account?.contaPgto) || ``,
        pagador_tipo_conta: Number(current_account?.tipoConta),
        tipo_processamento: 4,
        titulo_linha_digitavel: info.linhaDigitavel,
        titulo_codigo_barras: info.codigoBarras,
        valor_titulo: info.vlrTit,
        data_vencimento: dayjs(info.dataVencimento).toDate(),
        valor_pagamento: info.vlrTotal,
        data_pagamento: info.dtPgto,
        scheduled_date: dayjs(date).format(`YYYY-MM-DD`),
        cod_operacao_cli: ``,
        desc_cliente: ``,
      }

      const response = await services.bankingService.boletoScheduling(obj)
      setIsApplyFilterButtonLoading(false)
      if (response.data.sucesso) {
        setInfo({
          ...info,
          scheduledDate: dayjs(date).format(`YYYY-MM-DD`),
          vlrPago: response?.data?.valorTransacao,
        })
        setCustomModalAlert(``)
        setShowSuccessModal(true)
      }
    } catch (error: any) {
      showAlertModal(error?.response?.data?.message || error?.message || error)
      setIsApplyFilterButtonLoading(false)
    }
  }

  const handleDownloadImage = async () => {
    html2canvas(document.querySelector(`#capture`)!).then((canvas) => {
      const data = canvas.toDataURL(`image/jpge`)
      const link = document.createElement(`a`)

      if (typeof link.download === `string`) {
        link.href = data
        link.download = `comprovante_boleto.png`

        link.click()
      } else {
        window.open(data)
      }
    })
  }

  const handleNext = () => {
    if (hasDigitalSignature) {
      setIsApplyFilterButtonLoading(true)
      getBoletoInfo()
    } else setDigitalSignatureModal(true)
  }

  const handleFocus = () => {
    setIsOnfocus(!isFocus)
  }

  return (
    <div className="payment-container">
      <PaymentNav step={step} />
      <CustomModal
        isOpen={digitalSignatureModal}
        onClose={() => {
          setDigitalSignatureModal(false)
        }}
        isBanking={true}
      >
        <div style={{ width: `120px`, margin: `0 auto` }}>
          <img
            src={key}
            alt="/"
            width="120"
            height="120"
            className="key-vector"
            onLoad={(e) => SVGInject(e.target)}
          />
        </div>
        <h1 className="digital-signature-h1">
          Crie agora sua assinatura digital <br /> e aproveite todos os recursos
          de sua conta!
        </h1>
        <div className="flex-center mt-3">
          <Link style={{ textDecoration: `none` }} to="/digital-signature">
            <button className="home-bold-btn">Criar</button>
          </Link>
        </div>
      </CustomModal>
      <CustomModal
        isOpen={authorizeModal}
        onClose={() => {
          setAuthorizeModal(false)
        }}
        isBanking={true}
      >
        <div style={{ width: `120px`, margin: `0 auto` }}>
          <img
            src={key}
            alt="/"
            width="120"
            height="120"
            className="key-vector"
            onLoad={(e) => SVGInject(e.target)}
          />
        </div>
        <DigitalSignature handleSuccess={authorize} />
      </CustomModal>
      {step === 1 && (
        <div className="payment-content">
          <div className="payment-title-div">
            <h1 className="step-title">Boleto Bancário</h1>
          </div>
          <div className="bar-code-div">
            <div className="bar-code-info">
              <label
                className={
                  isFocus
                    ? `payment-input-label payment-show-input`
                    : `payment-input-label`
                }
                htmlFor="linhaDigitavel"
              >
                Linha digitável:
              </label>
              <input
                id="linhaDigitavel"
                type="text"
                placeholder="Digite a linha digitável"
                className="usr-input"
                onChange={barCodeInput}
                onFocus={handleFocus}
                onBlur={handleFocus}
                value={barCode}
                minLength={44}
                maxLength={120}
              />
            </div>
            <CodeBarScanner
              scannedData={barCode}
              setScannedData={handleGetBarCode}
              justIcon={true}
            />
          </div>
          <div className="payment-btn-div">
            <button className="next-btn" onClick={handleNext}>
              {isApplyFilterButtonLoading ? (
                <div className="payment-spinner"></div>
              ) : (
                <>
                  <span className="next-span">Próximo</span>
                </>
              )}
            </button>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="payment-content-2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="payment-title-div">
              <h1 className="step-title">Boleto Bancário</h1>
            </div>
            <div className="bar-code-div">
              <h2 className="step-span">Código de barras</h2>
              <div className="bar-code-info">
                <span className="bar-code-span">{barCode}</span>
              </div>
            </div>
            <div className="payment-boleto-info-div">
              <div className="boleto-info">
                <div className="boleto-info-cols">
                  {info.destinatario && (
                    <div className="col">
                      <h2 className="col-title">Dados do destinatário</h2>
                      <div className="row">
                        <span className="row-span-title">Nome</span>
                        <span className="row-span-content">
                          {info.destinatario}
                        </span>
                      </div>
                      <div className="row">
                        <span className="row-span-title">CPF/CNPJ</span>
                        <span className="row-span-content">
                          {info.cpf_cnpj}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="col">
                    <h2 className="col-title">Dados da cobrança</h2>
                    <div className="col-bill">
                      <div className="col">
                        <div className="row">
                          <span className="row-span-title">Emissor</span>
                          {info.emissor ? (
                            <span className="row-span-content">
                              {info.emissor}
                            </span>
                          ) : (
                            <span className="row-span-content">{`IBPJ`}</span>
                          )}
                        </div>
                        {isDateNotEmpty(info.dataCobranca) && (
                          <div className="row">
                            <span className="row-span-title">
                              Data da cobrança
                            </span>
                            <span className="row-span-content">
                              {dayjs(info.dataCobranca).format(`DD/MM/YYYY`)}
                            </span>
                          </div>
                        )}
                      </div>
                      {isDateNotEmpty(info.dataVencimento) ||
                        (isDateNotEmpty(info.dataLimPgto) && (
                          <div className="col">
                            {isDateNotEmpty(info.dataVencimento) && (
                              <div className="row">
                                <span className="row-span-title">
                                  Data de vencimento
                                </span>
                                <span className="row-span-content">
                                  {dayjs(info.dataVencimento).format(
                                    `DD/MM/YYYY`,
                                  )}
                                </span>
                              </div>
                            )}
                            {isDateNotEmpty(info.dataLimPgto) && (
                              <div className="row">
                                <span className="row-span-title">
                                  Data limite
                                </span>
                                <span className="row-span-content">
                                  {dayjs(info.dataLimPgto).format(`DD/MM/YYYY`)}
                                </span>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="col">
                    <h2 className="col-title">Dados do pagamento</h2>
                    <div className="col-bill">
                      <div className="col">
                        <div className="row">
                          <span className="row-span-title">Valor original</span>
                          <span className="row-span-content">
                            {info.vlrTit.toLocaleString(`pt-BR`, {
                              style: `currency`,
                              currency: `BRL`,
                            })}
                          </span>
                        </div>
                        <div className="row">
                          <span className="row-span-title">Multa</span>
                          {info.vlrMulta === 0 ? (
                            <span className="row-span-content">Isento</span>
                          ) : (
                            <span className="row-span-content">
                              {info.vlrMulta?.toLocaleString(`pt-BR`, {
                                style: `currency`,
                                currency: `BRL`,
                              })}
                            </span>
                          )}
                        </div>
                        <div className="row">
                          <span className="row-span-title">Valor total</span>
                          <span className="row-span-content">
                            {info.vlrTotal?.toLocaleString(`pt-BR`, {
                              style: `currency`,
                              currency: `BRL`,
                            })}
                          </span>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <span className="row-span-title">Abatimento</span>
                          {info.vlrAbatimento === 0 ? (
                            <span className="row-span-content">
                              Sem abatimento
                            </span>
                          ) : (
                            <span className="row-span-content">
                              {info.vlrAbatimento.toLocaleString(`pt-BR`, {
                                style: `currency`,
                                currency: `BRL`,
                              })}
                            </span>
                          )}
                        </div>
                        {/* {info.vlrDesconto && (
                          <div className="row">
                            <span className="row-span-title">Desconto</span>
                            {info.vlrDesconto && info.vlrDesconto === 0 ? (
                              <span className="row-span-content">
                                Sem desconto
                              </span>
                            ) : (
                              <span className="row-span-content">
                                {info.vlrDesconto?.toLocaleString(`pt-BR`, {
                                  style: `currency`,
                                  currency: `BRL`,
                                })}
                              </span>
                            )}
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-table-step2-div">
              <div className="content-table-step2">
                <div className="payment-step2-date">
                  <span className="content-table-step2-title-18">
                    Agendar pagamento
                  </span>
                  <div className="form-check form-switch">
                    <input
                      className="payment-step2-date-input form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      onClick={() => {
                        setShowPaymentDate(!showPaymentDate)
                        setScheduledDate(null)
                      }}
                    />
                  </div>
                </div>
                {showPaymentDate === true && (
                  <DatePicker
                    selected={scheduledDate}
                    onChange={(date: any) => setScheduledDate(date)}
                    locale="pt-BR"
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    required={true}
                    minDate={dayjs().nextBusinessDay().toDate()}
                    maxDate={dayjs(info.dataLimPgto).toDate()}
                    filterDate={isWeekday}
                    placeholderText="Quando você quer pagar?"
                    customInput={
                      <InputMask
                        mask="99/99/9999"
                        maskPlaceholder={null}
                        className="payment-step2-message-input payment-step2-input-calender"
                      />
                    }
                  />
                )}
              </div>
            </div>
            <div className="payment-btn-div">
              {showPaymentDate ? (
                <button className="next-btn" type="submit">
                  {isApplyFilterButtonLoading ? (
                    <div className="payment-spinner"></div>
                  ) : (
                    <>
                      <span className="next-span">Agendar pagamento</span>
                    </>
                  )}
                </button>
              ) : (
                <button className="next-btn" type="submit">
                  {isApplyFilterButtonLoading ? (
                    <div className="payment-spinner"></div>
                  ) : (
                    <>
                      <span className="next-span">Pagar Boleto</span>
                    </>
                  )}
                </button>
              )}
              <button
                className="cancel-btn"
                onClick={() => {
                  setIsApplyFilterButtonLoading(false)
                  setBarCode(``)
                  setStep(1)
                  setScheduledDate(null)
                  setShowPaymentDate(false)
                }}
              >
                <span className="cancel-span">Cancelar</span>
              </button>
            </div>
            {showSuccessModal && scheduledDate ? (
              <SuccessModal
                isOpen={showSuccessModal}
                width={`700px`}
                height={`400px`}
                onClose={() => {
                  setStep(1)
                  setShowSuccessModal(false)
                }}
              >
                <h1 className="pix-modal-sucess-h1">
                  {current_account?.tipoPessoa == 2
                    ? `Boleto enviado para a sua fila de aprovações`
                    : `Pagamento do boleto agendado com `}
                  <br /> com sucesso!
                </h1>
                <div className="boleto-justify-center">
                  {current_account?.tipoPessoa != 2 && (
                    <button
                      className="boleto-receipt-btn"
                      onClick={() => {
                        setCallApiTrigger(callApiTrigger + 1)
                        setStep(3)
                      }}
                    >
                      Ver comprovante
                    </button>
                  )}
                </div>
              </SuccessModal>
            ) : (
              <SuccessModal
                isOpen={showSuccessModal}
                width={`700px`}
                height={`400px`}
                onClose={() => {
                  setStep(1)
                  setShowSuccessModal(false)
                }}
              >
                <h1 className="pix-modal-sucess-h1">
                  {current_account?.tipoPessoa == 2
                    ? `Boleto enviado para a sua fila de aprovações`
                    : `Boleto pago com`}
                  <br /> com sucesso!
                </h1>
                <div className="boleto-justify-center">
                  {current_account?.tipoPessoa != 2 && (
                    <button
                      className="boleto-receipt-btn"
                      onClick={() => setStep(3)}
                    >
                      Ver comprovante
                    </button>
                  )}
                </div>
              </SuccessModal>
            )}
          </form>
        </div>
      )}
      {step === 3 && (
        <div className="payment-content-3">
          <div id="capture" className="payment-receipt-capture">
            <div className="step-3-btns">
              <button
                className="close-btn"
                onClick={() => {
                  setBarCode(``)
                  setInfo({
                    destinatario: ``,
                    cpf_cnpj: ``,
                    emissor: ``,
                    dataCobranca: ``,
                    dataVencimento: ``,
                    dataLimPgto: ``,
                    linhaDigitavel: ``,
                    codigoBarras: ``,
                    vlrTit: 0,
                    vlrAbatimento: 0,
                    vlrMulta: 0,
                    vlrDesconto: 0,
                    vlrTotal: 0,
                  })
                  setStep(1)
                  setScheduledDate(null)
                  setShowSuccessModal(false)
                  setShowPaymentDate(false)
                }}
              >
                <img src={close} alt="/" onLoad={(e) => SVGInject(e.target)} />
              </button>
              <button className="share-btn" onClick={handleDownloadImage}>
                <img src={share} alt="/" onLoad={(e) => SVGInject(e.target)} />
              </button>
            </div>
            {info.dtPgto ? (
              <>
                <h1 className="step-title">Comprovante de Pagamento</h1>
                <div className="payment-receipt">
                  <span className="row-span-title-18 margin-right-4">
                    Realizado em
                  </span>
                  <span className="row-span-title-18 w700">{info.dtPgto}</span>
                </div>
              </>
            ) : (
              <>
                <h1 className="step-title">Comprovante de Agendamento</h1>
                <div className="payment-receipt">
                  <span className="row-span-title-18 margin-right-4">
                    Agendado para
                  </span>
                  <span className="row-span-title-18 w700">
                    {info.scheduledDate}
                  </span>
                </div>
              </>
            )}
            <div className="section">
              <h2 className="section-title">Valor do pagamento</h2>
              <span className="section-2-span">
                {info.vlrTotal.toLocaleString(`pt-BR`, {
                  style: `currency`,
                  currency: `BRL`,
                })}
              </span>
            </div>
            <div className="table-div">
              <img
                src={entradaDinheiro}
                alt=""
                onLoad={(e) => SVGInject(e.target)}
              />
              <span className="section-title padding-left-10">
                Destinatário
              </span>
            </div>
            <div className="table-div-content">
              <div className="col">
                <div className="col-step-3">
                  <h2 className="row-title">Nome</h2>
                  <span className="row-span">{info.destinatario}</span>
                </div>
              </div>
              <div className="col">
                <div className="col-step-3">
                  <h2 className="row-title">CPF/CNPJ</h2>
                  <span className="row-span">{info.cpf_cnpj}</span>
                </div>
              </div>
              <div className="col">
                <div className="col-step-3">
                  <h2 className="row-title"></h2>
                  <span className="row-span"></span>
                </div>
              </div>
            </div>
            <div className="table-div">
              <img
                src={saidaDinheiro}
                alt=""
                onLoad={(e) => SVGInject(e.target)}
              />
              <span className="section-title padding-left-10">Origem</span>
            </div>
            <div className="table-div-content">
              <div className="col">
                <div className="col-step-3">
                  <h2 className="row-title">Nome</h2>
                  <span className="row-span">{user?.nome}</span>
                </div>
                <div className="col-step-3">
                  <h2 className="row-title">Instituição</h2>
                  <span className="row-span">Kikkin</span>
                </div>
              </div>
              <div className="col">
                <div className="col-step-3">
                  <h2 className="row-title">CPF/CNPJ</h2>
                  <span className="row-span">{user?.cpf}</span>
                </div>
                <div className="col-step-3">
                  <h2 className="row-title">Agencia</h2>
                  <span className="row-span">{current_account?.agencia}</span>
                </div>
              </div>
              <div className="col">
                <div className="col-step-3">
                  <h2 className="row-title">Conta</h2>
                  <span className="row-span">
                    {current_account?.conta +
                      `-` +
                      current_account?.contaDigito}
                  </span>
                </div>
              </div>
            </div>
            {info.codigoTransacao ? (
              <div className="section margin-30">
                <h2 className="section-title">Id da Transação</h2>
                <span className="section-span">{info.codigoTransacao}</span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
      <FailureModal
        isOpen={!!modalAlert}
        width={`937px`}
        height={`350px`}
        onClose={() => {
          setStep(1)
          setModalAlert(``)
          setScheduledDate(null)
          setIsApplyFilterButtonLoading(false)
          setShowPaymentDate(false)
        }}
      >
        <h1 className="pix-modal-sucess-h1">{modalAlert}</h1>
      </FailureModal>
      <CustomModal
        isOpen={!!customModalAlert}
        onClose={() => setCustomModalAlert(``)}
      >
        <div className="modal-message transfer-custom-modal">
          {customModalAlert}
        </div>
        <div className="transfer-schedule-alert">
          <SecondaryButton
            text="Não"
            width="200px"
            height="40px"
            OnClick={() => setCustomModalAlert(``)}
          />
          <PrimaryButton
            width="200px"
            height="40px"
            onClick={() => {
              setScheduledDate(dayjs().nextBusinessTime().toDate())
              boletoScheduling(dayjs().nextBusinessTime().toDate())
            }}
          >
            Sim
          </PrimaryButton>
        </div>
      </CustomModal>
    </div>
  )
}

export default PaymentStep1
