import ptBR from "date-fns/locale/pt-BR"
import { Email, Phone } from "k4n-svcmesh-sdk"
import { OnboardingPjSave } from "k4n-svcmesh-sdk/dist/typings/onboarding-pj.dto"
import { useContext, useEffect, useState } from "react"
import { registerLocale } from "react-datepicker"
import { useForm } from "react-hook-form"
import InputMask from "react-input-mask"
import { useLocation } from "react-router-dom"
import arrowYellowDown from "../../../../assets/images/arrow_expand_yellow.svg"
import arrow from "../../../../assets/images/arrow_right_white.svg"
import whiteLogo from "../../../../assets/images/logo-kikkin-verde.svg"
import arrowLeft from "../../../../assets/images/yellow-arrow-left.svg"
import { Address } from "../../../../context/OnboardingContext"
import { OnboardingPjContext } from "../../../../context/OnboardingPjContext"
import services from "../../../../services/svcmesh"
import {
  buildCnpjMask,
  buildCpfMask,
  buildPhoneMask,
  buildRgMask,
} from "../../../../utils/utils"
import { validateBirthday, validateCpf } from "../../../../utils/validation"
import "./styles.scss"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"

dayjs.extend(customParseFormat)

registerLocale(`pt-BR`, ptBR)

const Identification = () => {
  const { state } = useLocation()
  const [showCollapse, setShowCollapse] = useState(``)
  const [cnpjMask, setCnpjMask] = useState(``)
  const {
    goBack,
    next,
    step1Data,
    addStep1,
    // addClientePjId,
    loading,
    setLoading,
  } = useContext(OnboardingPjContext)

  // get from locatoin.state the cnpj and set it
  useEffect(() => {
    if (state && state.cnpj) {
      setCnpjMask(buildCnpjMask(state.cnpj))
    }
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue: setValue1,
  } = useForm({ mode: `onChange`, reValidateMode: `onChange` })

  const {
    register: register2,
    formState: { errors: errors2, isValid: isValid2 },
    handleSubmit: handleSubmit2,
    clearErrors,
    setError,
    setValue: setValue2,
    getValues: getValues2,
  } = useForm({ mode: `onChange`, reValidateMode: `onChange` })

  const {
    register: register3,
    formState: { errors: errors3, isValid: isValid3 },
    handleSubmit: handleSubmit3,
    // control: controlForm3,
    setValue: setValue3,
  } = useForm({ mode: `onChange`, reValidateMode: `onChange` })

  const onSubmitIdentification = async (_data: any) => {
    await handleSubmit2(onSubmitAddress, onErrorAddress)()
  }

  const onErrorAddress = async () => {
    if (showCollapse !== `address`) toggleRowCollapse(`address`)
  }

  const onSubmitAddress = async (_data: any) => {
    await handleSubmit3(onSubmitFinal, onErrorContact)()
  }

  const onErrorContact = async () => {
    if (showCollapse !== `contact`) toggleRowCollapse(`contact`)
  }

  const onSubmitFinal = async (data: any) => {
    if (isValid && isValid2 && isValid3 && !loading) {
      setLoading(true)
      const address = {
        cep: getValues2(`zip`),
        logradouro: getValues2(`street`),
        numero: getValues2(`number`),
        bairro: getValues2(`district`),
        complemento: getValues2(`complement`),
        cidade: getValues2(`city`),
        uf: getValues2(`state`),
      } as Address

      console.log(`data`, data?.dtNascimentoResponsavel)

      const saveObj: OnboardingPjSave = {
        cnpj:
          state?.cnpj.replace(/\D/g, ``) ??
          getValues(`cnpj`).replace(/\D/g, ``),
        razaoSocial: getValues(`razao`),
        nomeFantasia: getValues(`fantasia`),
        documentoEstadual: ``,
        documentoMunicipal: ``,
        nomeResponsavelEmpresa: data?.nomeResponsavelEmpresa,
        cpfResponsavelEmpresa: data?.cpfResponsavelEmpresa.replace(/\D/g, ``),
        dtNascimentoResponsavel: dayjs(
          data?.dtNascimentoResponsavel,
          `DD/MM/YYYY`,
        )
          .toDate()
          .toISOString(),
        rgResponsavelEmpresa: data?.rgResponsavelEmpresa.replace(/\D/g, ``),
        addresses: [address],
        emails: [{ email: data?.email, emailType: 3 } as Email],
        phones: [
          { phone: data?.phone.replace(/\D/g, ``), phoneType: 3 } as Phone,
        ],
        status: 2,
      }

      // prevent multiple submissions
      try {
        addStep1(saveObj)
        setLoading(false)
        next()
      } catch (error) {
        setLoading(false)
        console.log(`Erro ao salvar dados`, error)
      }
    }
  }

  const handleNext = async () => {
    await handleSubmit(onSubmitIdentification, onErrorIdentification)()
  }

  const onErrorIdentification = () => {
    if (showCollapse !== `identification`) toggleRowCollapse(`identification`)
  }

  const toggleRowCollapse = async (id: string) => {
    if (showCollapse === id) setShowCollapse(``)
    else {
      setShowCollapse(id)
    }
  }

  async function handleCnpjChange(e: any) {
    e.preventDefault()
    const value = e.currentTarget.value

    services.bankingService
      .checkIfExistByCnpj(value?.replace(/\D/g, ``))
      .then((res) => {
        if (res?.data?.exist) {
          setError(`cnpj`, { message: `CNPJ já cadastrado` })
        }
      })

    setCnpjMask(buildCnpjMask(value))
  }

  const handleCompleteAddress = async (
    event: React.FocusEvent<HTMLInputElement>,
  ) => {
    clearErrors(`zip`)
    const zipCode = event.currentTarget.value.replace(`-`, ``)
    const res = await services.zipCodeService.consultZipCode(zipCode)
    if (res.data.erro === `true`) {
      setError(`zip`, { message: `Verifique o CEP.` })
    }
    setValue2(`street`, res.data.logradouro, { shouldValidate: true })
    setValue2(`state`, res.data.uf, { shouldValidate: true })
    setValue2(`city`, res.data.localidade, { shouldValidate: true })
    setValue2(`district`, res.data.bairro, { shouldValidate: true })
  }

  useEffect(() => {
    // set form values if step1Data is not null
    if (
      step1Data &&
      Object.keys(step1Data).length > 0 &&
      state?.cnpj?.replace(/\D/g, ``) === step1Data.cnpj
    ) {
      setValue1(`cnpj`, buildCnpjMask(step1Data.cnpj))
      setValue1(`razao`, step1Data.razaoSocial, {
        shouldValidate: true,
      })
      setValue1(`fantasia`, step1Data.nomeFantasia, {
        shouldValidate: true,
      })
      setValue3(`nomeResponsavelEmpresa`, step1Data.nomeResponsavelEmpresa, {
        shouldValidate: true,
      })
      setValue3(
        `cpfResponsavelEmpresa`,
        buildCpfMask(step1Data.cpfResponsavelEmpresa),
        {
          shouldValidate: true,
        },
      )
      setValue3(
        `rgResponsavelEmpresa`,
        buildRgMask(step1Data.rgResponsavelEmpresa),
        {
          shouldValidate: true,
        },
      )
      setValue3(
        `dtNascimentoResponsavel`,
        dayjs(step1Data.dtNascimentoResponsavel).format(`DD/MM/YYYY`),
        {
          shouldValidate: true,
        },
      )
      setValue3(`email`, step1Data.emails[0].email, {
        shouldValidate: true,
      })
      setValue3(`phone`, buildPhoneMask(step1Data.phones[0].phone), {
        shouldValidate: true,
      })
      setValue2(`zip`, step1Data.addresses[0].cep, {
        shouldValidate: true,
      })
      setValue2(`street`, step1Data.addresses[0].logradouro, {
        shouldValidate: true,
      })
      setValue2(`number`, step1Data.addresses[0].numero, {
        shouldValidate: true,
      })
      setValue2(`complement`, step1Data.addresses[0].complemento, {
        shouldValidate: true,
      })
      setValue2(`district`, step1Data.addresses[0].bairro, {
        shouldValidate: true,
      })
      setValue2(`city`, step1Data.addresses[0].cidade, {
        shouldValidate: true,
      })
      setValue2(`state`, step1Data.addresses[0].uf, {
        shouldValidate: true,
      })
    }
  }, [step1Data])

  console.log(`step1Data`, errors3.dtNascimentoResponsavel)

  return (
    <div className="onboarding-pj-form-container">
      <img className="onboarding-pj-go-back" onClick={goBack} src={arrowLeft} />
      <img width={78} height={25} className="k4n-white-logo" src={whiteLogo} />
      <div className="onboarding-expand-primary">
        <div
          onClick={() => toggleRowCollapse(`identification`)}
          className="row onboarding-pj-expand-row"
        >
          <div className="col no-padding-col">
            <span className="onboarding-pj-medium-title">
              1. Sobre a empresa
            </span>
          </div>
          <div className="col no-padding-col">
            <div className="onboarding-pj-expand-arrow-div">
              <img
                src={arrowYellowDown}
                className={
                  showCollapse === `identification`
                    ? `onboarding-pj-expand-arrow-active`
                    : `onboarding-pj-expand-arrow`
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          showCollapse === `identification` ? `collapse show` : `collapse`
        }
        id={`collapseIdentification`}
      >
        <form onSubmit={handleSubmit(onSubmitIdentification)}>
          <div className="row onboarding-pj-form-row">
            <div className="col-12">
              <input
                id="cnpj"
                className={
                  errors.cnpj
                    ? `onboarding-pj-input onboarding-278-input input-invalid`
                    : state?.cnpj // eslint-disable-next-line
                    ? // eslint-disable-next-line
                      `onboarding-pj-input onboarding-278-input onboarding-pj-input-disabled` // eslint-disable-next-line
                    : // eslint-disable-next-line
                      `onboarding-pj-input onboarding-278-input`
                }
                placeholder="CNPJ"
                type="text"
                autoFocus
                value={cnpjMask}
                {...register(`cnpj`, {
                  required: true,
                  pattern: /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/,
                  onChange: handleCnpjChange,
                  disabled: state?.cnpj ? true : false,
                })}
              />
              {errors.cnpj && (
                <div className="input-error-pj">CNPJ inválido</div>
              )}
            </div>
          </div>
          <div className="row onboarding-pj-form-row">
            <div className="col-12">
              <input
                id="razao"
                className={
                  errors.razao
                    ? `onboarding-pj-input onboarding-671-input input-invalid`
                    : `onboarding-pj-input onboarding-671-input`
                }
                placeholder="Razão social"
                type="text"
                {...register(`razao`, {
                  required: true,
                  pattern: /^(([A-zÀ-ÿ]+)(\ )?){0,}$/,
                })}
              />
              {errors.razao && (
                <div className="input-error-pj">Razão social inválida</div>
              )}
            </div>
          </div>
          <div className="row onboarding-pj-form-row">
            <div className="col-12">
              <input
                id="fantasia"
                className={
                  errors.fantasia
                    ? `onboarding-pj-input onboarding-671-input input-invalid`
                    : `onboarding-pj-input onboarding-671-input`
                }
                placeholder="Nome fantasia"
                type="text"
                {...register(`fantasia`, {
                  required: true,
                  pattern: /^(([A-zÀ-ÿ]+)(\ )?){0,}$/,
                })}
              />
              {errors.fantasia && (
                <div className="input-error-pj">Nome fantasia inválido</div>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="onboarding-expand-primary">
        <div
          className="row onboarding-pj-expand-row"
          onClick={() => toggleRowCollapse(`address`)}
        >
          <div className="col no-padding-col">
            <span className="onboarding-pj-medium-title">
              2. Endereço (Sede social)
            </span>
          </div>
          <div className="col no-padding-col">
            <div className="onboarding-pj-expand-arrow-div">
              <img
                src={arrowYellowDown}
                className={
                  showCollapse === `address`
                    ? `onboarding-pj-expand-arrow-active`
                    : `onboarding-pj-expand-arrow`
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={showCollapse === `address` ? `collapse show` : `collapse`}
        id={`collapseAddress`}
      >
        <form onSubmit={handleSubmit(onSubmitAddress)}>
          <div className="row onboarding-pj-form-row">
            <div className="col-12">
              <InputMask
                id="cep"
                mask="99999-999"
                type="text"
                placeholder="CEP"
                className={
                  errors2.zip
                    ? `onboarding-pj-input onboarding-278-input input-invalid`
                    : `onboarding-pj-input onboarding-278-input`
                }
                onKeyPress={(event) => {
                  if (event.key === `Enter`) {
                    event.preventDefault()
                  }
                }}
                {...register2(`zip`, {
                  required: true,
                  pattern: /^\d{5}-\d{3}$/,
                  onBlur: (e) => {
                    handleCompleteAddress(e)
                  },
                })}
              />
              {errors2.zip && (
                <div className="input-error-pj">CEP inválido</div>
              )}
            </div>
          </div>
          <div className="row onboarding-pj-form-row mx-wd-695">
            <div className="col-md-8 col-lg-8 col-xl-8 col-sm-12 col-xs-12 pd-right-8-pj">
              <input
                id="street"
                type="text"
                // disabled={true}
                className={
                  errors2.street
                    ? `onboarding-pj-input onboarding-671-input onboarding-pj-input-disabled  input-invalid`
                    : `onboarding-pj-input onboarding-671-input onboarding-pj-input-disabled`
                }
                placeholder="Endereço"
                {...register2(`street`, {
                  required: true,
                  pattern: /^(([a-zA-Zà-ùÀ-Ù0-9´']+)(\ )?){0,}$/,
                })}
              />
              {errors2.street && (
                <div className="input-error-pj">Endereço inválido</div>
              )}
            </div>
            <div className="col-md-4 col-lg-4 col-xl-4 col-sm-12 col-xs-12 pd-left-8-pj mb-col-pj-270">
              <input
                id="number"
                type="text"
                className={
                  errors2.number
                    ? `onboarding-pj-input onboarding-671-input input-invalid`
                    : `onboarding-pj-input onboarding-671-input`
                }
                placeholder="Número"
                {...register2(`number`, {
                  required: true,
                  pattern: /^[0-9]+$/,
                })}
              />
              {errors2.number && (
                <div className="input-error-pj">Número inválido</div>
              )}
            </div>
          </div>
          <div className="row onboarding-pj-form-row mx-wd-695">
            <div className="col-md-5 col-lg-5 col-xl-5 col-sm-12 col-xs-12 pd-right-8-pj">
              <input
                id="complement"
                type="text"
                className={
                  errors2.complement
                    ? `onboarding-pj-input onboarding-671-input input-invalid`
                    : `onboarding-pj-input onboarding-671-input`
                }
                placeholder="Complemento"
                {...register2(`complement`)}
              />
            </div>
            <div className="col-md-7 col-lg-7 col-xl-7 col-sm-12 col-xs-12 pd-left-8-pj mb-col-pj-mt-35">
              <input
                id="district"
                type="text"
                // disabled={true}
                className={
                  errors2.district
                    ? `onboarding-pj-input onboarding-671-input onboarding-pj-input-disabled  input-invalid`
                    : `onboarding-pj-input onboarding-671-input onboarding-pj-input-disabled `
                }
                placeholder="Bairro"
                {...register2(`district`, {
                  required: true,
                  // pattern: /^(([a-zA-Zà-ùÀ-Ù0-9´']+)(\ )?){0,}$/,
                })}
              />
              {errors2.district && (
                <div className="input-error-pj">Bairro inválido</div>
              )}
            </div>
          </div>
          <div className="row onboarding-pj-form-row mx-wd-695">
            <div className="col-md-7 col-lg-7 col-xl-7 col-sm-12 col-xs-12 pd-right-8-pj">
              <input
                id="city"
                type="text"
                // disabled={true}
                className={
                  errors2.city
                    ? `onboarding-pj-input onboarding-671-input onboarding-pj-input-disabled  input-invalid`
                    : `onboarding-pj-input onboarding-671-input onboarding-pj-input-disabled `
                }
                placeholder="Cidade"
                {...register2(`city`, {
                  required: true,
                  pattern: /^(([a-zA-Zà-ùÀ-Ù0-9´']+)(\ )?){0,}$/,
                })}
              />
              {errors2.city && (
                <div className="input-error-pj">Cidade inválida</div>
              )}
            </div>
            <div className="col-md-5 col-lg-5 col-xl-5 col-sm-12 col-xs-12 pd-left-8-pj mb-col-pj-mt-35">
              <input
                id="state"
                type="text"
                className={
                  errors2.state
                    ? `onboarding-pj-input onboarding-671-input onboarding-pj-input-disabled  input-invalid`
                    : `onboarding-pj-input onboarding-671-input onboarding-pj-input-disabled `
                }
                // disabled={true}
                placeholder="Estado"
                {...register2(`state`, {
                  required: true,
                  minLength: 2,
                })}
              />
              {errors2.state && (
                <div className="input-error-pj">Estado inválido</div>
              )}
            </div>
          </div>
        </form>
      </div>
      <div
        className={
          showCollapse === `contact`
            ? `onboarding-expand-primary`
            : `onboarding-expand-primary onboarding-expand-border-bottom`
        }
      >
        <div
          className="row onboarding-pj-expand-row"
          onClick={() => toggleRowCollapse(`contact`)}
        >
          <div className="col no-padding-col">
            <span className="onboarding-pj-medium-title">3. Contatos</span>
          </div>
          <div className="col no-padding-col">
            <div className="onboarding-pj-expand-arrow-div">
              <img
                src={arrowYellowDown}
                className={
                  showCollapse === `contact`
                    ? `onboarding-pj-expand-arrow-active`
                    : `onboarding-pj-expand-arrow`
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={showCollapse === `contact` ? `collapse show` : `collapse`}
        id={`collapseContact`}
      >
        <form onSubmit={handleSubmit(onSubmitFinal)}>
          <div className="row onboarding-pj-form-row">
            <div className="col-12">
              <input
                id="nomeResponsavelEmpresa"
                className={
                  errors3.nomeResponsavelEmpresa
                    ? `onboarding-pj-input onboarding-671-input input-invalid`
                    : `onboarding-pj-input onboarding-671-input`
                }
                placeholder="Nome do responsável"
                type="text"
                {...register3(`nomeResponsavelEmpresa`, {
                  required: true,
                  pattern: /^(([A-zÀ-ÿ]+)(\ )?){0,}$/,
                })}
              />
              {errors3.nomeResponsavelEmpresa && (
                <div className="input-error-pj">
                  Nome do responsável inválido
                </div>
              )}
            </div>
          </div>

          <div className="row onboarding-pj-form-row mx-wd-695">
            <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 pd-right-8-pj">
              <InputMask
                id="cpfResponsavelEmpresa"
                className={
                  errors3.cpfResponsavelEmpresa
                    ? `onboarding-pj-input onboarding-671-input input-invalid`
                    : `onboarding-pj-input onboarding-671-input`
                }
                type="text"
                inputMode="numeric"
                mask="999.999.999-99"
                alwaysShowMask={false}
                placeholder="CPF"
                {...register3(`cpfResponsavelEmpresa`, {
                  required: true,
                  pattern: /^\d\d\d\.\d\d\d\.\d\d\d-\d\d$/,
                  validate: validateCpf,
                })}
              />
              {errors3.cpfResponsavelEmpresa && (
                <div className="input-error-pj">CPF inválido</div>
              )}
            </div>
            <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 pd-left-8-pj">
              <input
                id="rgResponsavelEmpresa"
                className={
                  errors3.rgResponsavelEmpresa
                    ? `onboarding-pj-input onboarding-671-input input-invalid`
                    : `onboarding-pj-input onboarding-671-input`
                }
                placeholder="RG"
                type="number"
                inputMode="numeric"
                {...register3(`rgResponsavelEmpresa`, {
                  required: true,
                  pattern: /^[0-9]+$/,
                })}
              />
              {errors3.rgResponsavelEmpresa && (
                <div className="input-error-pj">RG inválido</div>
              )}
            </div>
          </div>

          <div className="row onboarding-pj-form-row mx-wd-695">
            <div className="col-md-5 col-lg-5 col-xl-5 col-sm-12 col-xs-12 pd-right-8-pj">
              <InputMask
                mask="99/99/9999"
                id="dtNascimentoResponsavel"
                type="text"
                className={
                  errors3.dtNascimentoResponsavel
                    ? `onboarding-pj-input onboarding-671-input input-invalid`
                    : `onboarding-pj-input onboarding-671-input`
                }
                placeholder="Data de nascimento"
                {...register3(`dtNascimentoResponsavel`, {
                  required: true,
                  validate: validateBirthday,
                })}
              />
              {errors3?.dtNascimentoResponsavel && (
                <div className="input-error-pj">
                  {(errors3?.dtNascimentoResponsavel?.message as string) ||
                    `Data de Nascimento inválida`}
                </div>
              )}
            </div>
            <div className="col-md-7 col-lg-7 col-xl-7 col-sm-12 col-xs-12 pd-left-8-pj mb-col-pj-mt-35 pd-right-8-pj">
              <input
                id="email"
                type="text"
                className={
                  errors3.email
                    ? `onboarding-pj-input onboarding-671-input input-invalid`
                    : `onboarding-pj-input onboarding-671-input`
                }
                placeholder="E-mail do contato"
                {...register3(`email`, {
                  required: true,
                  pattern: /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                })}
              />
              {errors3.email && (
                <div className="input-error-pj">Email inválido</div>
              )}
            </div>
          </div>
          <div className="row onboarding-pj-form-row mx-wd-695">
            <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 pd-right-8-pj">
              <InputMask
                id="phone"
                mask="(99) 99999-9999"
                type="tel"
                placeholder="Telefone / Ramal"
                {...register3(`phone`, {
                  pattern: /^\(((\d{2})|(\d{3}))\)\s(\d{5}|\d{4})-\d{4}$/,
                  required: true,
                })}
                className={
                  errors3.phone
                    ? `onboarding-pj-input onboarding-671-input input-invalid`
                    : `onboarding-pj-input onboarding-671-input`
                }
              />
              {errors3.phone && (
                <div className="input-error-pj">Telefone / Ramal inválido</div>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="onboarding-pj-flex-end">
        <button
          onClick={handleNext}
          className="onboarding-pj-btn-next"
          disabled={loading}
        >
          <div className="onboarding-pj-btn-next-txt-div">
            <span
              className={
                `onboarding-pj-btn-next-txt` + (loading ? ` loading` : ``)
              }
            >
              Próximo
            </span>
            <img src={arrow} className="ml-32" />
          </div>
        </button>
      </div>
    </div>
  )
}

export default Identification
