import { Link } from "react-router-dom"
import Logo from "../../../assets/images/kikkin_white_logo.svg"
import LogoGreen from "../../../assets/images/kikkin_green_logo.svg"
import Avatar from "../../../assets/images/navbar-banking/avatar.svg"
import Eye from "../../../assets/images/navbar-banking/navbar_eye.svg"
import ClosedEye from "../../../assets/images/navbar-banking/navbar_eye_closed.svg"
import Settings from "../../../assets/images/settings.svg"
import "./styles.scss"
import Switcher from "./switcher"
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react"
import { AuthContext, SHOW_TIMER_TIME } from "../../../context/AuthProvider"
import SVGInject from "@iconfu/svg-inject"
import { ThemeContext } from "../../../context/ThemeContext"
import dayjs from "dayjs"
import Timer from "../../../assets/images/navbar-banking/timer.svg"
import { BalanceContext } from "../../../context/BalanceProvider"
import { BankingNavigationContext } from "../../../context/BankingNavigationContext"

interface NavbarProps {
  isLogin?: boolean
  isBanking?: boolean
}

const Navbar = ({ isLogin, isBanking }: NavbarProps) => {
  const { logout, user, current_account, remaningTime } =
    useContext(AuthContext)
  const { balance, loadingBalance, refreshBalance } = useContext(BalanceContext)
  const { logo, logoutRedirectUri } = useContext(ThemeContext)
  const [showBalance, setShowBalance] = useState(true)
  const [idleTimer, setIdleTimer] = useState<number>()
  const timerRef = useRef(setInterval(() => {}, 1000))
  const ref = useRef<HTMLInputElement | null>(null)
  const { insurancePage, insuranceStep } = useContext(BankingNavigationContext)
  const handleScroll = () => {
    if (insurancePage == 1) {
      ref.current?.scrollIntoView({ behavior: `smooth` })
    }
  }

  useEffect(() => {
    refreshBalance()
  }, [user])

  useLayoutEffect(() => {
    //scroll to top when buying insurance
    handleScroll()
  }, [insuranceStep])

  useEffect(() => {
    clearInterval(timerRef.current)
    if (remaningTime <= SHOW_TIMER_TIME) {
      setIdleTimer(remaningTime)
      timerRef.current = setInterval(() => {
        setIdleTimer((t) => (t ? t - 1 : 0))
      }, 1000)
    } else {
      clearInterval(timerRef.current)
      setIdleTimer(undefined)
    }
  }, [remaningTime])

  if (isBanking) {
    return (
      <div ref={ref} id="header" className="navbar-banking-kikkin">
        <div className="navbar-first-section">
          <div className="d-flex align-items-center">
            <img
              style={{ maxWidth: 150, height: 60 }}
              src={logo || Logo}
              onLoad={(e) => SVGInject(e.target)}
            />
            {idleTimer ? (
              <div className="timer-container ml-3">
                <img src={Timer} height="50%" />
                {dayjs(0).second(idleTimer).format(`mm:ss`)}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="d-flex align-items-center">
            <img
              className="mr-32"
              src={Avatar}
              onLoad={(e) => SVGInject(e.target)}
            />
            <span className="span-navbar-user cursor-pointer">
              Olá, {user?.apelido || user?.nome}
            </span>
            <Link to="/account-management">
              <img
                src={Settings}
                alt="configs"
                height="20px"
                style={{
                  marginRight: `20px`,
                  filter: `brightness(0) invert(1)`,
                }}
                onLoad={(e) => SVGInject(e.target)}
              />
            </Link>
            <div className="navbar-division"></div>
            <span
              onClick={() => logout(logoutRedirectUri)}
              className="span-navbar-leave"
            >
              Sair
            </span>
          </div>
        </div>

        <div className="navbar-second-section">
          <Switcher />
          <div className="dp-flex align-items-center">
            <div className="navbar-balance mr-46">
              <span className="navbar-info-span">Conta</span>
              <span className="navbar-value-span">
                {current_account?.conta}-{current_account?.contaDigito}
              </span>
            </div>
            <div className="navbar-balance mr-46">
              <span className="navbar-info-span">Saldo</span>
              {!loadingBalance ? (
                <>
                  {showBalance ? (
                    <span className="navbar-value-span">
                      {balance?.toLocaleString(`pt-BR`, {
                        style: `currency`,
                        currency: `BRL`,
                      })}
                    </span>
                  ) : (
                    <span className="navbar-value-span">R$ ***</span>
                  )}
                </>
              ) : (
                <div className="spinner-header navbar-spinner"></div>
              )}
            </div>
            <span
              className={showBalance ? `` : `d-none`}
              onClick={() => setShowBalance(false)}
            >
              <img
                src={Eye}
                style={{ cursor: `pointer` }}
                onLoad={(e) => SVGInject(e.target)}
              />
            </span>
            <span
              className={showBalance ? `d-none` : ``}
              onClick={() => setShowBalance(true)}
            >
              <img
                style={{ cursor: `pointer` }}
                src={ClosedEye}
                onLoad={(e) => SVGInject(e.target)}
              />
            </span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={isLogin ? `navbar-login-kikkin` : `navbar-home-kikkin`}>
      <div className="vertical-center fl-left">
        <img
          width="100%"
          height="100%"
          style={{ maxWidth: 150, maxHeight: 40 }}
          src={logo || LogoGreen}
          onLoad={(e) => SVGInject(e.target)}
        />
      </div>

      <div className="vertical-center fl-right">
        {!user ? (
          <></>
        ) : (
          <button
            className={isLogin ? `btn-open-account-login` : `btn-open-account`}
            onClick={() => logout(logoutRedirectUri)}
          >
            <span className="txt-open-account">Sair</span>
          </button>
        )}
        {/* {!isLogin && (
          <span
            onClick={() => {
              handleAccess()
            }}
            className="navbar-login-txt"
          >
            Acessar
          </span>
        )} */}
      </div>
    </div>
  )
}

export default Navbar
