import { useState, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import CustomModal from "../../../../../features/CustomModal"
import CurrencyInput from "../../../../../features/CurrencyInput"
import {
  price_to_number,
  validateCurrency,
} from "../../../../../../utils/validation"
import { formattedCurrency } from "../../../../../../services/banking"
import "./styles.scss"

const CreateApprovalModal = ({ isOpen, onClose, onSubmit }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const [minValueCreateApproval, setMinValueCreateApproval] =
    useState<number>(0)

  const handleClose = () => {
    reset()
    setMinValueCreateApproval(0)
    onClose()
  }

  const handleFormSubmit = (data) => {
    onSubmit(data)
    reset()
    setMinValueCreateApproval(0)
  }

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    const name = e.target.name
    const fixedNumber = price_to_number(newValue)

    if (name === `amountGreaterThanOrEqual`)
      setMinValueCreateApproval(fixedNumber)
  }

  return (
    <CustomModal isOpen={isOpen} onClose={handleClose} containerBg={`#ffff`}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="modal-content-create-approval">
          <div className="modal-title-create-approval">
            Criar Pagamento de Boleto
          </div>
          <p className="modal-description-create-approval">
            Atenção, você está prestes a criar uma nova configuração de
            pagamento!
          </p>
          <div className="input-group-create-approval">
            <div className="input-group-create-approval-min-value">
              <label>Valor Mínimo</label>
              <div className="create-approval-min-value-box">
                <span className="create-approval-min-value-label">R$</span>
                <Controller
                  name="amountGreaterThanOrEqual"
                  control={control}
                  defaultValue={`0.00`}
                  rules={{
                    required: true,
                    validate: (value) => {
                      const numericValue = price_to_number(value)
                      return (
                        numericValue >= 0 || `O valor não pode ser negativo`
                      )
                    },
                    onChange: onValueChange,
                  }}
                  render={({ field }) => (
                    <CurrencyInput
                      value={field.value}
                      options={{ style: `decimal`, allowNegative: false }}
                      onChangeEvent={(_, maskedValue) => {
                        field.onChange(maskedValue)
                      }}
                      className="create-approval-min-value-input"
                    />
                  )}
                />
                {errors.editApprovalValue && (
                  <p className="bc-error-msg">Por favor, verifique o valor.</p>
                )}
              </div>
            </div>
            <div className="input-group-create-approval-quantity">
              <div className="approval-quantity-container">
                <label
                  className="approval-quantity-input-label approval-quantity-show-input"
                  htmlFor="create-approval-quantity"
                >
                  Aprovações Necessárias
                </label>
                <select
                  className="approval-quantity-select approval-quantity-select-value-txt"
                  id="create-approval-quantity"
                  {...register(`numberOfApprovals`, {})}
                  defaultValue={1}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
            </div>
          </div>
          <div className="modal-buttons-create-approval">
            <button onClick={handleClose} className="button-primary-outlined">
              Cancelar
            </button>
            <button className="button-primary">Confirmar</button>
          </div>
        </div>
      </form>
    </CustomModal>
  )
}

export default CreateApprovalModal
