import { useContext, useEffect, useState } from "react"
import "./styles.scss"
import { useNavigate } from "react-router-dom"
import Header from "../../features/Header"
import { useResposive } from "../../../hooks/useResponsive"
import { PaginationV2 } from "../../features/PaginationV2"
import SVGInject from "@iconfu/svg-inject"
import Remove from "../../../assets/images/close.svg"
import Pencil from "../../../assets/images/pencil.svg"
import services from "../../../services/svcmesh"
import ErrorModal from "../../features/ErrorModal"
import { AuthContext } from "../../../context/AuthProvider"

interface Profile {
  id: number
  nome: string
  createdAt: string
  modifiedAt: string
  grants: string
  operators: any[]
}

export const OperatorsManagement = () => {
  const userAuth = useContext(AuthContext).user

  const [optionSelected, setOptionSelected] = useState(0)

  const [openModalError, setOpenModalError] = useState(false)
  const [requestError, setRequestError] = useState<any>()

  //pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [resultsPerPage, setResultsPerPage] = useState(10)
  const [totalResults, setTotalResults] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [isOpMaster, setIsOpMaster] = useState(false)

  const navigate = useNavigate()
  const { isMobile } = useResposive()

  const handleButtonClick = () => {
    if (optionSelected === 1) {
      navigate(`/profile-management`)
    } else {
      navigate(`/user-management`)
    }
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    // Here you would typically fetch new data based on the new page
  }

  const handleResultsPerPageChange = (value: number) => {
    setResultsPerPage(value)
    setCurrentPage(1) // Reset to first page when changing results per page
    // Here you would typically fetch new data based on the new resultsPerPage
  }

  useEffect(() => {
    if (optionSelected === 1) getAllProfiles()
  }, [currentPage, resultsPerPage, optionSelected])

  const [profiles, setProfiles] = useState<Profile[]>([])

  const getAllProfiles = async () => {
    try {
      const response =
        await services.bankingService.getAuthorizationProfilesForAccount({
          page: currentPage,
          limit: resultsPerPage,
        })
      setProfiles(response.data.data)

      setTotalResults(response.data.total)
      setTotalPages(response.data.totalPages)
    } catch (error) {
      console.error(`Error fetching profiles:`, error)
    }
  }

  const groupGrants = (grants: string[]): { [key: string]: string[] } => {
    const grouped: { [key: string]: string[] } = {}

    const filteredGrants = grants.filter((grant) => {
      if (!grant.includes(`internal`) && !grant.includes(`public`)) return grant
    })

    filteredGrants.forEach((grant) => {
      const parts = grant.split(`.`)
      if (parts.length > 1) {
        const groupName = parts[1]
        if (!grouped[groupName]) {
          grouped[groupName] = []
        }
        grouped[groupName].push(grant)
      }
    })
    return grouped
  }

  const userTableHeaders = [
    `Status`,
    `Nome`,
    `Código`,
    `Perfil`,
    `Celular`,
    `CPF`,
    `id`,
    isOpMaster ? `Ações` : null,
  ]

  const profileTableHeaders = [
    `Status`,
    `Nome do Perfil`,
    `Serviços`,
    `Cartão de Crédito`,
    `Seguros`,
    `Conta Digital`,
    `id`,
    isOpMaster ? `Ações` : null,
  ]

  const [usersData, setUsersData] = useState<any[]>([])

  const getAllUsers = async () => {
    try {
      const response = await services.bankingService.getOperatorPeers({
        page: currentPage,
        limit: resultsPerPage,
      })
      setUsersData(response.data.data)
      setTotalResults(response.data.total)
      setTotalPages(response.data.totalPages)
    } catch (error) {
      console.error(`Error fetching users:`, error)
    }
  }

  useEffect(() => {
    if (optionSelected === 0) getAllUsers()
  }, [currentPage, resultsPerPage, optionSelected])

  useEffect(() => {
    console.log(`🚀 ~ usersData`, usersData)

    if (usersData.length > 0) {
      const foundUser = usersData.find(
        (user) => userAuth.oidc_id === user?.oidc_id,
      )
      if (foundUser?.role === `OPERATOR_PJ_MASTER`) {
        setIsOpMaster(true)
      }
    }
  }, [usersData])

  const renderHeadersAndRows = () => {
    if (optionSelected === 0) {
      return {
        headers: userTableHeaders,
        rows: usersData.map((user) => {
          return {
            status: user.status,
            nome: user.nome,
            codigo: user.oidc_id,
            perfil: user.role,
            celular: user?.phones[0]?.phone || 0,
            cpf: user.cpf,
            id: user.id,
          }
        }),
      }
    } else
      return {
        headers: profileTableHeaders,
        rows: profiles.map((profile) => {
          const groupedGrants = groupGrants(profile.grants.split(`,`))
          return {
            status: 1, // Assuming all profiles are active
            nomeDoPerfil: profile.nome,
            servicos: `${groupedGrants[`banking`]?.length || 0} permissões`,
            cartaoCredito: `${groupedGrants[`cards`]?.length || 0} permissões`,
            seguros: `${groupedGrants[`insurance`]?.length || 0} permissões`,
            contaDigital: `${groupedGrants[`banking`]?.length || 0} permissões`, // Assuming banking is related to conta digital
            id: profile.id, // Add the profile ID
          }
        }),
      }
  }
  const { headers, rows } = renderHeadersAndRows()

  const handleDeleteProfile = async (profileId: number) => {
    console.log(`Deleting profile with ID:`, profileId)
    try {
      const response = await services.bankingService.deleteAuthorizationProfile(
        profileId,
      )
      console.log(`Profile deleted:`, response)
      getAllProfiles()
    } catch (error) {
      setOpenModalError(true)
      setRequestError((error as any)?.response?.data)
      console.error(`Error deleting profile:`, error)
    }
  }

  const handleDeleteUser = async (userId: number) => {
    console.log(`Deleting user with ID:`, userId)
    try {
      const response = await services.bankingService.deleteOperator(userId)
      console.log(`User deleted:`, response)
      getAllUsers()
    } catch (error) {
      console.error(`Error deleting user:`, error)
    }
  }

  return (
    <div className="op-page-container">
      <Header />
      <div className="op-management-container">
        <div className="op-management-content">
          <div className="op-manager">
            <div className="options">
              {[`Gestão de Usuários`, `Gestão de Perfis`].map(
                (label, index) => (
                  <div
                    className={
                      isMobile && index === 0 ? `options-btn-mobile` : ``
                    }
                    key={index}
                  >
                    <button
                      className={
                        optionSelected === index
                          ? `op-management-option-btn`
                          : `op-management-option-btn-disabled`
                      }
                      onClick={() => setOptionSelected(index)}
                    >
                      {label}
                    </button>
                    {isMobile && index === 0 && (
                      <div className="new-btn-mobile">
                        <button
                          onClick={handleButtonClick}
                          className="button-primary"
                        >
                          +
                        </button>
                      </div>
                    )}
                  </div>
                ),
              )}
              {!isMobile && isOpMaster && (
                <div className="new-btn">
                  <button
                    onClick={handleButtonClick}
                    className="button-primary"
                  >
                    {optionSelected === 1 ? `NOVO PERFIL` : `NOVO USUÁRIO`}
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="op-table">
            <table className="table table-borderless">
              <thead>
                <tr>
                  {headers.map((header, i) => (
                    <th key={i} className="table-column-title" scope="col">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((row, i) => (
                  <tr key={i}>
                    {(Object.values(row) as (string | number)[]).map(
                      (cell, j) => (
                        <td key={j} className="mx-wd-145">
                          <div className="table-cell-center">
                            <div className="txt-overflow">
                              {j === 0 ? (
                                <div
                                  className="op-status"
                                  style={{
                                    backgroundColor:
                                      cell === 1 ? `green` : `red`,
                                  }}
                                ></div>
                              ) : (
                                <span className="row-txt-bold">
                                  {String(cell)}
                                </span>
                              )}
                            </div>
                          </div>
                        </td>
                      ),
                    )}
                    {isOpMaster && (
                      <td className="mx-wd-145">
                        <div className="table-cell-center">
                          <div className="actions-col">
                            <button
                              className="op-btn-action-update"
                              onClick={() =>
                                optionSelected === 0
                                  ? navigate(`/user-management/${row.codigo}`)
                                  : navigate(`/profile-management/${row.id}`)
                              }
                            >
                              <img
                                style={{ cursor: `pointer` }}
                                onLoad={(e) => SVGInject(e.target)}
                                src={Pencil}
                              />
                            </button>
                            <button
                              className="op-btn-action-delete"
                              onClick={() =>
                                optionSelected === 0
                                  ? handleDeleteUser(row.id as number)
                                  : handleDeleteProfile(row.id as number)
                              }
                            >
                              <img
                                src={Remove}
                                className="ml-24"
                                style={{ cursor: `pointer` }}
                                onLoad={(e) => SVGInject(e.target)}
                              />
                            </button>
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="op-pagination">
            <PaginationV2
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              totalResults={totalResults}
              resultsPerPage={resultsPerPage}
              onResultsPerPageChange={handleResultsPerPageChange}
            />
          </div>
        </div>
      </div>
      <ErrorModal
        isOpen={openModalError}
        onClose={() => setOpenModalError(false)}
        width="400px"
        height="auto"
      >
        <div className="error-message">
          <h2>Erro</h2>
          <p>
            Ocorreu um erro ao realizar essa ação. Por favor, tente novamente.
          </p>
          {requestError && <p>Erro: {requestError?.message}</p>}
        </div>
      </ErrorModal>
    </div>
  )
}
