export default {
  profilePage: {
    title: `New Profile`,
    banking: `Banking`,
    cards: `Cards`,
    insurance: `Ìnsurance`,
    pix: `PIX`,
  },
  grant: {
    cards: {
      view: `View Cards`,
      invoice: `View Invoices`,
      billing: `Change Invoice Due Date`,
      limit: `Card Limits`,
      replacement: `Request Replacement Card`,
      pay: `Pay or Installment Invoice`,
      prepaid: `Prepaid Cards`,
      additional: `Request Additional Card`,
      manage: `Modify Card`,
    },
    banking: {
      schedules: `View Scheduled Transactions`,
      statement: `Detailed Statement`,
      cnab: `CNAB File Transmission`,
      withdraw: `Bank Withdrawals`,
      pix: `PIX Add and Manage Payments`,
      signature: `Create and Manage Digital Signature`,
      boletopay: `Boleto Add and Manage Payments`,
      balance: `Bank Balance`,
      transfer: `Bank Transfer`,
      favorites: `Favorites`,
      boleto: `Generate Boleto and Manage Billing`,
      authorizationProfile: `Operator and Profile Management`,
      approvals: `Approve and Review Transactions`,
      registration: `Manage Registration`,
    },
    pix: {
      pay: `Add Payment`,
      key: `Key Management`,
    },
    insurance: {
      manage: `Contract Insurance`,
    },
  },
  phoneError: {
    invalidToPhone: `Error: Invalid 'To' Phone Number: {phone}`,
  },
}
