import SVGInject from "@iconfu/svg-inject"
import dayjs from "dayjs"
import { useContext, useEffect } from "react"
import { CSVLink } from "react-csv"
import DatePicker from "react-datepicker"
import InputMask from "react-input-mask"
import arrow from "../../../../../assets/images/arrow_yellow_down.svg"
import calendar from "../../../../../assets/images/calendar.svg"
import download from "../../../../../assets/images/download.svg"
import Spinner from "../../../../../assets/images/spinner-white.svg"
import trash from "../../../../../assets/images/trash.svg"
import { CustomModal, PrimaryButton } from "k4n-components"
import "./styles.scss"
import {
  DEFAULT_PERIOD_FILTER,
  TRANSACTIONS_ICONS_TITLES,
  SCHEDULES_ICONS_TITLES,
} from "../extrato.shared"
import { BalanceContext } from "../../../../../context/BalanceProvider"
import { AuthContext } from "../../../../../context/AuthProvider"
import SuccessModal from "../../../../features/SuccesModal"
import { ChevronDown } from "lucide-react"
import receipts from "../../../../../assets/images/receipts.svg"
import SpinnerComponent from "../../../../features/Spinner"

const ExtratoPanel = ({
  isHome,
  getSchedules,
  getStatements,
  preStatementsRequest,
  dateSelection,
  generateCsv,
  openDropdown,
  setOpenDropdown,
  callApiTrigger,
  optionButton,
  setOptionButton,
  dateOptionBtns,
  setDateOptionBtns,
  csv,
  setDateRange,
  startDate,
  endDate,
  statements,
  receives,
  outings,
  schedules,
  errorMessage,
  modalAlert,
  modalDelete,
  setModalAlert,
  setModalDelete,
  isSeeMoreButtonLoading,
  setIsSeeMoreButtonLoading,
  isApplyFilterButtonLoading,
  setIsApplyFilterButtonLoading,
  isStatementsLoading,
  getFormattedCurrency,
  handleOnClick,
  getTransactionReceipt,
  setIsLoadingReceiptPdf,
  isLoadingReceiptPdf,
}) => {
  const { refreshBalance } = useContext(BalanceContext)
  useEffect(() => {
    getStatements()
    refreshBalance()
  }, [callApiTrigger])
  const { user } = useContext(AuthContext)

  const handleGetTransactionReceipt = async (codigo) => {
    setIsLoadingReceiptPdf(true)
    await getTransactionReceipt(codigo)
    setIsLoadingReceiptPdf(false)
  }

  function MapList(props: any) {
    if (isStatementsLoading) {
      return (
        <div
          style={{
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            marginTop: `50px`,
          }}
        >
          <SpinnerComponent />
          {/*this is a skeleton schema
          <div className="extrato-data">
            <div className="extrato-row">
              <span className="grey-box gradient" />

              <span className="grey-box gradient" />

              <span className="grey-box gradient" />
            </div>
          </div>
          */}
        </div>
      )
    }
    switch (props.option) {
      case 1:
        if (statements.days.length) {
          return (
            <>
              <div className="transactions-table-container">
                <table className="transactions-table">
                  <thead>
                    <tr>
                      <th>
                        <div className="column-header">
                          <span>Tipo de Transação</span>
                          <ChevronDown size={16} />
                        </div>
                      </th>
                      <th>
                        <div className="column-header">
                          <span>Nome</span>
                          <ChevronDown size={16} />
                        </div>
                      </th>
                      <th>
                        <div className="column-header">
                          <span>Data</span>
                          <ChevronDown size={16} />
                        </div>
                      </th>
                      <th>
                        <div className="column-header">
                          <span>Valor (R$)</span>
                          <ChevronDown size={16} />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {statements.days.map((data, i) =>
                      data.transactions.map((dados, j) => {
                        return (
                          <tr key={dados.id}>
                            <td>
                              <div className="transaction-info">
                                {dados.descricaoOperacao.includes(`CUSTO`) ? (
                                  <>
                                    <img
                                      src={
                                        TRANSACTIONS_ICONS_TITLES[5001]?.icon ||
                                        ``
                                      }
                                      alt="transaction-icon"
                                      className="transaction-icon"
                                      onLoad={(e) => SVGInject(e.target)}
                                    />
                                    <span className="primary-info">
                                      {dados.descricaoOperacao
                                        .substring(0, 1)
                                        .toUpperCase() +
                                        dados.descricaoOperacao
                                          .substring(1)
                                          .toLowerCase()}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={
                                        TRANSACTIONS_ICONS_TITLES[
                                          dados.origemTransacao
                                        ]?.icon || ``
                                      }
                                      alt="transaction-icon"
                                      className="transaction-icon"
                                      onLoad={(e) => SVGInject(e.target)}
                                    />
                                    <span className="primary-info">
                                      {TRANSACTIONS_ICONS_TITLES[
                                        dados.origemTransacao
                                      ]?.title || ``}
                                    </span>
                                  </>
                                )}
                              </div>
                            </td>
                            <td>
                              <span className="secondary-info">
                                {dados.nome}
                              </span>
                            </td>
                            <td>
                              <span className="secondary-info">
                                {dayjs(
                                  dados.dtMovimentoUtc || data.date,
                                ).format(`DD/MM/YYYY`)}
                              </span>
                            </td>
                            <td>
                              <span className="secondary-info">
                                <FormattedCurrencySpan
                                  vlrMovimento={dados.vlrMovimento}
                                  tipoLancamento={dados.tipoLancamento}
                                />
                              </span>
                            </td>
                          </tr>
                        )
                      }),
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )
        } else {
          return (
            <>
              <h6 className="no-transactions-message">
                {!props.isHome
                  ? `Não foi encontrada nenhuma movimentação no período selecionado.
                  Por favor, tente outro.`
                  : `Não foi encontrada nenhuma movimentação nos últimos ${DEFAULT_PERIOD_FILTER} dias.`}
              </h6>
            </>
          )
        }

      case 2:
        if (receives.days.length) {
          return (
            <>
              <div className="transactions-table-container">
                <table className="transactions-table">
                  <thead>
                    <tr>
                      <th>
                        <div className="column-header">
                          <span>Tipo de Transação</span>
                          <ChevronDown size={16} />
                        </div>
                      </th>
                      <th>
                        <div className="column-header">
                          <span>Remetente</span>
                          <ChevronDown size={16} />
                        </div>
                      </th>
                      <th>
                        <div className="column-header">
                          <span>Data da Transação</span>
                          <ChevronDown size={16} />
                        </div>
                      </th>
                      <th>
                        <div className="column-header">
                          <span>Valor (R$)</span>
                          <ChevronDown size={16} />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {receives.days.map((data, i) =>
                      data.transactions.map((dados, j) => {
                        return (
                          <tr key={dados.id}>
                            <td>
                              <div className="transaction-info">
                                <img
                                  src={
                                    TRANSACTIONS_ICONS_TITLES[
                                      dados.origemTransacao
                                    ]?.icon || ``
                                  }
                                  alt="transaction-icon"
                                  className="transaction-icon"
                                  onLoad={(e) => SVGInject(e.target)}
                                />
                                <span className="primary-info">
                                  {TRANSACTIONS_ICONS_TITLES[
                                    dados.origemTransacao
                                  ]?.title || ``}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="transaction-info">
                                <span className="primary-info">
                                  {dados.nome}
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className="secondary-info">
                                {dayjs(
                                  dados.dtMovimentoUtc || data.date,
                                ).format(`DD/MM/YYYY`)}
                              </span>
                            </td>
                            <td>
                              <span className="secondary-info value-green">
                                <FormattedCurrencySpan
                                  vlrMovimento={dados.vlrMovimento}
                                  tipoLancamento={dados.tipoLancamento}
                                />
                              </span>
                            </td>
                          </tr>
                        )
                      }),
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )
        } else {
          return (
            <>
              <h6 className="no-transactions-message">
                Por enquanto nenhuma entrada no período selecionado. Toque no
                botão &quot;Ver mais&quot; se estiver disponível.
              </h6>
            </>
          )
        }

      case 3:
        if (outings.days.length) {
          return (
            <>
              <div className="transactions-table-container">
                <table className="transactions-table">
                  <thead>
                    <tr>
                      <th>
                        <div className="column-header">
                          <span>Tipo de Transação</span>
                          <ChevronDown size={16} />
                        </div>
                      </th>
                      <th>
                        <div className="column-header">
                          <span>Destinatário</span>
                          <ChevronDown size={16} />
                        </div>
                      </th>
                      <th>
                        <div className="column-header">
                          <span>Data da Transação</span>
                          <ChevronDown size={16} />
                        </div>
                      </th>
                      <th>
                        <div className="column-header">
                          <span>Valor (R$)</span>
                          <ChevronDown size={16} />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {outings.days.map((data, i) =>
                      data.transactions.map((dados, j) => {
                        return (
                          <tr key={dados.id}>
                            <td>
                              <div className="transaction-info">
                                {dados.descricaoOperacao.includes(`CUSTO`) ? (
                                  <>
                                    <img
                                      src={
                                        TRANSACTIONS_ICONS_TITLES[5001]?.icon ||
                                        ``
                                      }
                                      alt="transaction-icon"
                                      className="transaction-icon"
                                      onLoad={(e) => SVGInject(e.target)}
                                    />
                                    <span className="primary-info">
                                      {dados.descricaoOperacao
                                        .substring(0, 1)
                                        .toUpperCase() +
                                        dados.descricaoOperacao
                                          .substring(1)
                                          .toLowerCase()}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={
                                        TRANSACTIONS_ICONS_TITLES[
                                          dados.origemTransacao
                                        ]?.icon || ``
                                      }
                                      alt="transaction-icon"
                                      className="transaction-icon"
                                      onLoad={(e) => SVGInject(e.target)}
                                    />
                                    <span className="primary-info">
                                      {TRANSACTIONS_ICONS_TITLES[
                                        dados.origemTransacao
                                      ]?.title || ``}
                                    </span>
                                  </>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="transaction-info">
                                <span className="primary-info">
                                  {dados.nome}
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className="secondary-info">
                                {dayjs(
                                  dados.dtMovimentoUtc || data.date,
                                ).format(`DD/MM/YYYY`)}
                              </span>
                            </td>
                            <td>
                              <span className="secondary-info value-red">
                                <FormattedCurrencySpan
                                  vlrMovimento={dados.vlrMovimento}
                                  tipoLancamento={dados.tipoLancamento}
                                />
                              </span>
                            </td>
                          </tr>
                        )
                      }),
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )
        } else {
          return (
            <>
              <h6 className="no-transactions-message">
                Por enquanto nenhuma saída no período selecionado. Toque no
                botão &quot;Ver mais&quot; se estiver disponível.
              </h6>
            </>
          )
        }

      case 5:
        if (outings.days.length) {
          return (
            <>
              <div className="transactions-table-container">
                <table className="transactions-table">
                  <thead>
                    <tr>
                      <th>
                        <div className="column-header">
                          <span>Tipo de Transação</span>
                          <ChevronDown size={16} />
                        </div>
                      </th>
                      <th>
                        <div className="column-header">
                          <span>Destinatário</span>
                          <ChevronDown size={16} />
                        </div>
                      </th>
                      <th>
                        <div className="column-header">
                          <span>Data da Transação</span>
                          <ChevronDown size={16} />
                        </div>
                      </th>
                      <th>
                        <div className="column-header">
                          <span>Valor (R$)</span>
                          <ChevronDown size={16} />
                        </div>
                      </th>
                      <th>
                        <div className="column-header">
                          <span>Ações</span>
                          <ChevronDown size={16} />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {outings.days.map((data, i) =>
                      data.transactions.map((dados, j) => {
                        return (
                          <tr key={dados.id}>
                            <td>
                              <div className="transaction-info">
                                {dados.descricaoOperacao.includes(`CUSTO`) ? (
                                  <>
                                    <img
                                      src={
                                        TRANSACTIONS_ICONS_TITLES[5001]?.icon ||
                                        ``
                                      }
                                      alt="transaction-icon"
                                      className="transaction-icon"
                                      onLoad={(e) => SVGInject(e.target)}
                                    />
                                    <span className="primary-info">
                                      {dados.descricaoOperacao
                                        .substring(0, 1)
                                        .toUpperCase() +
                                        dados.descricaoOperacao
                                          .substring(1)
                                          .toLowerCase()}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={
                                        TRANSACTIONS_ICONS_TITLES[
                                          dados.origemTransacao
                                        ]?.icon || ``
                                      }
                                      alt="transaction-icon"
                                      className="transaction-icon"
                                      onLoad={(e) => SVGInject(e.target)}
                                    />
                                    <span className="primary-info">
                                      {TRANSACTIONS_ICONS_TITLES[
                                        dados.origemTransacao
                                      ]?.title || ``}
                                    </span>
                                  </>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="transaction-info">
                                <span className="primary-info">
                                  {dados.nome}
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className="secondary-info">
                                {dayjs(dados.dtMovimentoUtc).format(
                                  `DD/MM/YYYY`,
                                )}
                              </span>
                            </td>
                            <td>
                              <span className="secondary-info value-red">
                                <FormattedCurrencySpan
                                  vlrMovimento={dados.vlrMovimento}
                                  tipoLancamento={dados.tipoLancamento}
                                />
                              </span>
                            </td>
                            <td>
                              <div className="col col-flex">
                                <button
                                  onClick={() =>
                                    handleGetTransactionReceipt(dados.codigo)
                                  }
                                  disabled={isLoadingReceiptPdf}
                                  className="btn-receipts-transaction"
                                  style={{
                                    pointerEvents: isLoadingReceiptPdf
                                      ? `none`
                                      : `all`,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: `flex`,
                                      alignItems: `center`,
                                      gap: `8px`,
                                    }}
                                  >
                                    {isLoadingReceiptPdf ? (
                                      <>
                                        <div
                                          className="spinner-receipts"
                                          role="status"
                                        ></div>
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          src={receipts}
                                          alt="2° Via do Comprovante"
                                          onLoad={(e) => SVGInject(e.target)}
                                          className="receipt-icon"
                                        />
                                      </>
                                    )}
                                  </div>
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      }),
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )
        } else {
          return (
            <>
              <h6 className="no-transactions-message">
                Por enquanto nenhuma 2° Via no período selecionado. Toque no
                botão &quot;Ver mais&quot; se estiver disponível.
              </h6>
            </>
          )
        }

      default:
        if (schedules.days.length) {
          return (
            <>
              {schedules.days.map((data, i) => (
                <div key={i} className="extrato-data">
                  <div className="extrato-date">
                    <img
                      src={calendar}
                      alt={`calendar`}
                      className="extrato-calendar-icon"
                      onLoad={(e) => SVGInject(e.target)}
                    />
                    <span className="date">
                      {dayjs(data.date).isSame(dayjs(), `d`)
                        ? `Hoje, ${dayjs(data.date).format(`DD/MM/YYYY`)}`
                        : dayjs(data.date).format(`DD/MM/YYYY`)}
                    </span>
                  </div>
                  {data.transactions.map((dados, j) => (
                    <div key={j} className="schedule-row">
                      <div className="schedule-operation">
                        <img
                          src={SCHEDULES_ICONS_TITLES[dados.type].icon}
                          alt={`transaction-icon`}
                          className="transaction-icon"
                          onLoad={(e) => SVGInject(e.target)}
                        />
                        <span className="data-text-14-black ">
                          {SCHEDULES_ICONS_TITLES[dados.type].title}
                        </span>
                      </div>
                      <span className="value-red float-right">
                        <FormattedCurrencySpan
                          vlrMovimento={Number(dados.value)}
                          schedule
                        />
                      </span>
                      <div
                        className="delete-operation"
                        onClick={() => handleOnClick(dados)}
                      >
                        <img className="delete-img" src={trash} alt="" />
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </>
          )
        } else {
          return (
            <h6 className="no-transactions-message">
              Nenhum pagamento agendado.
            </h6>
          )
        }
    }
  }

  function FormattedCurrencySpan(props: any) {
    if (props.tipoLancamento === `C`) {
      return (
        <span className="value-green">
          {`+ ${getFormattedCurrency(props.vlrMovimento)}`}
        </span>
      )
    } else if (props.tipoLancamento === `D` || props.schedule) {
      return (
        <span className="value-red">
          {`- ${getFormattedCurrency(props.vlrMovimento)}`}
        </span>
      )
    } else {
      return <></>
    }
  }

  return (
    <>
      {!isHome ? (
        <div className="extrato-container">
          <div className="extrato-content">
            <div className="date-manager">
              <span className="extrato-date-text">Busque por um período</span>
              <button
                className="date-button"
                onClick={() => setOpenDropdown(!openDropdown)}
              >
                <span className="date-span">Selecione</span>
                <img
                  src={arrow}
                  alt=""
                  className="arrow-icon"
                  onLoad={(e) => SVGInject(e.target)}
                />
              </button>
              {openDropdown && (
                <>
                  {/* <div
                    className="modal-overlay"
                    onClick={() => {
                      setOpenDropdown(false)
                    }}
                  ></div> */}

                  <div className="date-dropdown-menu">
                    <div className="extrato-filter-close-div">
                      <button
                        className="extrato-filter-close-btn"
                        onClick={() => setOpenDropdown(!openDropdown)}
                      ></button>
                    </div>
                    <h1 className="date-dropdown-menu-title">
                      Escolha um período
                    </h1>
                    <span className="date-dropdown-menu-span">Períodos</span>
                    <div className="date-dropdown-menu-buttons">
                      {dateOptionBtns === 0 ? (
                        <button
                          className="last-day-btn-active"
                          onClick={() => {
                            dateSelection(0)
                          }}
                        >
                          <span className="dropdown-span-active">
                            Último dia
                          </span>
                        </button>
                      ) : (
                        <button
                          className="last-day-btn"
                          onClick={() => {
                            dateSelection(0), setDateOptionBtns(0)
                          }}
                        >
                          <span className="dropdown-span">Último dia</span>
                        </button>
                      )}
                      {dateOptionBtns === 7 ? (
                        <button
                          className="seven-days-btn-active"
                          onClick={() => {
                            dateSelection(7)
                          }}
                        >
                          <span className="dropdown-span-active">7 dias</span>
                        </button>
                      ) : (
                        <button
                          className="seven-days-btn"
                          onClick={() => {
                            dateSelection(7), setDateOptionBtns(7)
                          }}
                        >
                          <span className="dropdown-span">7 dias</span>
                        </button>
                      )}
                      {dateOptionBtns === 30 ? (
                        <button
                          className="thirty-days-btn-active"
                          onClick={() => {
                            dateSelection(30)
                          }}
                        >
                          <span className="dropdown-span-active">30 dias</span>
                        </button>
                      ) : (
                        <button
                          className="thirty-days-btn"
                          onClick={() => {
                            dateSelection(30), setDateOptionBtns(30)
                          }}
                        >
                          <span className="dropdown-span">30 dias</span>
                        </button>
                      )}
                      {dateOptionBtns === 90 ? (
                        <button
                          className="ninety-days-btn-active"
                          onClick={() => {
                            dateSelection(90)
                          }}
                        >
                          <span className="dropdown-span-active">90 dias</span>
                        </button>
                      ) : (
                        <button
                          className="ninety-days-btn"
                          onClick={() => {
                            dateSelection(90), setDateOptionBtns(90)
                          }}
                        >
                          <span className="dropdown-span">90 dias</span>
                        </button>
                      )}
                    </div>
                    <span className="date-dropdown-menu-span">Período</span>
                    <div
                      className="calendar-div"
                      onClick={() => setDateOptionBtns(1)}
                    >
                      <img
                        src={calendar}
                        alt=""
                        className="date-manager-calendar-icon"
                        onLoad={(e) => SVGInject(e.target)}
                      />
                      <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        locale="pt-BR"
                        onChange={(update: any) => {
                          setDateRange(update)
                        }}
                        dateFormat="dd/MM/yyyy"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        required={true}
                        placeholderText="Filtre por data"
                        customInput={
                          <InputMask
                            mask="99/99/9999 - 99/99/9999"
                            className="statement-input-date"
                          />
                        }
                        maxDate={dayjs().toDate()}
                      />
                      {/* <img
                      className="clear-x"
                      alt=""
                      onClick={clearDates}
                      src={clearX}
                      onLoad={(e) => SVGInject(e.target)}
                    /> */}
                    </div>
                    {errorMessage && (
                      <span className="pix-error-msg">
                        Você deve escolher uma data inicial e uma data final
                      </span>
                    )}
                    <div className="filter-btn">
                      <PrimaryButton
                        onClick={() => {
                          setIsApplyFilterButtonLoading(true)
                          preStatementsRequest(true)
                        }}
                        disabled={isApplyFilterButtonLoading}
                        width={`173px`}
                        height={`56px`}
                      >
                        {isApplyFilterButtonLoading ? (
                          <img
                            className="btn-spinner"
                            src={Spinner}
                            alt="loading"
                            onLoad={(e) => SVGInject(e.target)}
                          />
                        ) : (
                          `Aplicar filtros`
                        )}
                      </PrimaryButton>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="options">
              {optionButton === 1 ? (
                <button className="extrato-option-btn">Tudo</button>
              ) : (
                <button
                  className="extrato-option-btn-disabled"
                  onClick={() => setOptionButton(1)}
                >
                  Tudo
                </button>
              )}
              {optionButton === 2 ? (
                <button className="extrato-option-btn">Entradas</button>
              ) : (
                <button
                  className="extrato-option-btn-disabled"
                  onClick={() => setOptionButton(2)}
                >
                  Entradas
                </button>
              )}
              {optionButton === 3 ? (
                <button className="extrato-option-btn">Saídas</button>
              ) : (
                <button
                  className="extrato-option-btn-disabled"
                  onClick={() => setOptionButton(3)}
                >
                  Saídas
                </button>
              )}
              {optionButton === 4 ? (
                <button className="extrato-option-btn">Futuras</button>
              ) : (
                <button
                  className="extrato-option-btn-disabled"
                  onClick={() => {
                    getSchedules()
                    setOptionButton(4)
                  }}
                >
                  Futuras
                </button>
              )}
              {optionButton === 5 ? (
                <button className="extrato-option-btn">
                  2° Via de Comprovantes
                </button>
              ) : (
                <button
                  className="extrato-option-btn-disabled"
                  onClick={() => setOptionButton(5)}
                >
                  2° Via de Comprovantes
                </button>
              )}

              {optionButton !== 5 && (
                <button
                  className="download-button"
                  onClick={() => {
                    generateCsv()
                  }}
                >
                  <CSVLink data={csv} separator={`;`} filename={`extrato.csv`}>
                    <img
                      src={download}
                      alt=""
                      className=""
                      onLoad={(e) => SVGInject(e.target)}
                    />
                  </CSVLink>
                </button>
              )}
            </div>
            <MapList option={optionButton} />
            {statements.next_relative_final_date && optionButton != 4 && (
              <div className="see-more-btn">
                <PrimaryButton
                  onClick={() => {
                    setIsSeeMoreButtonLoading(true)
                    preStatementsRequest(false)
                  }}
                  disabled={isSeeMoreButtonLoading}
                  width={`173px`}
                  height={`56px`}
                >
                  {isSeeMoreButtonLoading ? (
                    <div className="navbar-spinner"></div>
                  ) : (
                    `Ver mais`
                  )}
                </PrimaryButton>
              </div>
            )}
          </div>
          <CustomModal
            isOpen={modalAlert}
            onClose={() => setModalAlert(``)}
            isBanking
          >
            <div className="px-5 py-3">{modalAlert}</div>
          </CustomModal>
          <SuccessModal
            isOpen={modalDelete}
            onClose={() => setModalDelete(``)}
            width={``}
            height={``}
          >
            <div className="px-5 py-3 extrato-modal-success">{modalDelete}</div>
          </SuccessModal>
        </div>
      ) : (
        <>
          <MapList option={1} />
        </>
      )}
    </>
  )
}
export default ExtratoPanel
