import { useState, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import CustomModal from "../../../../../features/CustomModal"
import CurrencyInput from "../../../../../features/CurrencyInput"
import {
  price_to_number,
  validateCurrency,
} from "../../../../../../utils/validation"
import "./styles.scss"

interface EditApprovalModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: any) => void
  defaultEditApprovalValue: string
  defaultEditApprovalQuantity: number
}

const EditApprovalModal = ({
  isOpen,
  onClose,
  onSubmit,
  defaultEditApprovalValue,
  defaultEditApprovalQuantity,
}: EditApprovalModalProps) => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amountGreaterThanOrEqual: defaultEditApprovalValue || `0.00`,
      numberOfApprovals: defaultEditApprovalQuantity || 1,
    },
  })

  const [minValueEditApproval, setMinValueEditApproval] = useState<number>(0)

  useEffect(() => {
    setValue(`amountGreaterThanOrEqual`, defaultEditApprovalValue)
    setValue(`numberOfApprovals`, defaultEditApprovalQuantity)
    setMinValueEditApproval(price_to_number(defaultEditApprovalValue || `0.00`))
  }, [defaultEditApprovalValue, defaultEditApprovalQuantity, setValue])

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    const name = e.target.name
    const fixedNumber = price_to_number(newValue)

    if (name === `amountGreaterThanOrEqual`)
      setMinValueEditApproval(fixedNumber)
  }

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} containerBg={`#ffff`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-content-edit-approval">
          <div className="modal-title-edit-approval">
            Editar Pagamento de Boleto
          </div>
          <p className="modal-description-edit-approval">
            Informe aqui o valor mínimo para a requisição de aprovações, e a
            quantidade de aprovações necessárias para a transação
          </p>
          <div className="input-group-edit-approval">
            <div className="input-group-edit-approval-min-value">
              <label>Valor Mínimo</label>
              <div className="edit-approval-min-value-box">
                <span className="edit-approval-min-value-label">R$</span>
                <Controller
                  name="amountGreaterThanOrEqual"
                  control={control}
                  defaultValue={defaultEditApprovalValue}
                  rules={{
                    required: true,
                    validate: (value) => {
                      const numericValue = price_to_number(value)
                      return (
                        numericValue >= 0 || `O valor não pode ser negativo`
                      )
                    },
                    onChange: onValueChange,
                  }}
                  render={({ field }) => (
                    <CurrencyInput
                      value={field.value}
                      options={{ style: `decimal`, allowNegative: false }}
                      onChangeEvent={(_, maskedValue) => {
                        field.onChange(maskedValue)
                      }}
                      className="edit-approval-min-value-input"
                    />
                  )}
                />
                {errors.amountGreaterThanOrEqual && (
                  <p className="bc-error-msg">Por favor, verifique o valor.</p>
                )}
              </div>
            </div>
            <div className="input-group-edit-approval-quantity">
              <div className="approval-quantity-container">
                <label
                  className="approval-quantity-input-label approval-quantity-show-input"
                  htmlFor="edit-approval-quantity"
                >
                  Aprovações Necessárias
                </label>
                <select
                  className="approval-quantity-select approval-quantity-select-value-txt"
                  id="edit-approval-quantity"
                  {...register(`numberOfApprovals`, {})}
                  defaultValue={defaultEditApprovalQuantity}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
            </div>
          </div>
          <div className="modal-buttons-edit-approval">
            <button onClick={onClose} className="button-primary-outlined">
              Cancelar
            </button>
            <button className="button-primary">Confirmar</button>
          </div>
        </div>
      </form>
    </CustomModal>
  )
}

export default EditApprovalModal
