import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"
import { useContext } from "react"
import { BMP_BANK_NUMBER } from "../../../../../../assets/constants"
import entradaDinheiro from "../../../../../../assets/images/entrada_dinheiro.svg"
import saidaDinheiro from "../../../../../../assets/images/saida_dinheiro.svg"
import share from "../../../../../../assets/images/share_yellow.svg"
import { AuthContext } from "../../../../../../context/AuthProvider"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import { TransferContext } from "../../../../../../context/TransferContext"
import { formattedCurrency } from "../../../../../../services/banking"
import { censorCpf } from "../../../../../../utils/validation"
import "./styles.scss"
import SVGInject from "@iconfu/svg-inject"
import clearX from "../../../../../../assets/images/clear_x.svg"

dayjs.extend(isBetween)

const TransferReceiptMobile = ({ handleDownloadImage }) => {
  const {
    favoriteData,
    transferValue,
    transferStep3Data,
    transferTime,
    transactionId,
  } = useContext(TransferContext)
  const { user, current_account } = useContext(AuthContext)
  const { changeTransferStep } = useContext(BankingNavigationContext)
  const scheduleCondition =
    dayjs(transferStep3Data.date).isAfter(dayjs(), `d`) ||
    (dayjs().isBetween(
      dayjs().hour(0).minute(0).second(0).millisecond(0),
      dayjs().hour(7).minute(0).second(0).millisecond(0),
      `ms`,
    ) &&
      favoriteData.numero_banco !== BMP_BANK_NUMBER)

  return (
    <div className="transfer-mobile-header">
      <div className="panel-mobile-content">
        <div className="transfer-receipt-mobile-receipt-btns">
          <button className="close-btn" onClick={() => changeTransferStep(1)}>
            <img
              src={clearX}
              width="24"
              height="24"
              alt="/"
              onLoad={(e) => SVGInject(e.target)}
            />
          </button>
          <button className="share-btn" onClick={handleDownloadImage}>
            <img src={share} alt="/" onLoad={(e) => SVGInject(e.target)} />
          </button>
        </div>
        <div id="capture" className="pd-2">
          <h1 className="transfer-receipt-mobile-receipt-title">
            Comprovante de Transfer&ecirc;ncia
          </h1>
          <div>
            <span className="transfer-receipt-mobile-span-title-18">
              {scheduleCondition ? `Agendado para` : `Realizado em`}
            </span>
            <span className="transfer-receipt-row-span-title-18 w700">
              {scheduleCondition
                ? dayjs(transferStep3Data.date).format(`DD/MM/YYYY`)
                : transferTime}
            </span>
          </div>

          <div className="d-flex mt-2 mb-2">
            <h2 className="section-title transfer-receipt-mobile-d-flex-w-50">
              Valor da transf&ecirc;ncia
            </h2>
            <span className="section-2-span transfer-receipt-mobile-d-flex-w-50">
              {formattedCurrency(transferValue)}
            </span>
          </div>
          {transferStep3Data.reason && (
            <div className="transfer-receipt-mobile-receipt-message">
              <div className="transfer-receipt-mobile-info-div">
                <h1 className="info-span-bold-16">Mensagem:</h1>
                <h1 className="info-span-grey-16">
                  {transferStep3Data.reason}
                </h1>
              </div>
            </div>
          )}
          <div className="transfer-receipt-mobile-receipt-img-div mb-2">
            <img
              src={entradaDinheiro}
              alt=""
              onLoad={(e) => SVGInject(e.target)}
            />
            <span className="section-title padding-left-10">Destino</span>
          </div>
          <div className="transfer-receipt-mobile-info-div-wrapper">
            <div className="transfer-receipt-mobile-info-div">
              <h1 className="info-span-bold-16">Nome:</h1>
              <h1 className="info-span-grey-16 transfer-receipt-mobile-align-right w-100">
                {favoriteData.nome}
              </h1>
            </div>
            <div className="transfer-receipt-mobile-info-div">
              <h1 className="info-span-bold-16">CPF:</h1>
              <h1 className="info-span-grey-16 transfer-receipt-mobile-align-right w-100">
                {censorCpf(favoriteData.cpf || ``)}
              </h1>
            </div>
            <div className="transfer-receipt-mobile-info-div">
              <h1 className="info-span-bold-16">Instituição:</h1>
              <h1 className="info-span-grey-16 transfer-receipt-mobile-align-right w-100">
                {favoriteData.numero_banco} - {favoriteData.instituicao}
              </h1>
            </div>
            <div className="transfer-receipt-mobile-info-div">
              <h1 className="info-span-bold-16">Agência:</h1>
              <h1 className="info-span-grey-16 transfer-receipt-mobile-align-right w-100">{`${
                favoriteData.agencia
              }-${favoriteData.agencia_digito || `0`}`}</h1>
            </div>
            <div className="transfer-receipt-mobile-info-div">
              <h1 className="info-span-bold-16">Conta:</h1>
              <h1 className="info-span-grey-16 transfer-receipt-mobile-align-right w-100">{`${favoriteData.conta}-${favoriteData.conta_digito}`}</h1>
            </div>
          </div>
          <div className="transfer-receipt-mobile-receipt-img-div margin-top-30 mb-2">
            <img
              src={saidaDinheiro}
              alt=""
              onLoad={(e) => SVGInject(e.target)}
            />
            <span className="section-title padding-left-10">Origem</span>
          </div>
          <div className="transfer-receipt-mobile-info-div-wrapper">
            <div className="transfer-receipt-mobile-info-div">
              <h1 className="info-span-bold-16">Nome:</h1>
              <h1 className="info-span-grey-16 transfer-receipt-mobile-align-right w-100">
                {user?.nome}
              </h1>
            </div>
            <div className="transfer-receipt-mobile-info-div">
              <h1 className="info-span-bold-16">CPF:</h1>
              <h1 className="info-span-grey-16 transfer-receipt-mobile-align-right w-100">
                {censorCpf(user?.cpf || ``)}
              </h1>
            </div>
            <div className="transfer-receipt-mobile-info-div">
              <h1 className="info-span-bold-16">Instituição:</h1>
              <h1 className="info-span-grey-16 transfer-receipt-mobile-align-right w-100">
                BMP Money Plus
              </h1>
            </div>
            <div className="transfer-receipt-mobile-info-div">
              <h1 className="info-span-bold-16">Agência:</h1>
              <h1 className="info-span-grey-16 transfer-receipt-mobile-align-right w-100">{`${
                current_account?.agencia
              }-${current_account?.agenciaDigito || `0`}`}</h1>
            </div>
            <div className="transfer-receipt-mobile-info-div">
              <h1 className="info-span-bold-16">Conta:</h1>
              <h1 className="info-span-grey-16 transfer-receipt-mobile-align-right w-100">{`${current_account?.conta}-${current_account?.contaDigito}`}</h1>
            </div>
          </div>
          {!scheduleCondition && (
            <div className="receipt-transaction-id-div">
              <div className="transfer-receipt-mobile-info-div">
                <h1 className="info-span-bold-16">ID da transação:</h1>
                <h1 className="info-span-grey-16">{transactionId}</h1>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TransferReceiptMobile
