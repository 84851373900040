import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import translationEN from "../../../utils/i18n/locales/en-us"
import translationPT from "../../../utils/i18n/locales/pt-br"

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEN },
    pt: { translation: translationPT },
  },
  lng: `pt`, // Default language
  fallbackLng: `pt`,
  interpolation: {
    escapeValue: false, // React already escapes by default
  },
})

export default i18n
