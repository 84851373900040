export default {
  profilePage: {
    title: `Novo Perfil`,
    banking: `Conta Digital`,
    cards: `Cartões`,
    insurance: `Seguros`,
    pix: `PIX`,
  },
  grant: {
    cards: {
      view: `Visualizar cartões`,
      invoice: `Visualizar faturas`,
      billing: `Alterar vencimento fatura`,
      limit: `Limite de cartões`,
      replacement: `Solicitar segunda via`,
      pay: `Pagar ou parcelar fatura`,
      prepaid: `Cartões pré-pagos`,
      additional: `Solicitar cartão adicional`,
      manage: `Alterar Cartão`,
    },
    banking: {
      schedules: `Consultar agendamentos`,
      statement: `Extrato Detalhado`,
      cnab: `CNAB transmissão de arquivo`,
      withdraw: `Saques Bancários`,
      pix: `PIX incluir e Gerenciar Pagamentos`,
      signature: `Criar e gerenciar assinatura digital`,
      boletopay: `Boleto Incluir e Gerenciar Pagamentos`,
      balance: `Saldo Bancário`,
      transfer: `Transferência Bancária`,
      favorites: `Favoritos`,
      boleto: `Gerar Boleto e gerenciar cobrança`,
      authorizationProfile: `Gestão de Operadores e Perfis`,
      approvals: `Aprovar e Consultar Transações`,
      registration: `Cadastro gerenciar`,
    },
    pix: {
      pay: `Incluir pagamento`,
      key: `Gestão de Chaves`,
    },
    insurance: {
      manage: `Seguro contratar`,
    },
  },
  phoneError: {
    invalidToPhone: `Erro: Número de telefone inválido: {{phone}}`,
  },
}
