import { ReactNode } from "react"
import { useResposive } from "../../../hooks/useResponsive"
import "./styles.scss"
import close from "../../../assets/images/clear_x.svg"
import SVGInject from "@iconfu/svg-inject"
interface ModalProps {
  isOpen: boolean
  onClose?: () => void
  children?: ReactNode
  containerBg?: string
  isBanking?: boolean
  center?: boolean
  loadingModal?: boolean
  modalBottom?: boolean
}

const CustomModal = ({
  isOpen,
  onClose,
  children,
  containerBg,
  center = true,
  loadingModal = false,
  modalBottom,
}: ModalProps) => {
  const { isTabletOrMobile } = useResposive()

  const defaultBg = `var(--k4n-primary-color)`

  return isOpen ? (
    <div
      className={
        isTabletOrMobile
          ? `modal-wrapper-custom-mobile`
          : `modal-wrapper-custom`
      }
      style={{
        justifyContent: center ? `center` : `flex-start`,
        overflowY: center ? undefined : `scroll`,
      }}
    >
      <div
        className="modal-overlay"
        onClick={loadingModal ? undefined : onClose}
      ></div>
      <div
        className={
          isTabletOrMobile
            ? modalBottom
              ? `modal-container-mobile modal-button`
              : `modal-container-mobile`
            : `modal-container`
        }
        style={
          containerBg
            ? { backgroundColor: containerBg }
            : { backgroundColor: defaultBg }
        }
      >
        {!loadingModal && (
          <div className="flex-right mb-3">
            <div onClick={onClose}>
              <img
                src={close}
                alt=""
                width="24"
                height="24"
                onLoad={(e) => SVGInject(e.target)}
              />
            </div>
          </div>
        )}
        {children}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default CustomModal
