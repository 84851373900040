import dayjs from "dayjs"
import {
  GetCustomerSchedulesResponse,
  GetCustomerStatementResponse,
} from "k4n-svcmesh-sdk"
import { useContext, useEffect, useState } from "react"
import { BMP_BANK_NUMBER } from "../../../../assets/constants"
import { AuthContext } from "../../../../context/AuthProvider"
import { useResposive } from "../../../../hooks/useResponsive"
import services from "../../../../services/svcmesh"
import {
  csvData,
  DEFAULT_PERIOD_FILTER,
  RequestData,
  SchedulesRequestData,
  TRANSACTIONS_ICONS_TITLES,
} from "./extrato.shared"
import ExtratoMobile from "./mobile"
import ExtratoPanel from "./panel"
import "./styles.scss"

const Extrato = (props: any) => {
  const { user, current_account } = useContext(AuthContext)
  const { isTabletOrMobile } = useResposive()
  const [openDropdown, setOpenDropdown] = useState(false)
  const [isHome, setIsHome] = useState(false)
  const [callApiTrigger, setCallApiTrigger] = useState(0)
  const [firstCall, setFirstCall] = useState(true)
  const [optionButton, setOptionButton] = useState(1)
  const [dateOptionBtns, setDateOptionBtns] = useState(DEFAULT_PERIOD_FILTER)
  const [csv, setCsv] = useState<csvData[]>([
    {
      Data: ``,
      Valor: `0`,
      Nome: ``,
      Descricao: ``,
    },
  ])
  const [dateRange, setDateRange] = useState([
    dayjs().subtract(DEFAULT_PERIOD_FILTER, `d`).toDate(),
    dayjs().toDate(),
  ])
  const [startDate, endDate] = dateRange
  const [statements, setStatements] = useState<GetCustomerStatementResponse>({
    days: [],
    next_relative_final_date: ``,
  })
  const [receives, setReceives] = useState<GetCustomerStatementResponse>({
    days: [],
    next_relative_final_date: ``,
  })
  const [outings, setOutings] = useState<GetCustomerStatementResponse>({
    days: [],
    next_relative_final_date: ``,
  })
  const [schedules, setSchedules] = useState<GetCustomerSchedulesResponse>({
    days: [],
  })
  const [requestData, setRequestData] = useState<RequestData>({
    initial_date: dayjs()
      .subtract(DEFAULT_PERIOD_FILTER, `d`)
      .format(`YYYY-MM-DD`),
    relative_final_date: dayjs().format(`YYYY-MM-DD`),
  })
  const [schedulesRequest, setSchedulesRequest] =
    useState<SchedulesRequestData>({
      customer_pf_id: Number(user?.id),
    })
  const [errorMessage, showErrorMessage] = useState(false)
  const [modalAlert, setModalAlert] = useState(``)
  const [modalDelete, setModalDelete] = useState(``)
  const [isSeeMoreButtonLoading, setIsSeeMoreButtonLoading] = useState(false)
  const [isApplyFilterButtonLoading, setIsApplyFilterButtonLoading] =
    useState(false)
  const [isStatementsLoading, setIsStatementsLoading] = useState(false)
  const [isLoadingReceiptPdf, setIsLoadingReceiptPdf] = useState(false)

  const showAlertModal = (message: string) => {
    setModalAlert(message)
  }

  async function getSchedules() {
    const data = await services.bankingService.getCustomerSchedules(
      schedulesRequest,
    )
    setSchedules(data)
  }
  async function getStatements() {
    setIsStatementsLoading(true)
    try {
      const data = await services.bankingService.getCustomerStatement({
        ...requestData,
        bank_number:
          Number(current_account?.numeroBanco) || Number(BMP_BANK_NUMBER),
        bank_agency: current_account?.agencia || ``,
        bank_agency_digit: current_account?.agenciaDigito,
        account_number: current_account?.conta || ``,
        account_digit: current_account?.contaDigito || ``,
        account_type: current_account?.tipoConta || 3,
      })

      const outing = data.days
        .map((obj1) => ({
          ...obj1,
          transactions: obj1.transactions.filter(
            (obj2) => obj2.tipoLancamento === `D`,
          ),
        }))
        .filter((obj3) => obj3.transactions.length)

      const received = data.days
        .map((obj1) => ({
          ...obj1,
          transactions: obj1.transactions.filter(
            (obj2) => obj2.tipoLancamento === `C`,
          ),
        }))
        .filter((obj3) => obj3.transactions.length)

      if (statements.next_relative_final_date && !firstCall) {
        setStatements({ ...data, days: statements.days.concat(data.days) })
        setReceives({ ...receives, days: receives.days.concat(received) })
        setOutings({ ...outings, days: outings.days.concat(outing) })
      } else {
        setStatements(data)
        setReceives({ ...receives, days: received })
        setOutings({ ...outings, days: outing })
      }
      setIsSeeMoreButtonLoading(false)
      setIsApplyFilterButtonLoading(false)
      setIsStatementsLoading(false)
      console.log(statements)
    } catch (e: any) {
      showAlertModal(e?.response?.data?.message || e?.message || e)
      setIsSeeMoreButtonLoading(false)
      setIsApplyFilterButtonLoading(false)
      setIsStatementsLoading(false)
    }
  }

  async function preStatementsRequest(first_call: boolean) {
    if (!endDate || !startDate) {
      setIsApplyFilterButtonLoading(false)
      showErrorMessage(true)
    } else {
      setFirstCall(first_call)
      showErrorMessage(false)
      if (first_call) setOpenDropdown(!openDropdown)
      setRequestData({
        initial_date: dayjs(startDate).format(`YYYY-MM-DD`),
        relative_final_date: first_call
          ? dayjs(endDate).format(`YYYY-MM-DD`)
          : statements.next_relative_final_date,
      })
      setCallApiTrigger(callApiTrigger + 1)
    }
  }
  function dateSelection(days: number) {
    const selection = [dayjs().subtract(days, `d`).toDate(), dayjs().toDate()]
    setDateRange(selection)
  }

  function generateCsv() {
    const aux: Array<csvData> = []
    statements.days.forEach((d) => {
      d.transactions.forEach((dados) => {
        aux.push({
          Data: d.date,
          Descricao: dados.descricaoOperacao.includes(`CUSTO`)
            ? dados.descricaoOperacao
            : TRANSACTIONS_ICONS_TITLES[dados.origemTransacao]?.title || ``,
          Nome: dados.nome,
          Valor:
            dados.tipoLancamento === `C`
              ? formatValueWithoutSymbol(dados.vlrMovimento)
              : formatValueWithoutSymbol(-dados.vlrMovimento),
        })
      })
    })
    setCsv(aux)
  }

  function getFormattedCurrency(currency: number) {
    return currency.toLocaleString(`pt-BR`, {
      style: `currency`,
      currency: `BRL`,
    })
  }

  function formatValueWithoutSymbol(value: number) {
    return value.toLocaleString(`pt-br`, { minimumFractionDigits: 2 })
  }

  useEffect(() => {
    if (props.isHome === true) setIsHome(true)
  }, [isHome])

  async function handleOnClick(dados) {
    await handleDelete(dados).then(getSchedules)
  }

  async function handleDelete(dados) {
    let response
    switch (dados.type) {
      case `boleto`:
        response = await services.bankingService.cancelScheduledBoleto({
          id: dados.id,
          customer_pf: { id: user?.id },
        })
        if (response.data.sucesso) {
          setModalDelete(response.data.mensagem)
        } else {
          setModalAlert(response.data.mensagem)
        }
        break
      case `pix`:
        response = await services.bankingService.cancelScheduledPix({
          id: dados.id,
          customer_pf: { id: user?.id },
        })
        if (response.data.sucesso) {
          setModalDelete(response.data.mensagem)
        } else {
          setModalAlert(response.data.mensagem)
        }
        break
      case `transfer`:
        response = await services.bankingService.cancelScheduledTransfer({
          id: dados.id,
          customer_pf: { id: user?.id },
        })
        if (response.data.sucesso) {
          setModalDelete(response.data.mensagem)
        } else {
          setModalAlert(response.data.mensagem)
        }
        break
      case `ted`:
        response = await services.bankingService.cancelScheduledTed({
          id: dados.id,
          customer_pf: { id: user?.id },
        })
        if (response.data.sucesso) {
          setModalDelete(response.data.mensagem)
        } else {
          setModalAlert(response.data.mensagem)
        }
        break
      default:
    }
  }

  async function getTransactionReceipt(codigoTransacao: string) {
    setIsLoadingReceiptPdf(true)
    const response = await services.bankingService.getTransactionReceiptPdf(
      codigoTransacao,
    )
    if (response && response.data.comprovantePdf.receiptPdf) {
      const linkSource = `data:application/pdf;base64,${response.data.comprovantePdf.receiptPdf}`
      const downloadLink = document.createElement(`a`)
      const date = new Date()
      date.setHours(date.getHours() - 3)
      const formattedDate = date.toISOString().replace(/[:.-]/g, `_`)
      const fileName = `comprovante_${formattedDate}.pdf`
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    }
    setIsLoadingReceiptPdf(false)
  }

  return (
    <>
      {isTabletOrMobile === true ? (
        <div className={!isHome ? `extrato-wrapper-mobile` : ``}>
          <ExtratoMobile
            isHome={isHome}
            getSchedules={getSchedules}
            getStatements={getStatements}
            preStatementsRequest={preStatementsRequest}
            dateSelection={dateSelection}
            getFormattedCurrency={getFormattedCurrency}
            generateCsv={generateCsv}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
            callApiTrigger={callApiTrigger}
            optionButton={optionButton}
            setOptionButton={setOptionButton}
            dateOptionBtns={dateOptionBtns}
            setDateOptionBtns={setDateOptionBtns}
            csv={csv}
            setDateRange={setDateRange}
            startDate={startDate}
            endDate={endDate}
            statements={statements}
            receives={receives}
            outings={outings}
            schedules={schedules}
            errorMessage={errorMessage}
            modalAlert={modalAlert}
            modalDelete={modalDelete}
            setModalAlert={setModalAlert}
            setModalDelete={setModalDelete}
            isSeeMoreButtonLoading={isSeeMoreButtonLoading}
            setIsSeeMoreButtonLoading={setIsSeeMoreButtonLoading}
            isApplyFilterButtonLoading={isApplyFilterButtonLoading}
            setIsApplyFilterButtonLoading={setIsApplyFilterButtonLoading}
            isStatementsLoading={isStatementsLoading}
            handleOnClick={handleOnClick}
            getTransactionReceipt={getTransactionReceipt}
            setIsLoadingReceiptPdf={setIsLoadingReceiptPdf}
            isLoadingReceiptPdf={isLoadingReceiptPdf}
          />
        </div>
      ) : (
        <>
          <div className={!isHome ? `extrato-wrapper` : ``}>
            <div className="extrato-header">
              <h1 className="extrato-title ">Extrato</h1>
            </div>
            <ExtratoPanel
              isHome={isHome}
              getSchedules={getSchedules}
              getStatements={getStatements}
              preStatementsRequest={preStatementsRequest}
              dateSelection={dateSelection}
              getFormattedCurrency={getFormattedCurrency}
              generateCsv={generateCsv}
              openDropdown={openDropdown}
              setOpenDropdown={setOpenDropdown}
              callApiTrigger={callApiTrigger}
              optionButton={optionButton}
              setOptionButton={setOptionButton}
              dateOptionBtns={dateOptionBtns}
              setDateOptionBtns={setDateOptionBtns}
              csv={csv}
              setDateRange={setDateRange}
              startDate={startDate}
              endDate={endDate}
              statements={statements}
              receives={receives}
              outings={outings}
              schedules={schedules}
              errorMessage={errorMessage}
              modalAlert={modalAlert}
              modalDelete={modalDelete}
              setModalAlert={setModalAlert}
              setModalDelete={setModalDelete}
              isSeeMoreButtonLoading={isSeeMoreButtonLoading}
              setIsSeeMoreButtonLoading={setIsSeeMoreButtonLoading}
              isApplyFilterButtonLoading={isApplyFilterButtonLoading}
              setIsApplyFilterButtonLoading={setIsApplyFilterButtonLoading}
              isStatementsLoading={isStatementsLoading}
              handleOnClick={handleOnClick}
              getTransactionReceipt={getTransactionReceipt}
              setIsLoadingReceiptPdf={setIsLoadingReceiptPdf}
              isLoadingReceiptPdf={isLoadingReceiptPdf}
            />
          </div>
        </>
      )}
    </>
  )
}
export default Extrato
