import { ChangeEvent, FormEvent, useContext, useState } from "react"
import { OnboardingContext } from "../../../context/OnboardingContext"
import defaultLogo from "../../../assets/images/logo_kikkin_white.svg"
import { ThemeContext } from "../../../context/ThemeContext"
import { useForm } from "react-hook-form"

const Alias = () => {
  const { goBack, saveAlias, alias } = useContext(OnboardingContext)
  const { logo } = useContext(ThemeContext)

  const [tempAlias, setTempAlias] = useState(alias)
  const [error, setError] = useState(``)

  const handleSaveAlias = (e: FormEvent) => {
    e.preventDefault()
    const validatedName = validateName()
    if (validatedName && isValid) saveAlias(tempAlias)
  }

  const validateName = () => {
    const input = document.getElementById(`alias-input`) as HTMLInputElement
    if (!input) return false

    const clearName = input.value.trim()
    if (clearName.length == 0) {
      setError(` O campo nome é obrigatório`)
      input.className = input.className + ` input-invalid`
      return false
    }
    if (clearName.length < 3) {
      setError(`Nome muito curto`)
      input.className = input.className + ` input-invalid`
      return false
    } else if (clearName.length > 250) {
      setError(`Nome muito grande`)
      input.className = input.className + ` input-invalid`
      return false
    } else if (clearName.match(/[0-9]/)) {
      setError(`Seu nome não pode conter números`)
      input.className = input.className + ` input-invalid`
      return false
    }
    setError(``)
    input.className = input.className.replace(` input-invalid`, ``)
    return clearName
  }

  const handleChangeAlias = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trimStart()

    // Capitalize
    //value = value.replace(/\b\w/g, l => l.toUpperCase());
    let newString = ``
    for (let i = 0; i < value.length; i++) {
      if (i === 0 || value.charAt(i - 1) === ` `) {
        newString += value.charAt(i).toUpperCase()
      } else {
        newString += value.charAt(i).toLowerCase()
      }
    }

    setTempAlias(newString)
  }

  const {
    register: register,
    formState: { errors: erros, isValid: isValid },
  } = useForm({ mode: `onChange`, reValidateMode: `onChange` })

  return (
    <form className="onboarding-form-container" onSubmit={handleSaveAlias}>
      <button className="onboarding-go-back" onClick={goBack} type="button" />

      <img
        style={{ marginTop: `120px` }}
        src={logo || defaultLogo}
        width="80px"
        height="27px"
      />
      <div className="onboarding-question-primary">
        E como você gostaria de ser chamado?
      </div>
      <input
        id="alias-input"
        className="onboarding-input"
        placeholder="Digite seu nome"
        value={tempAlias}
        autoFocus
        {...register(`nome`, {
          required: true,
          pattern: /^(([A-zÀ-ÿ]+)(\ )?){0,}$/,
          onChange: handleChangeAlias,
        })}
      />
      {erros.nome && <div className="input-error-pj">Nome inválido</div>}
      <div className="input-error">{error}</div>
      <div className="flex-end">
        <button className="onboarding-btn-next" type="submit" />
      </div>
    </form>
  )
}

export default Alias
