import SVGInject from "@iconfu/svg-inject"
import { useContext, useEffect, useMemo, useState } from "react"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form"
import { Document, Page } from "react-pdf"
import { TERMOS_E_CONDICOES } from "../../../../assets/constants"
import Arrow from "../../../../assets/images/arrow.svg"
import arrow from "../../../../assets/images/arrow_right_white.svg"
import close from "../../../../assets/images/clear_x.svg"
import whiteLogo from "../../../../assets/images/logo-kikkin-verde.svg"
import Spinner from "../../../../assets/images/spinner-white.svg"
import arrowLeft from "../../../../assets/images/yellow-arrow-left.svg"
import { OnboardingPjContext } from "../../../../context/OnboardingPjContext"
import { useResposive } from "../../../../hooks/useResponsive"
import services from "../../../../services/svcmesh"
import { toBase64 } from "../../../../utils/utils"
import CustomModal from "../../../features/CustomModal"
import "./styles.scss"

interface FilesForm {
  identidade: FileList
  identificationType: string
  contratoSocial: FileList
  termosECondicoes: boolean
}

// avoid re-rendering the options
const DocumentType = {
  CPF: 1,
  RG: 2,
  PASSPORTE: 3,
  CNH: 5,
}

export const Documents = () => {
  const [pdf, setPdf] = useState(``)
  const [numPages, setNumPages] = useState(0)
  const { isTabletOrMobile } = useResposive()
  const [pageNumber, setPageNumber] = useState(1)
  const [modalPdf, setModalPdf] = useState(false)
  const [openModalPdfLoading, setOpenModalPdfLoading] = useState(false)
  const {
    goBack,
    step1Data,
    addClientePjId,
    clientePjId,
    next,
    loading,
    setLoading,
  } = useContext(OnboardingPjContext)

  const [fileInputKeys, setFileInputKeys] = useState({
    identidade: Date.now(),
    contratoSocial: Date.now(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
    setValue,
  } = useForm({
    mode: `onChange`,
    reValidateMode: `onChange`,
  })

  const [selectedFiles, setSelectedFiles] = useState<{
    identidade?: File
    contratoSocial?: File
  }>({})

  useEffect(() => {
    console.log(`selectedFiles`, selectedFiles)
  }, [selectedFiles])

  const onSubmit: SubmitHandler<FieldValues> = async (data, event) => {
    event?.preventDefault()
    setLoading(true)

    try {
      if (
        !data.identidade?.[0] ||
        !data.contratoSocial?.[0] ||
        !data.termosECondicoes ||
        !data.identificationType
      ) {
        if (data.identidade?.[0]) {
          setError(`identidade`, {
            type: `required`,
            message: `Este campo é obrigatório.`,
          })
        }
        if (data.contratoSocial?.[0]) {
          setError(`contratoSocial`, {
            type: `required`,
            message: `Este campo é obrigatório.`,
          })
        }
        if (!data.termosECondicoes) {
          setError(`termosECondicoes`, {
            type: `required`,
            message: `Este campo é obrigatório.`,
          })
        }
        if (!data.identificationType) {
          setError(`identificationType`, {
            type: `required`,
            message: `Este campo é obrigatório.`,
          })
        }
        return
      }

      const [identidadeFile, contratoSocialFile] = await Promise.all([
        toBase64(data.identidade?.[0]),
        toBase64(data.contratoSocial?.[0]),
      ])

      const res = await services.bankingService.saveOnboardingPj(step1Data)
      if (res.data.data.id) {
        addClientePjId(res.data.data.id)
        setLoading(false)
      }

      const createdUserId = res.data.data.id

      // upload identification and social contract files
      const doc1res = await services.bankingService.onboardingPjUpload(
        identidadeFile,
        createdUserId,
        data.identificationType,
      )
      if (doc1res.status !== 200 && doc1res.status !== 201) {
        console.error(`Error uploading file 1:`, doc1res)
        setError(`identidade`, {
          type: `required`,
          message: `Erro ao enviar o arquivo. Por favor, tente novamente.`,
        })
        return
      }

      const doc2res = await services.bankingService.onboardingPjUpload(
        contratoSocialFile,
        createdUserId,
        7,
      )
      if (doc2res.status !== 201 && doc2res.status !== 200) {
        console.error(`Error uploading file 2:`, doc2res)
        setError(`contratoSocial`, {
          type: `required`,
          message: `Erro ao enviar o arquivo. Por favor, tente novamente.`,
        })
        return
      }

      // finish the onboarding
      const resFinalStep = await services.bankingService.saveOnboardingPj2(
        createdUserId,
        step1Data.emails[0].email,
        step1Data.phones[0].phone,
      )
      if (resFinalStep.status !== 201) {
        console.error(`Onboarding completion failed:`, resFinalStep)
        setError(`termosECondicoes`, {
          type: `required`,
          message: `Erro na criação de conta PJ. Por favor, tente novamente.`,
        })
        return
      }

      localStorage.removeItem(`step1Data`)

      setLoading(false)
      next()
    } catch (error) {
      setLoading(false)
      console.error(`Error during file upload or onboarding completion:`, error)
    }
  }

  const onSubmitError = (errors) => {
    console.log(`Validation Errors:`, errors)
  }

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: keyof FilesForm,
  ) => {
    const file = event.target.files?.[0]
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        // Check if file size is greater than 5MB
        setError(field, {
          type: `size`,
          message: `O tamanho do arquivo deve ser menor que 5MB`,
        })
        return
      }
      setSelectedFiles((prev) => ({ ...prev, [field]: file }))
      clearErrors(field)
    }
  }

  const validateFile = (file: File) => {
    if (!file || file.size === 0) return `Este campo é obrigatório.`
    if (file.size > 5 * 1024 * 1024) {
      return `O tamanho do arquivo deve ser menor que 5MB`
    }
    return true
  }

  const handleRemoveFile = (field: keyof FilesForm) => {
    setSelectedFiles((prev) => ({ ...prev, [field]: undefined }))

    // Reset the file input by re-rendering it with a unique key
    const fileInput = document.getElementById(field) as HTMLInputElement | null
    if (fileInput) {
      fileInput.value = `` // Clears the file input programmatically
    }

    setValue(field, null) // Reset the input value

    // Update the key to force re-rendering
    setFileInputKeys((prevKeys) => ({
      ...prevKeys,
      [field]: Date.now(), // Generates a new key
    }))

    clearErrors(field)
  }

  const getPdfInformativo = async () => {
    setOpenModalPdfLoading(true)

    try {
      if (isTabletOrMobile) {
        const link = document.createElement(`a`)
        link.href = TERMOS_E_CONDICOES
        link.setAttribute(`download`, `Termo de aceite.pdf`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        setPdf(TERMOS_E_CONDICOES)
        setModalPdf(true)
      }
    } catch (error) {
      console.error(`Error fetching PDF:`, error)
    } finally {
      setOpenModalPdfLoading(false)
    }
  }

  const onDocumentLoadSuccess = ({ numPages: loadedPages }) => {
    setNumPages(loadedPages)
    setPageNumber(1)
  }

  const closeModalPdf = () => {
    setModalPdf(false)
    setPageNumber(1)
    setNumPages(0)
  }

  const previousPage = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1)
  }

  const nextPage = () => {
    if (pageNumber < numPages) setPageNumber(pageNumber + 1)
  }

  const documentTypeOptions = useMemo(() => {
    return Object.keys(DocumentType).map((key, index) => (
      <option key={index} value={DocumentType[key]}>
        {key}
      </option>
    ))
  }, [])

  console.log(`Data:`, getValues(`identificationType`))

  return (
    <div className="onboarding-pj-form-container">
      <img
        className="onboarding-pj-go-back"
        onClick={goBack}
        src={arrowLeft}
        alt="Go Back"
      />
      <img
        width={78}
        height={25}
        className="k4n-white-logo"
        src={whiteLogo}
        alt="Logo"
      />

      <form
        onSubmit={handleSubmit(onSubmit, onSubmitError)}
        id="onboarding-pj-form-files"
      >
        {/* First document */}
        <div className="onboarding-expand-primary w-100">
          <div className="row onboarding-pj-expand-row">
            <div className="col no-padding-col">
              <span className="onboarding-pj-medium-title">
                1. Identificação
              </span>
            </div>
          </div>
        </div>
        <div className="onboarding-pj-upload-btn">
          <div className="col no-padding-col">
            <span className="onboarding-pj-small-txt-white">
              Esse documento é muito importante para nós, nele consta todos os
              seus dados básicos. (Envio obrigatório)
            </span>
          </div>
          <div>
            {/* Hidden File Input */}
            <input
              key={fileInputKeys.identidade}
              id="identidade"
              type="file"
              accept=".pdf,.jpg,.jpeg,.png"
              {...register(`identidade`, {
                required: { value: true, message: `Este campo é obrigatório.` },
                onChange: (e) => handleFileChange(e, `identidade`),
                validate: validateFile,
              })}
              className="onboarding-pj-file-input-hidden"
            />
            {/* Button to Trigger File Input */}
            <label htmlFor="identidade" className="onboarding-pj-btn-next mt-2">
              <div className="onboarding-pj-btn-next-txt-div">
                <span className="onboarding-pj-btn-next-txt">Selecionar</span>
              </div>
            </label>

            <select
              className="onboarding-pj-select onboarding-278-input"
              id="identificationType"
              defaultValue={`0`}
              {...register(`identificationType`, {
                validate: (value) => value !== `0`,
                required: {
                  value: true,
                  message: `Este campo é obrigatório.`,
                },
              })}
              style={{
                color: `var(--k4n-primary-color)`,
                marginLeft: `10px`,
              }}
            >
              <option value="0" disabled className="bc-input-disabled-bg">
                Tipo de documento
              </option>
              {documentTypeOptions}
            </select>
          </div>
          {errors?.identificationType && (
            <div className="input-error-pj">
              {String(errors?.identificationType?.message) ||
                `Verifique o tipo de documento.`}
            </div>
          )}
          {errors?.identidade && (
            <div className="input-error-pj">
              {String(errors?.identidade?.message) ||
                `Este campo é obrigatório.`}
            </div>
          )}
          {/* Display Selected File Name */}
          {!errors.identidade && selectedFiles?.identidade && (
            <div className="col align-items-center file-row">
              <span className="file-name me-2">
                {selectedFiles?.identidade?.name}
              </span>
              <div onClick={() => handleRemoveFile(`identidade`)}>
                <img
                  src={close}
                  alt="Remove"
                  width="24"
                  height="24"
                  onLoad={(e) => SVGInject(e.target)}
                  className="onboarding-pj-close-icon"
                />
              </div>
            </div>
          )}
        </div>

        {/* Second document */}
        <div className="onboarding-expand-primary w-100">
          <div className="row onboarding-pj-expand-row">
            <div className="col no-padding-col">
              <span className="onboarding-pj-medium-title">
                2. Contrato Social
              </span>
            </div>
          </div>
        </div>
        <div className="onboarding-pj-upload-btn">
          <div className="col no-padding-col">
            <span className="onboarding-pj-small-txt-white">
              Esse documento é muito importante para nós, nele consta todos os
              dados básicos da sua empresa. (Envio obrigatório)
            </span>
          </div>
          <div>
            {/* Hidden File Input */}
            <input
              key={fileInputKeys.contratoSocial}
              id="contratoSocial"
              type="file"
              accept=".pdf,.jpg,.jpeg,.png"
              {...register(`contratoSocial`, {
                required: { value: true, message: `Este campo é obrigatório.` },
                onChange: (e) => handleFileChange(e, `contratoSocial`),
                validate: validateFile,
              })}
              className="onboarding-pj-file-input-hidden"
            />
            {/* Button to Trigger File Input */}
            <label
              htmlFor="contratoSocial"
              className="onboarding-pj-btn-next mt-2"
            >
              <div className="onboarding-pj-btn-next-txt-div">
                <span className="onboarding-pj-btn-next-txt">Selecionar</span>
              </div>
            </label>
          </div>
          {/* Display Selected File Name */}
          {!errors.contratoSocial && selectedFiles?.contratoSocial && (
            <div className="col align-items-center file-row">
              <span className="file-name me-2">
                {selectedFiles?.contratoSocial?.name}
              </span>
              <div onClick={() => handleRemoveFile(`contratoSocial`)}>
                <img
                  src={close}
                  alt="Remove"
                  width="24"
                  height="24"
                  onLoad={(e) => SVGInject(e.target)}
                  className="onboarding-pj-close-icon"
                />
              </div>
            </div>
          )}
          {errors.contratoSocial && (
            <div className="input-error-pj">
              {String(errors?.contratoSocial?.message) ||
                `Este campo é obrigatório.`}
            </div>
          )}
        </div>

        {/* Termo  de aceite / check box */}
        <div
          className="insurance-terms-link mt-4"
          onClick={() => getPdfInformativo()}
        >
          <div>
            <input
              type="checkbox"
              className="form-check-input"
              {...register(`termosECondicoes`, {
                required: { value: true, message: `Este campo é obrigatório.` },
              })}
            />
            <span className="insurance-terms-apolicy-txt mt-2">
              Concordo com os Termos e condições
            </span>
          </div>
          {errors.termosECondicoes && (
            <p className="input-error-pj">
              {String(errors?.termosECondicoes?.message) ||
                `Este campo é obrigatório.`}
            </p>
          )}
        </div>

        <div className="insurance-terms-policy">
          <CustomModal
            isOpen={openModalPdfLoading}
            isBanking
            loadingModal={true}
          >
            <div className="container row justify-content-between align-items-center">
              <div className="pix-modal-sucess-h1 col-6">
                Carregando documento...
              </div>
              <div className="col-6">
                <img
                  style={{ marginLeft: `100%`, width: 70 }}
                  src={Spinner}
                  alt="loading"
                  onLoad={(e) => SVGInject(e.target)}
                />
              </div>
            </div>
          </CustomModal>
          <CustomModal
            isOpen={modalPdf}
            onClose={closeModalPdf}
            isBanking
            center={false}
          >
            <>
              <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                <Page
                  width={isTabletOrMobile ? 200 : 500}
                  height={isTabletOrMobile ? 300 : 500}
                  pageNumber={pageNumber}
                />
              </Document>
              {numPages > 1 && (
                <div>
                  <p className="modal-pdf-text">
                    Página {pageNumber || (numPages ? 1 : `--`)} de{` `}
                    {numPages || `--`}
                  </p>
                  <div style={{ display: `inline-flex` }}>
                    <button
                      type="button"
                      className="pdf-modal-previous-page-button"
                      style={{ opacity: pageNumber <= 1 ? 0.5 : 1 }}
                      disabled={pageNumber <= 1}
                      onClick={previousPage}
                    />
                    <button
                      type="button"
                      className="pdf-modal-next-page-button"
                      disabled={pageNumber >= numPages}
                      style={{ opacity: pageNumber >= numPages ? 0.5 : 1 }}
                      onClick={nextPage}
                    />
                  </div>
                </div>
              )}
              <a
                className="modal-pdf-href"
                download={`Termo de aceite.pdf`}
                href={pdf}
              >
                Baixar PDF
              </a>
              <button
                type="button"
                onClick={closeModalPdf}
                className="btn-modal-pdf"
              >
                <span className="txt-modal-pdf-ok">OK</span>
                <img
                  src={Arrow}
                  onLoad={(e) => SVGInject(e.target)}
                  className="arrow-next"
                  alt=">"
                />
              </button>
            </>
          </CustomModal>
        </div>
      </form>
      <div className="onboarding-pj-flex-end">
        <button
          type="submit"
          form="onboarding-pj-form-files"
          // onClick={handleNext}
          className="onboarding-pj-btn-next"
          disabled={loading}
        >
          <div className="onboarding-pj-btn-next-txt-div">
            <span
              className={
                `onboarding-pj-btn-next-txt` + (loading ? ` loading` : ``)
              }
            >
              Próximo
            </span>
            <img src={arrow} className="ml-32" />
          </div>
        </button>
      </div>
    </div>
  )
}
