import { useContext, useEffect } from "react"
import { BankingNavigationContext } from "../../../../../context/BankingNavigationContext"
import download from "../../../../../assets/images/download.svg"
import filter from "../../../../../assets/images/filter.svg"
import Spinner from "../../../../../assets/images/spinner-white.svg"
import back from "../../../../../assets/images/arrow_yellow.svg"
import close from "../../../../../assets/images/clear_x.svg"
import calendar from "../../../../../assets/images/calendar.svg"
import "./styles.scss"
import SVGInject from "@iconfu/svg-inject"
import dayjs from "dayjs"
import DatePicker from "react-datepicker"
import InputMask from "react-input-mask"
import { PrimaryButton, CustomModal } from "k4n-components"
import { CSVLink } from "react-csv"
import {
  DEFAULT_PERIOD_FILTER,
  TRANSACTIONS_ICONS_TITLES,
  SCHEDULES_ICONS_TITLES,
} from "../extrato.shared"
import { BalanceContext } from "../../../../../context/BalanceProvider"
import { useNavigate } from "react-router-dom"
import SuccessModal from "../../../../features/SuccesModal"
import trash from "../../../../../assets/images/trash.svg"
import receipts from "../../../../../assets/images/receipts.svg"
import SpinnerComponent from "../../../../features/Spinner"

const ExtratoMobile = ({
  isHome,
  getSchedules,
  getStatements,
  preStatementsRequest,
  dateSelection,
  getFormattedCurrency,
  generateCsv,
  openDropdown,
  setOpenDropdown,
  callApiTrigger,
  optionButton,
  setOptionButton,
  dateOptionBtns,
  setDateOptionBtns,
  csv,
  setDateRange,
  startDate,
  endDate,
  statements,
  receives,
  outings,
  schedules,
  errorMessage,
  modalAlert,
  modalDelete,
  setModalAlert,
  setModalDelete,
  isSeeMoreButtonLoading,
  setIsSeeMoreButtonLoading,
  isApplyFilterButtonLoading,
  setIsApplyFilterButtonLoading,
  isStatementsLoading,
  handleOnClick,
  getTransactionReceipt,
  setIsLoadingReceiptPdf,
  isLoadingReceiptPdf,
}) => {
  const { refreshBalance } = useContext(BalanceContext)
  const navigate = useNavigate()
  function isFullName(name: string) {
    if (name.split(` `).length > 1) {
      return true
    } else return false
  }

  useEffect(() => {
    refreshBalance()
    getStatements()
  }, [callApiTrigger])

  const handleGetTransactionReceipt = async (codigo) => {
    setIsLoadingReceiptPdf(true)
    await getTransactionReceipt(codigo)
    setIsLoadingReceiptPdf(false)
  }

  function DateOptions() {
    return (
      <>
        <div
          className="modal-overlay"
          onClick={() => {
            setOpenDropdown(false)
          }}
        ></div>
        <div className="dropdown-statements-menu-mobile">
          <button
            className="dropdown-statements-menu-mobile-close"
            onClick={() => {
              setOpenDropdown(false)
            }}
          >
            <img src={close} alt="" onLoad={(e) => SVGInject(e.target)} />
          </button>
          <h1 className="statement-mobile-title-h1">
            Qual período você quer filtrar?
          </h1>
          <span className="statement-mobile-text-22">Períodos</span>
          <div className="dropdown-statements-menu-btns-div">
            {dateOptionBtns === 0 ? (
              <button className="extrato-option-btn-mobile">Último dia</button>
            ) : (
              <button
                className="extrato-option-btn-mobile-disable"
                onClick={() => {
                  dateSelection(0)
                  setDateOptionBtns(0)
                }}
              >
                Último dia
              </button>
            )}
            {dateOptionBtns === 7 ? (
              <button className="extrato-option-btn-mobile">7 dias</button>
            ) : (
              <button
                className="extrato-option-btn-mobile-disable"
                onClick={() => {
                  dateSelection(7)
                  setDateOptionBtns(7)
                }}
              >
                7 dias
              </button>
            )}
            {dateOptionBtns === 30 ? (
              <button className="extrato-option-btn-mobile">30 dias</button>
            ) : (
              <button
                className="extrato-option-btn-mobile-disable"
                onClick={() => {
                  dateSelection(30)
                  setDateOptionBtns(30)
                }}
              >
                30 dias
              </button>
            )}
            {dateOptionBtns === 90 ? (
              <button className="extrato-option-btn-mobile">90 dias</button>
            ) : (
              <button
                className="extrato-option-btn-mobile-disable"
                onClick={() => {
                  dateSelection(90)
                  setDateOptionBtns(90)
                }}
              >
                90 dias
              </button>
            )}
          </div>
          <span className="statement-mobile-text-22">Período</span>
          <div
            className="dropdown-calendar-mobile-div"
            onClick={() => setDateOptionBtns(1)}
          >
            <img
              src={calendar}
              alt=""
              className="date-manager-calendar-icon"
              onLoad={(e) => SVGInject(e.target)}
            />
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              locale="pt-BR"
              onChange={(update: any) => {
                setDateRange(update)
              }}
              dateFormat="dd/MM/yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              required={true}
              placeholderText="Filtre por data"
              customInput={
                <InputMask
                  mask="99/99/9999 - 99/99/9999"
                  maskPlaceholder={null}
                  className="statement-input-date"
                />
              }
              maxDate={dayjs().toDate()}
            />
          </div>
          {errorMessage && (
            <span className="pix-error-msg">
              Você deve escolher uma data inicial e uma data final
            </span>
          )}
          <div className="dropdown-apply-btn-mobile-div">
            <PrimaryButton
              width={`80%`}
              height={`56px`}
              onClick={() => {
                setIsApplyFilterButtonLoading(true)
                preStatementsRequest(true)
              }}
              disabled={isApplyFilterButtonLoading}
            >
              {isApplyFilterButtonLoading ? (
                <img
                  className="btn-spinner"
                  src={Spinner}
                  alt="loading"
                  onLoad={(e) => SVGInject(e.target)}
                />
              ) : (
                `Aplicar filtros`
              )}
            </PrimaryButton>
          </div>
        </div>
      </>
    )
  }

  function FormattedCurrencySpan(props: any) {
    if (props.tipoLancamento === `C`) {
      return (
        <span className="value-green">
          {`+ ${getFormattedCurrency(props.vlrMovimento)}`}
        </span>
      )
    } else if (props.tipoLancamento === `D` || props.schedule) {
      return (
        <span className="value-red">
          {`- ${getFormattedCurrency(props.vlrMovimento)}`}
        </span>
      )
    } else {
      return <></>
    }
  }

  function MobileMapList(props: any) {
    if (isStatementsLoading) {
      return (
        <div
          style={{
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            marginTop: `50px`,
            marginBottom: `50px`,
          }}
        >
          <SpinnerComponent />
          {/*this is a skeleton schema
          <div className="extrato-mobile-data">
            <div className="extrato-info-mobile-content">
              <span className="grey-box-mobile gradient" />

              <span className="grey-box-mobile gradient" />

              <span className="grey-box-mobile gradient" />
            </div>
          </div>
          */}
        </div>
      )
    }
    switch (props.option) {
      case 1:
        if (statements.days.length) {
          return (
            <>
              {statements.days.map((data, i) => (
                <div key={i}>
                  {data.transactions.map((dados, j) => (
                    <div key={j} className="extrato-info-mobile-content">
                      {dados.descricaoOperacao.includes(`CUSTO`) ? (
                        <>
                          <img
                            src={TRANSACTIONS_ICONS_TITLES[5001]?.icon || ``}
                            alt=""
                            className=""
                            onLoad={(e) => SVGInject(e.target)}
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src={
                              TRANSACTIONS_ICONS_TITLES[dados.origemTransacao]
                                ?.icon || ``
                            }
                            alt=""
                            className=""
                            onLoad={(e) => SVGInject(e.target)}
                          />
                        </>
                      )}
                      <div className="col display-inline-grid">
                        <span className="extrato-info-mobile-row-span-16">
                          {dados.descricaoOperacao.includes(`CUSTO`) ? (
                            <>
                              {dados.descricaoOperacao
                                .substring(0, 1)
                                .toUpperCase() +
                                dados.descricaoOperacao
                                  .substring(1)
                                  .toLowerCase()}
                            </>
                          ) : (
                            <>
                              {TRANSACTIONS_ICONS_TITLES[dados.origemTransacao]
                                ?.title || ``}
                            </>
                          )}
                        </span>
                        <span className="extrato-info-mobile-row-span-14 w-80">
                          {dados.nome && isFullName(dados.nome)
                            ? dados.nome.split(` `)[0] +
                              ` ` +
                              dados.nome.split(` `)[
                                dados.nome.split(` `).length - 1
                              ]
                            : dados.nome}
                        </span>
                        <span className="extrato-info-mobile-row-span-14-red">
                          <FormattedCurrencySpan
                            vlrMovimento={dados.vlrMovimento}
                            tipoLancamento={dados.tipoLancamento}
                          />
                        </span>
                      </div>
                      <span className="extrato-info-mobile-row-span-14">
                        {dayjs(data.date).format(`DD/MM/YYYY`)}
                      </span>
                    </div>
                  ))}
                </div>
              ))}
            </>
          )
        } else {
          return (
            <>
              <h6 className="no-transactions-message">
                {!props.isHome
                  ? `Não foi encontrada nenhuma movimentação no período selecionado.
                Por favor tente outro.`
                  : `Não foi encontrada nenhuma movimentação nos últimos ${DEFAULT_PERIOD_FILTER} dias.`}
              </h6>
            </>
          )
        }
      case 2:
        if (receives.days.length) {
          return (
            <>
              {receives.days.map((data, i) => (
                <div key={i}>
                  {data.transactions.map((dados, j) => (
                    <div key={j} className="extrato-info-mobile-content">
                      <img
                        src={
                          TRANSACTIONS_ICONS_TITLES[dados.origemTransacao]
                            ?.icon || ``
                        }
                        alt=""
                        className=""
                        onLoad={(e) => SVGInject(e.target)}
                      />
                      <div className="col display-inline-grid">
                        <span className="extrato-info-mobile-row-span-16">
                          {TRANSACTIONS_ICONS_TITLES[dados.origemTransacao]
                            ?.title || ``}
                        </span>
                        <span className="extrato-info-mobile-row-span-14 w-80">
                          {dados.nome && isFullName(dados.nome)
                            ? dados.nome.split(` `)[0] +
                              ` ` +
                              dados.nome.split(` `)[
                                dados.nome.split(` `).length - 1
                              ]
                            : dados.nome}
                        </span>
                        <span className="extrato-info-mobile-row-span-14-red">
                          <FormattedCurrencySpan
                            vlrMovimento={dados.vlrMovimento}
                            tipoLancamento={dados.tipoLancamento}
                          />
                        </span>
                      </div>
                      <span className="extrato-info-mobile-row-span-14">
                        {dayjs(data.date).format(`DD/MM/YYYY`)}
                      </span>
                    </div>
                  ))}
                </div>
              ))}
            </>
          )
        } else {
          return (
            <>
              <h6 className="no-transactions-message">
                {!props.isHome
                  ? `Não foi encontrada nenhuma movimentação no período selecionado.
                  Por favor tente outro.`
                  : `Não foi encontrada nenhuma movimentação nos últimos ${DEFAULT_PERIOD_FILTER} dias.`}
              </h6>
            </>
          )
        }

      case 3:
        if (outings.days.length) {
          return (
            <>
              {outings.days.map((data, i) => (
                <div key={i}>
                  {data.transactions.map((dados, j) => (
                    <div key={j} className="extrato-info-mobile-content">
                      {dados.descricaoOperacao.includes(`CUSTO`) ? (
                        <>
                          <img
                            src={TRANSACTIONS_ICONS_TITLES[5001]?.icon || ``}
                            alt=""
                            className=""
                            onLoad={(e) => SVGInject(e.target)}
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src={
                              TRANSACTIONS_ICONS_TITLES[dados.origemTransacao]
                                ?.icon || ``
                            }
                            alt=""
                            className=""
                            onLoad={(e) => SVGInject(e.target)}
                          />
                        </>
                      )}
                      <div className="col display-inline-grid">
                        <span className="extrato-info-mobile-row-span-16">
                          {dados.descricaoOperacao.includes(`CUSTO`) ? (
                            <>
                              {dados.descricaoOperacao
                                .substring(0, 1)
                                .toUpperCase() +
                                dados.descricaoOperacao
                                  .substring(1)
                                  .toLowerCase()}
                            </>
                          ) : (
                            <>
                              {TRANSACTIONS_ICONS_TITLES[dados.origemTransacao]
                                ?.title || ``}
                            </>
                          )}
                        </span>
                        <span className="extrato-info-mobile-row-span-14 w-80">
                          {dados.nome && isFullName(dados.nome)
                            ? dados.nome.split(` `)[0] +
                              ` ` +
                              dados.nome.split(` `)[
                                dados.nome.split(` `).length - 1
                              ]
                            : dados.nome}
                        </span>
                        <span className="extrato-info-mobile-row-span-14-red">
                          <FormattedCurrencySpan
                            vlrMovimento={dados.vlrMovimento}
                            tipoLancamento={dados.tipoLancamento}
                          />
                        </span>
                      </div>
                      <span className="extrato-info-mobile-row-span-14">
                        {dayjs(data.date).format(`DD/MM/YYYY`)}
                      </span>
                    </div>
                  ))}
                </div>
              ))}
            </>
          )
        } else {
          return (
            <>
              <h6 className="no-transactions-message">
                {!props.isHome
                  ? `Não foi encontrada nenhuma movimentação no período selecionado.
                    Por favor tente outro.`
                  : `Não foi encontrada nenhuma movimentação nos últimos ${DEFAULT_PERIOD_FILTER} dias.`}
              </h6>
            </>
          )
        }

      case 5:
        if (outings.days.length) {
          return (
            <>
              {outings.days.map((data, i) => (
                <div key={i}>
                  {data.transactions.map((dados, j) => (
                    <div key={j} className="extrato-info-mobile-content">
                      {dados.descricaoOperacao.includes(`CUSTO`) ? (
                        <>
                          <img
                            src={TRANSACTIONS_ICONS_TITLES[5001]?.icon || ``}
                            alt=""
                            className=""
                            onLoad={(e) => SVGInject(e.target)}
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src={
                              TRANSACTIONS_ICONS_TITLES[dados.origemTransacao]
                                ?.icon || ``
                            }
                            alt=""
                            className=""
                            onLoad={(e) => SVGInject(e.target)}
                          />
                        </>
                      )}
                      <div className="col display-inline-grid">
                        <span className="extrato-info-mobile-row-span-16">
                          {dados.descricaoOperacao.includes(`CUSTO`) ? (
                            <>
                              {dados.descricaoOperacao
                                .substring(0, 1)
                                .toUpperCase() +
                                dados.descricaoOperacao
                                  .substring(1)
                                  .toLowerCase()}
                            </>
                          ) : (
                            <>
                              {TRANSACTIONS_ICONS_TITLES[dados.origemTransacao]
                                ?.title || ``}
                            </>
                          )}
                        </span>
                        <span className="extrato-info-mobile-row-span-14 w-80">
                          {dados.nome && isFullName(dados.nome)
                            ? dados.nome.split(` `)[0] +
                              ` ` +
                              dados.nome.split(` `)[
                                dados.nome.split(` `).length - 1
                              ]
                            : dados.nome}
                        </span>
                        <span className="extrato-info-mobile-row-span-14-red">
                          <FormattedCurrencySpan
                            vlrMovimento={dados.vlrMovimento}
                            tipoLancamento={dados.tipoLancamento}
                          />
                        </span>
                        <div className="col col-flex">
                          <button
                            onClick={() =>
                              handleGetTransactionReceipt(dados.codigo)
                            }
                            disabled={isLoadingReceiptPdf}
                            className="btn-receipts-transaction-mobile"
                            style={{
                              pointerEvents: isLoadingReceiptPdf
                                ? `none`
                                : `all`,
                            }}
                          >
                            <div
                              style={{
                                display: `flex`,
                                alignItems: `center`,
                                gap: `8px`,
                              }}
                            >
                              {isLoadingReceiptPdf ? (
                                <>
                                  <div
                                    className="spinner-receipts"
                                    role="status"
                                  ></div>
                                </>
                              ) : (
                                <>
                                  <img
                                    src={receipts}
                                    alt="2° Via do Comprovante"
                                    onLoad={(e) => SVGInject(e.target)}
                                    className="receipt-icon"
                                  />
                                </>
                              )}
                            </div>
                          </button>
                        </div>
                      </div>
                      <span className="extrato-info-mobile-row-span-14">
                        {dayjs(data.date).format(`DD/MM/YYYY`)}
                      </span>
                    </div>
                  ))}
                </div>
              ))}
            </>
          )
        } else {
          return (
            <>
              <h6 className="no-transactions-message">
                {!props.isHome
                  ? `Não foi encontrada nenhuma movimentação no período selecionado.
                    Por favor tente outro.`
                  : `Não foi encontrada nenhuma movimentação nos últimos ${DEFAULT_PERIOD_FILTER} dias.`}
              </h6>
            </>
          )
        }

      default:
        if (schedules.days.length) {
          return (
            <>
              {schedules.days.map((data, i) => (
                <div key={i}>
                  {data.transactions.map((dados, j) => (
                    <div key={j} className="extrato-info-mobile-content">
                      <img
                        src={SCHEDULES_ICONS_TITLES[dados.type].icon}
                        alt=""
                        className=""
                        onLoad={(e) => SVGInject(e.target)}
                      />
                      <div className="extrato-info-mobile-row-content">
                        <span className="extrato-info-mobile-row-span-14 w-80">
                          {SCHEDULES_ICONS_TITLES[dados.type].title}
                        </span>
                        <span className="extrato-info-mobile-row-span-14-red">
                          <FormattedCurrencySpan
                            vlrMovimento={Number(dados.value)}
                            schedule
                          />
                        </span>
                      </div>
                      <span className="extrato-info-mobile-row-span-14">
                        {dayjs(data.date).isSame(dayjs(), `d`)
                          ? `Hoje, ${dayjs(data.date).format(`DD/MM/YYYY`)}`
                          : dayjs(data.date).format(`DD/MM/YYYY`)}
                      </span>
                      <div
                        className="delete-operation"
                        onClick={() => handleOnClick(dados)}
                      >
                        <img className="delete-img" src={trash} alt="" />
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </>
          )
        } else {
          return (
            <h6 className="no-transactions-message">
              Nenhum pagamento agendado.
            </h6>
          )
        }
    }
  }

  return (
    <>
      {!isHome ? (
        <div className="extrato-container-mobile">
          {openDropdown && <DateOptions />}
          <div className="extrato-header-mobile">
            <button
              className="extrato-header-mobile-btn f-l"
              onClick={() => navigate(`/banking`)}
            >
              <img src={back} alt="" onLoad={(e) => SVGInject(e.target)} />
            </button>
            {optionButton !== 5 && (
              <button
                className="extrato-header-mobile-btn"
                onClick={() => {
                  generateCsv()
                }}
              >
                <CSVLink data={csv} separator={`;`} filename={`extrato.csv`}>
                  <img
                    src={download}
                    alt=""
                    className=""
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </CSVLink>
              </button>
            )}
            <button
              className="extrato-header-mobile-btn"
              onClick={() => {
                setOpenDropdown(true)
              }}
            >
              <img
                src={filter}
                alt=""
                className=""
                onLoad={(e) => SVGInject(e.target)}
              />
            </button>
            <h1 className="extrato-header-h1-mobile"> Extrato </h1>
          </div>
          <div className="extrato-table-content-mobile">
            <div className="extrato-options-btns-mobile-div">
              {optionButton === 1 ? (
                <button className="extrato-option-btn-mobile">Tudo</button>
              ) : (
                <button
                  className="extrato-option-btn-mobile-disable"
                  onClick={() => setOptionButton(1)}
                >
                  Tudo
                </button>
              )}
              {optionButton === 2 ? (
                <button className="extrato-option-btn-mobile">Entradas</button>
              ) : (
                <button
                  className="extrato-option-btn-mobile-disable"
                  onClick={() => setOptionButton(2)}
                >
                  Entradas
                </button>
              )}
              {optionButton === 3 ? (
                <button className="extrato-option-btn-mobile">Saídas</button>
              ) : (
                <button
                  className="extrato-option-btn-mobile-disable"
                  onClick={() => setOptionButton(3)}
                >
                  Saídas
                </button>
              )}
              {optionButton === 4 ? (
                <button className="extrato-option-btn-mobile">
                  Agendamentos
                </button>
              ) : (
                <button
                  className="extrato-option-btn-mobile-disable"
                  onClick={() => {
                    getSchedules()
                    setOptionButton(4)
                  }}
                >
                  Agendamentos
                </button>
              )}
              {optionButton === 5 ? (
                <button className="extrato-option-btn-mobile">
                  2° Via de Comprovantes
                </button>
              ) : (
                <button
                  className="extrato-option-btn-mobile-disable"
                  onClick={() => setOptionButton(5)}
                >
                  2° Via de Comprovantes
                </button>
              )}
            </div>
            <div className="extrato-info-mobile-div">
              <MobileMapList option={optionButton} />
              {statements.next_relative_final_date && optionButton != 4 && (
                <div className="see-more-btn">
                  <PrimaryButton
                    onClick={() => {
                      setIsSeeMoreButtonLoading(true)
                      preStatementsRequest(false)
                    }}
                    disabled={isSeeMoreButtonLoading}
                    width={`173px`}
                    height={`56px`}
                  >
                    {isSeeMoreButtonLoading ? (
                      <div className="navbar-spinner"></div>
                    ) : (
                      `Ver mais`
                    )}
                  </PrimaryButton>
                </div>
              )}
            </div>
            <CustomModal
              isOpen={!!modalAlert}
              onClose={() => setModalAlert(``)}
              isBanking
            >
              <div className="px-5 py-3">{modalAlert}</div>
            </CustomModal>
            <SuccessModal
              isOpen={modalDelete}
              onClose={() => setModalDelete(``)}
              width={``}
              height={``}
            >
              <div className="px-5 py-3 extrato-modal-success">
                {modalDelete}
              </div>
            </SuccessModal>
          </div>
        </div>
      ) : (
        <>
          <MobileMapList option={1} />
        </>
      )}
    </>
  )
}

export default ExtratoMobile
