import { useState, useContext, useEffect } from "react"
import "./styles.scss"
import { HelpPanel } from "./components/HelpPanel"
import Header from "../../../features/Header"
import CustomModal from "../../../features/CustomModal"
import DigitalSignature from "../../DigitalSignature/Form"
import key from "../../../../assets/images/key.svg"
import pix from "../../../../assets/images/navbar-banking/pix.svg"
import transfer from "../../../../assets/images/navbar-banking/transfer.svg"
import barcode from "../../../../assets/images/navbar-banking/barcode.svg"
import SVGInject from "@iconfu/svg-inject"
import EditApprovalModal from "./components/EditApprovalModal/EditApprovalModal"
import DeleteApprovalModal from "./components/DeleteApprovalModal/DeleteApprovalModal"
import CreateApprovalModal from "./components/CreateApprovalModal/CreateApprovalModal"
import services from "../../../../services/svcmesh"
import { AuthContext } from "../../../../context/AuthProvider"
import { formattedCurrency } from "../../../../services/banking"
import { price_to_number } from "../../../../utils/validation"
import { TransactionsType } from "k4n-svcmesh-sdk/dist/typings/approvals-config.dto"
import { PaymentCard } from "./components/PaymentCard/PaymentCard"
import ErrorModal from "../../../features/ErrorModal"
import { AxiosError } from "axios"

const paymentCards = [
  {
    id: 1,
    title: `Transferência Entre Contas`,
    transactionType: `TRANSFERENCIA_ENTRE_CONTAS`,
    icon: transfer,
  },
  {
    id: 2,
    title: `Transferência Outros Bancos`,
    transactionType: `TRANSFERENCIA_OUTRO_BANCO`,
    icon: transfer,
  },
  {
    id: 3,
    title: `Pagamentos Pix`,
    transactionType: `PIX`,
    icon: pix,
  },
  {
    id: 4,
    title: `Pagamentos de Boleto`,
    transactionType: `BOLETO`,
    icon: barcode,
  },
]

interface approvalConfigItem {
  id: number
  ownewrAccount: object
  name: string
  numberOfApprovals: number
  amountGreaterThanOrEqual: number
  transactionType: string
  createdAt: string
  modifiedAt: string
}

export const ApprovalsManagement = () => {
  const [openCards, setOpenCards] = useState<number[]>([])
  const [editApprovalModalState, setEditApprovalModalState] = useState<{
    isOpen: boolean
    approvalConfigItem: approvalConfigItem | null
  }>({
    isOpen: false,
    approvalConfigItem: null,
  })
  const [deleteApprovalModalState, setDeleteApprovalModalState] = useState<{
    isOpen: boolean
    approvalConfigItem: approvalConfigItem | null
  }>({
    isOpen: false,
    approvalConfigItem: null,
  })
  const [openModalCreateApproval, setOpenModalCreateApproval] = useState(false)
  const [transactionTypeCreate, setTransactionTypeCreate] = useState(``)
  const [configurations, setConfigurations] = useState({})
  const [authorizeModal, setAuthorizeModal] = useState(false)
  const [pendingAction, setPendingAction] = useState<(() => void) | null>(null)
  const [operatorLogged, setOperatorLogged] = useState({})
  const [isOpMaster, setIsOpMaster] = useState(false)
  const { user } = useContext(AuthContext)
  const [isOpenModalException, setModalException] = useState(false)
  const [modalErrorMessage, setModalErrorMessage] = useState(``)

  useEffect(() => {
    const fetchOperator = async () => {
      const op = (
        await services.bankingService.getOperatorByOidcId(user?.oidc_id)
      ).data as any

      setOperatorLogged(op)

      if (op?.role === `OPERATOR_PJ_MASTER`) {
        setIsOpMaster(true)
      }
    }

    fetchOperator()
  }, [user])

  const fetchConfigurations = async () => {
    try {
      const ownerAccountId = user?.accounts[0]?.id
      if (!ownerAccountId) {
        console.error(`Owner Account ID não encontrado!`)
        return
      }

      const response = await services.bankingService.getApprovalsConfig({
        ownerAccountId: ownerAccountId,
      })
      const allConfigurations = response
      const newConfigurations = paymentCards.reduce((acc, card) => {
        acc[card.id] = allConfigurations.filter(
          (config) => config.transactionType === card.transactionType,
        )
        return acc
      }, {})

      setConfigurations(newConfigurations)
    } catch (error) {
      console.error(`Erro ao buscar approvals config:`, error)
    }
  }

  useEffect(() => {
    fetchConfigurations()
  }, [user])

  const toggleCard = (cardId) => {
    setOpenCards((prevOpenCards) =>
      prevOpenCards.includes(cardId)
        ? prevOpenCards.filter((id) => id !== cardId)
        : [...prevOpenCards, cardId],
    )
  }

  const toggleOpenModalEditApproval = (
    approvalConfig = null,
    cardId: number | null = null,
    transactionType: string | null = null,
  ) => {
    setEditApprovalModalState((prev) => ({
      isOpen: !prev.isOpen,
      approvalConfigItem: approvalConfig || null,
    }))
  }

  const toggledeleteApprovalModalState = (
    approvalConfig = null,
    cardId: number | null = null,
    transactionType: string | null = null,
  ) => {
    setDeleteApprovalModalState((prev) => ({
      isOpen: !prev.isOpen,
      approvalConfigItem: approvalConfig || null,
    }))
  }

  const toggleOpenModalCreateApproval = (
    cardId: number | null = null,
    transactionType: string | null = null,
  ) => {
    setTransactionTypeCreate(transactionType || ``)
    setOpenModalCreateApproval((prev) => !prev)
  }

  const onSubmitEditApproval = (data) => {
    setPendingAction(() => () => submitEditApproval(data))
    setAuthorizeModal(true)
  }

  const submitEditApproval = async (data) => {
    try {
      if (data.amountGreaterThanOrEqual) {
        data.amountGreaterThanOrEqual = price_to_number(
          data.amountGreaterThanOrEqual,
        )
      }
      if (editApprovalModalState.approvalConfigItem?.id !== undefined) {
        await services.bankingService.updateApprovalsConfig(
          editApprovalModalState.approvalConfigItem.id,
          data,
        )
        setEditApprovalModalState((prevState) => ({
          ...prevState,
          approvalConfigItem: null,
        }))
        toggleOpenModalEditApproval()
        fetchConfigurations()
      }
    } catch (error) {
      const err = error as AxiosError<{ message: string }>
      setModalErrorMessage(err.response?.data?.message || ``)
      setModalException(true)
    }
  }

  const onSubmitDeleteApproval = (data) => {
    setPendingAction(() => () => submitDeleteApproval(data))
    setAuthorizeModal(true)
  }

  const submitDeleteApproval = async (data) => {
    try {
      if (data.amountGreaterThanOrEqual) {
        data.amountGreaterThanOrEqual = price_to_number(
          data.amountGreaterThanOrEqual,
        )
      }
      data.status = 0

      if (deleteApprovalModalState.approvalConfigItem?.id !== undefined) {
        await services.bankingService.updateApprovalsConfig(
          deleteApprovalModalState.approvalConfigItem.id,
          data,
        )
        setDeleteApprovalModalState((prevState) => ({
          ...prevState,
          approvalConfigItem: null,
        }))
        toggledeleteApprovalModalState()
        fetchConfigurations()
      }
    } catch (error) {
      console.error(`Erro ao excluir a configuração:`, error)
    }
  }

  const onSubmitCreateApproval = (data) => {
    setPendingAction(() => () => submitCreateApproval(data))
    setAuthorizeModal(true)
  }

  const submitCreateApproval = async (data) => {
    try {
      if (data.amountGreaterThanOrEqual) {
        data.amountGreaterThanOrEqual = price_to_number(
          data.amountGreaterThanOrEqual,
        )
      }

      if (data.numberOfApprovals) {
        data.numberOfApprovals = parseInt(data.numberOfApprovals, 10)
      }

      const ownerAccountId = user?.accounts[0]?.id
      if (!ownerAccountId) {
        console.error(`OwnerAccountId não encontrado!`)
        return
      }
      data.ownerAccountId = ownerAccountId
      data.status = 1

      if (!transactionTypeCreate) {
        console.error(`transactionType não fornecido!`)
        return
      }

      data.transactionType = transactionTypeCreate as TransactionsType

      await services.bankingService.createApprovalsConfig(data)
      toggleOpenModalCreateApproval()
      fetchConfigurations()
    } catch (error) {
      const err = error as AxiosError<{ message: string }>
      setModalErrorMessage(err.response?.data?.message || ``)
      setModalException(true)
    }
  }

  const authorize = () => {
    setAuthorizeModal(false)
    if (pendingAction) {
      pendingAction()
      setPendingAction(null)
    }
  }

  return (
    <>
      <Header />
      <div className="approval-container">
        <div className="approval-first-container">
          <div className="approval-management">
            <div className="approval-management-header">
              <h1>Gestão de Aprovações</h1>
            </div>
            <p>
              Selecione uma transação e configure os requisitos para aprovação.
              Você pode escolher a partir de qual valor será necessário uma
              aprovação e quantos operadores terão que aprovar a transação para
              que seja efetivada.
            </p>

            <div className="group">
              <h2 className="group-title">Grupo: {user?.accounts[0]?.nome}</h2>
              <div>
                {paymentCards.map((card) => (
                  <PaymentCard
                    key={card.id}
                    card={card}
                    isOpen={openCards.includes(card.id)}
                    configurations={configurations[card.id]}
                    onToggle={toggleCard}
                    onEdit={toggleOpenModalEditApproval}
                    onDelete={toggledeleteApprovalModalState}
                    onCreate={toggleOpenModalCreateApproval}
                    isOpMaster={isOpMaster}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <HelpPanel />
        <EditApprovalModal
          isOpen={editApprovalModalState.isOpen}
          onClose={() => toggleOpenModalEditApproval()}
          onSubmit={onSubmitEditApproval}
          defaultEditApprovalValue={
            formattedCurrency(
              editApprovalModalState.approvalConfigItem
                ?.amountGreaterThanOrEqual || 0,
            ) || `0.00`
          }
          defaultEditApprovalQuantity={
            editApprovalModalState.approvalConfigItem?.numberOfApprovals || 1
          }
        />
        <DeleteApprovalModal
          isOpen={deleteApprovalModalState.isOpen}
          onClose={toggledeleteApprovalModalState}
          onSubmit={onSubmitDeleteApproval}
        />
        <CreateApprovalModal
          isOpen={openModalCreateApproval}
          onClose={toggleOpenModalCreateApproval}
          onSubmit={onSubmitCreateApproval}
        />
        <CustomModal
          isOpen={authorizeModal}
          onClose={() => setAuthorizeModal(false)}
          isBanking={true}
        >
          <div style={{ width: `120px`, margin: `0 auto` }}>
            <img
              src={key}
              alt="/"
              width="120"
              height="120"
              className="key-vector"
              onLoad={(e) => SVGInject(e.target)}
            />
          </div>
          <DigitalSignature handleSuccess={authorize} />
        </CustomModal>
        <ErrorModal
          isOpen={isOpenModalException}
          width={`500px`}
          height={`auto`}
          onClose={() => setModalException(false)}
        >
          <h1 className="pix-modal-sucess-h1">
            {modalErrorMessage !== ``
              ? modalErrorMessage
              : `Ocorreu um erro, tente novamente mais tarde.`}
          </h1>
        </ErrorModal>
      </div>
    </>
  )
}
