import { ReactNode } from "react"
import "./styles.scss"
import Vector from "../../../assets/images/success_vector.svg"
import SVGInject from "@iconfu/svg-inject"
import CloseIcon from "../../../assets/images/close.svg"

interface ModalProps {
  isOpen: boolean
  width: string
  height: string
  onClose: () => void
  children?: ReactNode
  paddingTop?: string
}

const SuccessModal = ({
  isOpen,
  onClose,
  width,
  height,
  children,
  paddingTop,
}: ModalProps) => {
  return isOpen ? (
    <div className="modal-wrapper">
      <div className="modal-overlay" onClick={onClose}></div>
      <div
        style={{ width: width, height: height, paddingTop: paddingTop }}
        className="modal-container-success"
      >
        <div className="flex-end">
          <img
            src={CloseIcon}
            alt="Fechar modal"
            width={22}
            height={22}
            className="modal-close-button-sucess-modal"
            onClick={onClose}
          />
        </div>
        <div className="flex-center">
          <div className="success-vector">
            <img src={Vector} onLoad={(e) => SVGInject(e.target)} />
          </div>
        </div>
        {children}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default SuccessModal
