import { useContext, useState } from "react"
import { AuthContext } from "../../../context/AuthProvider"
import { Link } from "react-router-dom"
import "./styles.scss"
import { FieldValues, useForm } from "react-hook-form"
import {
  formatCnpj,
  formatCpf,
  formatPhone,
  getDigitsOnly,
  validateEmail,
  validatePhone,
} from "../../../utils/validation"
import ReactInputMask from "react-input-mask"
import { CustomModal } from "k4n-components"
import Header from "../../features/Header"
import services from "../../../services/svcmesh"

const AccountManagement = () => {
  const {
    user,
    refreshUser,
    openResetPassPage,
    emailVerified,
    hasDigitalSignature,
  } = useContext(AuthContext)

  // const [emailModal, setEmailModal] = useState(false)
  const [phoneModal, setPhoneModal] = useState(false)
  const [isloading, setIsloading] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    getValues,
  } = useForm({
    defaultValues: {
      email:
        user.emails && user?.emails?.length > 0 ? user?.emails[0].email : ``,
      phone:
        user.phones && user?.phones?.length > 0
          ? formatPhone(user?.phones[0]?.phone)
          : ``,
    },
  })
  const handleUpdateFields = async (data: FieldValues) => {
    try {
      await services.bankingService.checkIfEmailIsAvailable(data.email)
    } catch (error) {
      setEmailError(true)
    }

    if (user && user.oidc_id) {
      try {
        setIsloading(true)
        data.phone = getDigitsOnly(data.phone)
        const updateUserMethod =
          user.accounts[0].tipoPessoa === 2
            ? services.authService.updateUserAccountOperador
            : services.authService.updateUserAccount

        const res = await updateUserMethod(user.oidc_id, data)

        if (res.data) setIsloading(false)
        user.emails[0].email = data.email

        // setEmailModal(res.data.email)
        setPhoneModal(res.data.phone)
      } catch (error) {
        console.log(error)
        setIsloading(false)
      }
    }
  }

  const handleValidatePhone = async () => {
    try {
      let code = (
        document.getElementById(`phone-code-input`) as HTMLInputElement
      )?.value
      code = getDigitsOnly(code)

      const { phone } = getValues()

      const validatePhoneMethod =
        user.accounts[0].tipoPessoa === 2
          ? services.bankingService.validatePhoneOperador
          : services.bankingService.validatePhone

      if (user?.id)
        await validatePhoneMethod(
          user?.id,
          getDigitsOnly(user.phones[0].phone),
          code,
        )

      const changePhoneMethod =
        user.accounts[0].tipoPessoa === 2
          ? services.bankingService.changePhoneOperador
          : services.bankingService.changePhone
      await changePhoneMethod(getDigitsOnly(phone), user!.cpf)
      setPhoneModal(false)
      refreshUser()
    } catch (error) {
      console.log(error)
      window.alert(error)
    }
  }

  return (
    <>
      <Header />
      <div className="ac-mang-bg-gray">
        <div className="ac-mang-manage-container">
          <h1 className="ac-mang-manage-title">Gerenciar Conta</h1>

          <div className="ac-mang-card">
            <h2 className="ac-mang-section-title">Informações da Conta</h2>
            <div className="ac-mang-account-info">
              <div className="ac-mang-info-group">
                <label className="ac-mang-label-text">Número da Conta:</label>
                <span className="ac-mang-input-text">
                  {user?.accounts[0]?.conta}-{user?.accounts[0]?.contaDigito}
                </span>
              </div>
              <div className="ac-mang-info-group">
                <label className="ac-mang-label-text">Agência:</label>
                <span className="ac-mang-input-text">
                  {user?.accounts[0]?.agencia}
                </span>
              </div>
              <div className="ac-mang-info-group">
                <label className="ac-mang-label-text">Banco:</label>
                <span className="ac-mang-input-text">
                  {user?.bank?.number} - {user?.bank?.name}
                </span>
              </div>
            </div>
          </div>

          {user?.accounts[0]?.tipoPessoa === 2 && (
            <div className="ac-mang-card">
              <h2 className="ac-mang-section-title">Dados da Empresa</h2>
              <div className="ac-mang-info-group">
                <label className="ac-mang-label-text">Nome Social</label>
                <input
                  className="ac-mang-input-text"
                  type="text"
                  value={user?.accounts[0]?.nome}
                  disabled
                />
              </div>
              <div className="ac-mang-info-group">
                <label className="ac-mang-label-text">CNPJ</label>
                <input
                  className="ac-mang-input-text"
                  type="text"
                  value={formatCnpj(user?.cnpj)}
                  disabled
                />
              </div>
            </div>
          )}

          <div className="ac-mang-card">
            <h2 className="ac-mang-section-title">
              {user?.accounts[0]?.tipoPessoa === 2
                ? `Dados do Operador`
                : `Dados Pessoais`}
            </h2>
            <div className="ac-mang-info-group">
              <label className="ac-mang-label-text">Nome Completo</label>
              <input
                className="ac-mang-input-text"
                type="text"
                value={user?.nome}
                disabled
              />
            </div>
            <div className="ac-mang-info-group">
              <label className="ac-mang-label-text">CPF</label>
              <input
                className="ac-mang-input-text"
                type="text"
                value={formatCpf(user?.cpf)}
                disabled
              />
            </div>
          </div>

          {/* Alteração de Senha */}
          <div className="ac-mang-card">
            <h2 className="ac-mang-section-title">Senha</h2>
            <span className="ac-mang-yellow-link" onClick={openResetPassPage}>
              Alterar senha
            </span>
          </div>

          {/* Alteração de Endereço */}
          <div className="ac-mang-card">
            <h2 className="ac-mang-section-title">Endereço</h2>
            <Link to="/change-address" className="ac-mang-yellow-link">
              Alterar Endereço
            </Link>
          </div>

          {/* Assinatura Digital */}
          <div className="ac-mang-card">
            <h2 className="ac-mang-section-title">Assinatura Digital</h2>
            <Link to="/digital-signature" className="ac-mang-yellow-link">
              {hasDigitalSignature
                ? `Alterar assinatura digital`
                : `Criar assinatura digital`}
            </Link>
          </div>

          {/* Gestão de Operadores e Aprovações */}
          {user?.accounts[0]?.tipoPessoa === 2 && (
            <>
              <div className="ac-mang-card">
                <h2 className="ac-mang-section-title">Gerenciar Operadores</h2>
                <Link
                  to="/operators-management"
                  className="ac-mang-yellow-link"
                >
                  Gerenciar Operadores
                </Link>
              </div>

              <div className="ac-mang-card">
                <h2 className="ac-mang-section-title">Gestão de Aprovações</h2>
                <Link
                  to="/approvals-management"
                  className="ac-mang-yellow-link"
                >
                  Gerenciar Aprovações
                </Link>
              </div>
            </>
          )}

          {/* Formulário de Contato */}
          <form onSubmit={handleSubmit(handleUpdateFields)}>
            <div className="ac-mang-card">
              <h2 className="ac-mang-section-title">Dados de Contato</h2>

              <div className="ac-mang-info-group">
                <label>E-mail</label>
                <input
                  {...register(`email`, {
                    required: true,
                    validate: validateEmail,
                  })}
                  className={
                    errors?.email ? `k4n-input input-invalid` : `k4n-input`
                  }
                  type="email"
                  onChange={() => setEmailError(false)}
                />
                {errors.email && (
                  <div className="ac-mang-input-error">Email inválido</div>
                )}
                {emailError && user?.emails[0].email !== getValues(`email`) && (
                  <div className="ac-mang-input-error">
                    Este email já está sendo usado
                  </div>
                )}
              </div>

              <div className="ac-mang-info-group">
                <label>Telefone</label>
                <ReactInputMask
                  mask="(99) 99999-9999"
                  defaultValue={
                    user.phones &&
                    user?.phones?.length > 0 &&
                    formatPhone(user?.phones[0].phone)
                  }
                  {...register(`phone`, {
                    pattern: /^\((\d{2})\)\s\d{5}-\d{4}$/,
                    required: true,
                    validate: validatePhone,
                  })}
                  className={
                    errors?.phone ? `k4n-input input-invalid` : `k4n-input`
                  }
                />
                {errors.phone && (
                  <div className="ac-mang-input-error">
                    Número de celular inválido
                  </div>
                )}
              </div>
            </div>

            {/* Botões de ação */}
            <div className="ac-mang-button-group">
              <Link to="/banking" className="ac-mang-button-secondary">
                Cancelar
              </Link>
              <button className="ac-mang-button-primary" type="submit">
                {isloading ? (
                  <div className="ac-mang-save-spinner" />
                ) : (
                  `Salvar`
                )}
              </button>
            </div>
          </form>
        </div>
        <CustomModal
          // isOpen={emailModal}
          onClose={() => {
            // setEmailModal(false)
            if (!phoneModal) {
              refreshUser()
            }
          }}
          isBanking={true}
        >
          <div>
            Seu email precisa ser verificado. Te enviamos um email de
            verificação
          </div>
          <div className="flex-center mt-3">
            <button
              onClick={() => {
                // setEmailModal(false)
                if (!phoneModal) {
                  refreshUser()
                }
              }}
              className="ac-mang-button-primary"
            >
              Ok
            </button>
          </div>
        </CustomModal>

        <CustomModal
          isOpen={/* !emailModal && */ phoneModal}
          onClose={() => {
            setPhoneModal(false)
            refreshUser()
          }}
          isBanking={true}
        >
          <div className="text-center">
            <div>
              Você precisa confirmar que esse número é seu. <br /> Digite abaixo
              o código que te enviamos por SMS
            </div>
            <div className="my-3">
              <ReactInputMask
                id="phone-code-input"
                mask="9 9 9 9 9"
                className="k4n-input"
                style={{ width: `10rem`, textAlign: `center` }}
                alwaysShowMask
              />
            </div>
            <button
              onClick={() => handleValidatePhone()}
              className="ac-mang-button-primary"
            >
              Verificar
            </button>
          </div>
        </CustomModal>
      </div>
    </>
  )
}

export default AccountManagement
