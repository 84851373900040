/* eslint-disable */
import { PaginationV2 } from "../../../../features/PaginationV2"
import { TransactionsTable } from "../components/TransactionsTable"
import { Search } from "lucide-react"
import { useResposive } from "../../../../../hooks/useResponsive"
import { Transaction } from ".."
import Spinner from "../../../../features/Spinner"

interface IApprovalsPanelProps {
  tabs: { id: string; label: string }[]
  activeTab: string
  setActiveTab: (tab: string) => void
  search: string
  setSearch: (search: string) => void
  selectedCount: number
  setSelectedCount: (count: number) => void
  selectAll: boolean
  currentPage: number
  resultsPerPage: number
  Transactions: Transaction[]
  handlePageChange: (page: number) => void
  handleResultsPerPageChange: (value: number) => void
  handleSelectAll: () => void
  navigate: (path: string) => void
  isFetching: boolean
  totalAmount: number
  totalPages: number
  totalResults: number
  setSelectedRows: any
  selectedRows: any
  handleApprove: () => void
  handleReject: () => void
  isApproving: boolean
  isRejecting: boolean
  setModalErrorMessage: (message: string) => void
  setModalException: (exception: boolean) => void
}

const ApprovalsPanel = ({
  tabs,
  activeTab,
  setActiveTab,
  search,
  setSearch,
  selectedCount,
  setSelectedCount,
  selectAll,
  currentPage,
  resultsPerPage,
  Transactions,
  handlePageChange,
  handleResultsPerPageChange,
  handleSelectAll,
  navigate,
  isFetching,
  totalAmount,
  totalResults,
  totalPages,
  setSelectedRows,
  selectedRows,
  handleApprove,
  handleReject,
  isApproving,
  isRejecting,
  setModalErrorMessage,
  setModalException,
}: IApprovalsPanelProps) => {
  const { isTabletOrMobile } = useResposive()

  return (
    <>
      {isTabletOrMobile ? null : (
        <div className="approvals-header-container">
          <div className="approvals-header">
            <h1 className="approvals-title">Aprovações</h1>
          </div>
          <div className="ap-header-btn-container">
            <button
              onClick={() => navigate(`/approvals-management`)}
              className="ap-header-btn button-primary"
            >
              Gestão de aprovações
            </button>
          </div>
        </div>
      )}
      <div className="approvals-container">
        <div className="approvals-content">
          <div className="tabs">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={`tab-item ${activeTab === tab.id ? `active` : ``}`}
                onClick={() => setActiveTab(tab.id)}
              >
                {tab.label}
              </button>
            ))}
          </div>

          <p className="description">
            Confira as transações pendentes da sua aprovação e selecione para
            aprovar ou reprovar.
          </p>

          <div className="filters">
            <div className="search-container">
              <Search className="search-icon" />
              <input
                type="text"
                placeholder="Buscar"
                className="search-input"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            {/* <div className="filters-right">
              <button className="filter-button button-primary">Filtrar</button>
              <div className="date-filter">
                Solicitação: 06/01/2025 a 05/02/2025
              </div>
              <button className="accounts-button">Todas as contas</button>
            </div> */}
          </div>

          <div className="summary">
            <div className="summary-left">
              {activeTab === `completed` ||
              activeTab === `inProgress` ? null : (
                <button
                  onClick={handleSelectAll}
                  className="select-all button-primary"
                >
                  {selectAll ? `Desmarcar todos` : `Selecionar todos`}
                </button>
              )}
              <div className="totals">
                {activeTab === `completed` ||
                activeTab === `inProgress` ? null : (
                  <div className="total-item">
                    <span className="label">Selecionados</span>
                    <span className="value">
                      {selectedCount} de {Transactions?.length}
                    </span>
                  </div>
                )}
                <div className="total-item">
                  <span className="label">Total</span>
                  <span className="value">
                    R$ {totalAmount.toFixed(2).replace(`.`, `,`)}
                  </span>
                </div>
                {/* <div className="total-item">
                  <span className="label">Total EUA</span>
                  <span className="value">US$ 0,00</span>
                </div> */}
              </div>
            </div>
            {activeTab === `completed` || activeTab === `inProgress` ? null : (
              <div className="action-buttons">
                <button
                  className="btn-reject"
                  disabled={
                    activeTab === `completed` ||
                    selectedCount === 0 ||
                    isRejecting
                  }
                  onClick={handleReject}
                >
                  {isRejecting ? <Spinner /> : `Reprovar`}
                </button>
                <button
                  disabled={
                    activeTab === `completed` ||
                    selectedCount === 0 ||
                    isApproving
                  }
                  className="btn-approve button-primary"
                  onClick={handleApprove}
                >
                  {isApproving ? <Spinner /> : `Aprovar`}
                </button>
              </div>
            )}
          </div>
          <div className="transactions-table-container">
            <TransactionsTable
              onSelectionChange={setSelectedCount}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              searchTerm={search}
              selectAll={selectAll}
              transactions={Transactions}
              isFetching={isFetching}
              activeTab={activeTab}
              setModalErrorMessage={setModalErrorMessage}
              setModalException={setModalException}
            />
          </div>
          <PaginationV2
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalResults={totalResults}
            resultsPerPage={resultsPerPage}
            onResultsPerPageChange={handleResultsPerPageChange}
          />
        </div>
      </div>
    </>
  )
}

export default ApprovalsPanel
