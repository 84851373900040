import { useContext } from "react"
import { useForm } from "react-hook-form"
import InputMask from "react-input-mask"
import { AuthContext } from "../../../../../../../context/AuthProvider"
import { BankingNavigationContext } from "../../../../../../../context/BankingNavigationContext"
import { formatCpf, validateCpf } from "../../../../../../../utils/validation"
import { SecondaryButton, PrimaryButton } from "k4n-components"
import "./styles.scss"
import { BmpTipoConta } from "k4n-svcmesh-sdk"
import { useNavigate } from "react-router-dom"

const NewContactTransferStep1Mobile = ({
  onSubmit,
  setSameAccountHolder,
  sameAccountHolder,
  setIsCorporateAccount,
  isCorporateAccount,
  banks,
  loadingBanks,
  bankAgency,
  setBankAgency,
  accountNumber,
  setAccountNumber,
}) => {
  const { changeNewContactTransferStep, changeTransferStep } = useContext(
    BankingNavigationContext,
  )
  const { user } = useContext(AuthContext)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const navigate = useNavigate()

  return (
    <div className="transfer-mobile-container">
      <div className="transfer-mobile-new-contact-panel">
        <button
          className="transfer-mobile-close-btn"
          onClick={() => {
            navigate(`/banking`)
          }}
        ></button>
        <div className="transfer-mobile-new-contact-title">
          <h1 className="title-mobile-blue">Transferir para</h1>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="transfer-mobile-new-contact-form-div"
        >
          {!sameAccountHolder && (
            <div className="checkbox-transfer-corporate-account-div">
              <input
                onClick={() => setIsCorporateAccount(!isCorporateAccount)}
                className="form-check-input checkbox-transfer-corporate-account"
                type="checkbox"
              />
              <span className="checkbox-transfer-corporate-account-span">
                Conta Empresarial
              </span>
            </div>
          )}
          {!isCorporateAccount && (
            <div className="checkbox-mobile-transfer-new-contact-div">
              <input
                onClick={() => setSameAccountHolder(!sameAccountHolder)}
                className="form-check-input transfer-mobile-new-contact-holder-checkbox"
                type="checkbox"
              />
              <span className="checkbox-mobile-transfer-new-contact-span">
                Mesmo titular da conta
              </span>
            </div>
          )}
          {sameAccountHolder && !isCorporateAccount && (
            <div className="transfer-mobile-new-contact-holder-info">
              <h1 className="transfer-mobile-new-contact-info-name">
                {user?.nome}
              </h1>
              <p className="transfer-mobile-new-contact-info-cpf">
                {formatCpf(user?.cpf || ``)}
              </p>
            </div>
          )}
          <div className="transfer-mobile-new-contact-box-div">
            <div className="transfer-mobile-new-contact-form-field bank-name">
              <span className="transfer-mobile-new-contact-form-span">
                Nome do banco
              </span>
              <input
                list="banks"
                type="text"
                className="select-mobile-transfer-new-contact"
                {...register(`bank_number`, {
                  required: true,
                  validate: (data: string) => {
                    return banks.banks.some(
                      (b) => b.number === data.split(` - `)[0],
                    )
                  },
                })}
              />
              <datalist id="banks">
                {!loadingBanks &&
                  banks.banks.map((b, i) => (
                    <option key={i} value={`${b.number} - ${b.name}`} />
                  ))}
              </datalist>
              {errors.bank_number && (
                <p className="new-contact-mobile-transfer-error-msg">
                  Verifique o banco informado.
                </p>
              )}
            </div>
          </div>
          <div className="transfer-mobile-new-contact-box-div">
            <div className="transfer-mobile-new-contact-form-field account-type">
              <span className="transfer-mobile-new-contact-form-span">
                Selecione o tipo de Conta
              </span>
              <select
                className="select-mobile-transfer-new-contact"
                {...register(`account_type`, {
                  required: true,
                })}
              >
                <option value={BmpTipoConta.Corrente}>Conta corrente</option>
                <option value={BmpTipoConta.Poupanca}>Conta poupança</option>
                <option value={BmpTipoConta.Pagamento}>Conta pagamento</option>
                <option value={BmpTipoConta.Salario}>Conta salário</option>
              </select>
              {errors.account_type && (
                <p className="new-contact-mobile-transfer-error-msg">
                  Verifique o tipo de conta informado.
                </p>
              )}
            </div>
            <div className="transfer-mobile-new-contact-form-field agency">
              <span className="transfer-mobile-new-contact-form-span">
                Agência
              </span>
              <input
                value={bankAgency}
                type="text"
                className="transfer-mobile-new-contact-form-input"
                placeholder="0000"
                {...register(`bank_agency`, {
                  required: true,
                  onChange: (e) => {
                    setBankAgency(e.currentTarget.value.replace(/[^0-9]/g, ``))
                  },
                })}
              />
              {errors.bank_agency && (
                <p className="new-contact-mobile-transfer-error-msg">
                  Verifique a agência informada.
                </p>
              )}
            </div>
            <div className="transfer-mobile-conta-digito-wrapper">
              <div className="transfer-mobile-new-contact-form-field-conta account">
                <span className="transfer-mobile-new-contact-form-span">
                  Conta
                </span>
                <input
                  value={accountNumber}
                  type="text"
                  className="transfer-mobile-new-contact-form-input"
                  placeholder="0000000"
                  {...register(`account_number`, {
                    required: true,
                    onChange: (e) =>
                      setAccountNumber(
                        e.currentTarget.value.replace(/[^0-9]/g, ``),
                      ),
                  })}
                />
                {errors.account_number && (
                  <p className="new-contact-mobile-transfer-error-msg">
                    Verifique o número da conta informado.
                  </p>
                )}
              </div>
              <div className="transfer-mobile-new-contact-form-field-digit account-digit">
                <span className="transfer-mobile-new-contact-form-span">
                  Digito
                </span>
                <input
                  type="text"
                  className="transfer-mobile-new-contact-form-input"
                  placeholder="0"
                  {...register(`account_digit`, {
                    required: true,
                  })}
                />
                {errors.account_digit && (
                  <p className="new-contact-mobile-transfer-error-msg">
                    Verifique o dígito da conta informado.
                  </p>
                )}
              </div>
            </div>
          </div>
          {!sameAccountHolder && (
            <div className="transfer-mobile-new-contact-box-div">
              <div className="transfer-mobile-new-contact-form-field">
                <span className="transfer-mobile-new-contact-form-span">
                  Nome
                </span>
                <input
                  type="text"
                  className="transfer-mobile-new-contact-form-input"
                  placeholder="Digite aqui"
                  {...register(`holder_name`, {
                    required: true,
                  })}
                />
                {errors.holder_name && (
                  <p className="new-contact-mobile-transfer-error-msg">
                    Verifique o nome informado.
                  </p>
                )}
              </div>
              {!isCorporateAccount && (
                <div className="transfer-mobile-new-contact-form-field">
                  <span className="transfer-mobile-new-contact-form-span">
                    CPF do titular
                  </span>
                  <InputMask
                    mask="999.999.999-99"
                    type="text"
                    className="transfer-mobile-new-contact-form-input"
                    placeholder="000.000.000-00"
                    {...register(`holder_cpf`, {
                      required: true,
                      pattern: /^\d\d\d\.\d\d\d\.\d\d\d-\d\d$/,
                      validate: validateCpf,
                    })}
                  />
                  {errors.holder_cpf && (
                    <p className="new-contact-mobile-transfer-error-msg">
                      Verifique o cpf informado.
                    </p>
                  )}
                </div>
              )}
              {isCorporateAccount && (
                <div className="transfer-mobile-new-contact-form-field">
                  <span className="transfer-mobile-new-contact-form-span">
                    CNPJ da empresa
                  </span>
                  <InputMask
                    mask="99.999.999/9999-99"
                    type="text"
                    className="transfer-mobile-new-contact-form-input"
                    placeholder="00.000.000/0000-00"
                    {...register(`holder_cnpj`, {
                      required: true,
                      pattern: /^\d\d\.\d\d\d\.\d\d\d\/\d\d\d\d-\d\d$/,
                    })}
                  />
                  {errors.holder_cnpj && (
                    <p className="new-contact-mobile-transfer-error-msg">
                      Verifique o CNPJ informado.
                    </p>
                  )}
                </div>
              )}
            </div>
          )}
          <div className="transfer-mobile-new-contact-btns-div">
            <SecondaryButton
              width={`200px`}
              height={`60px`}
              text={`Voltar`}
              OnClick={() => {
                changeTransferStep(2)
                changeNewContactTransferStep(0)
              }}
            />
            <PrimaryButton width={`200px`} height={`60px`}>
              Próximo
            </PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  )
}

export default NewContactTransferStep1Mobile
