import React from "react"
import { ChevronDown, Plus } from "lucide-react"
import closeIcon from "../../../../../../assets/images/close.svg"
import pencilIcon from "../../../../../../assets/images/pencil.svg"
import SVGInject from "@iconfu/svg-inject"
import { formattedCurrency } from "../../../../../../services/banking"
import "./styles.scss"

interface PaymentCardProps {
  card: {
    id: number
    title: string
    icon: string
    transactionType: string
  }
  isOpen: boolean
  configurations: any[]
  isOpMaster?: boolean
  onToggle: (cardId: number, transactionType: string) => void
  onEdit: (config: any, cardId: number, transactionType: string) => void
  onDelete: (config: any, cardId: number, transactionType: string) => void
  onCreate: (cardId: number, transactionType: string) => void
}

export const PaymentCard: React.FC<PaymentCardProps> = ({
  card,
  isOpen,
  configurations,
  onToggle,
  onEdit,
  onDelete,
  onCreate,
  isOpMaster,
}) => {
  return (
    <div key={card.id} className="payment-card">
      <div
        className="payment-card-header"
        onClick={() => onToggle(card.id, card.transactionType)}
      >
        <img
          className="icon"
          src={card.icon}
          onLoad={(e) => SVGInject(e.target)}
          alt={card.title}
        />
        <span className="title">{card.title}</span>
        <ChevronDown className={`chevron ${isOpen ? `open` : ``}`} size={20} />
      </div>
      <div className={`payment-card-content ${isOpen ? `open` : ``}`}>
        {configurations?.map((config) => (
          <div key={config.id} className="config-item">
            <div className="config-info">
              <div className="config-description">
                A partir de R${` `}
                {formattedCurrency(config.amountGreaterThanOrEqual)} precisa de
                {` `}
                {config.numberOfApprovals}
                {` `}
                {config.numberOfApprovals === 1 ? `aprovação` : `aprovações`}
              </div>
            </div>
            {isOpMaster && (
              <div className="config-actions">
                <button
                  className="icon-edit-approval-config"
                  aria-label="Editar"
                  onClick={() => onEdit(config, card.id, card.transactionType)}
                >
                  <img
                    className="icon"
                    src={pencilIcon}
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </button>
                <button
                  className="icon-delete-approval-config"
                  aria-label="Excluir"
                  onClick={() =>
                    onDelete(config, card.id, card.transactionType)
                  }
                >
                  <img src={closeIcon} onLoad={(e) => SVGInject(e.target)} />
                </button>
              </div>
            )}
          </div>
        ))}
        {isOpMaster && (
          <button
            className="add-config"
            onClick={() => onCreate(card.id, card.transactionType)}
          >
            <Plus size={16} className="plus-icon" />
            Adicionar configuração
          </button>
        )}
      </div>
    </div>
  )
}
