import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import "./styles.scss"
import { useResposive } from "../../../../hooks/useResponsive"
import ApprovalsPanel from "./Panel"
import SVGInject from "@iconfu/svg-inject"
import back from "../../../../assets/images/arrow_yellow.svg"
import services from "../../../../services/svcmesh"
import { AuthContext } from "../../../../context/AuthProvider"
import { AxiosError } from "axios"
import ErrorModal from "../../../features/ErrorModal"

export interface Approvals {
  id?: number // ID
  approvalStatus: number // Status de aprovação (0 = em andamento, 1 = aprovado, 2 = rejeitado)
  createdAt: string // Data de criação no formato ISO 8601
  modifiedAt: string // Data da última modificação no formato ISO 8601
  numberApprovalsRequired: number // Número de aprovações necessárias
  transactionAmount: string // Valor da transação
  transactionIdempotencyKey: string // Chave da transação
  transactionType: string // Tipo de transação (ex: "PIX")
  account: string // Número da conta
  approvalsLogCount: number
  recipientDocument: string
  recipientName: string
  notCompletedReason: string
}

export interface Transaction {
  type: string
  destinatary?: string
  requester: string
  requestDate: string
  accountNumber: string
  paymentDate: string
  amount: string
  status: string
  approvalStatus: number
  id: number
  transactionIdempotencyKey: string
  numberApprovalsRequired: number
  notCompletedReason: string
}

export const Approvals = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState(``)

  //pagination
  const [totalResults, setTotalResults] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [resultsPerPage, setResultsPerPage] = useState(10)

  const [activeTab, setActiveTab] = useState(`pending`)
  const [selectedCount, setSelectedCount] = useState(0)
  const [selectAll, setSelectAll] = useState(false)
  const [selectedRows, setSelectedRows] = useState<Transaction[]>([])
  const [isFetching, setIsFetching] = useState(false)
  const [isOpenModalException, setModalException] = useState(false)
  const [modalErrorMessage, setModalErrorMessage] = useState(``)
  const [isRejecting, setIsRejecting] = useState(false)
  const [isApproving, setIsApproving] = useState(false)

  const { user } = useContext(AuthContext)

  const [Approvals, setApprovals] = useState<Transaction[]>([])

  const { isTabletOrMobile } = useResposive()

  const tabs = [
    { id: `pending`, label: `Pendentes` },
    { id: `inProgress`, label: `Em andamento` },
    { id: `completed`, label: `Concluídas` },
  ]

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    // Here you would typically fetch new data based on the new page
  }

  const handleResultsPerPageChange = (value: number) => {
    setResultsPerPage(value)
    setCurrentPage(1) // Reset to first page when changing results per page
    // Here you would typically fetch new data based on the new resultsPerPage
  }

  useEffect(() => {
    setSelectAll(selectedRows.length === Approvals?.length)
  }, [selectedRows, Approvals])

  const handleSelectAll = () => {
    const newSelectAll = !selectAll
    setSelectAll(newSelectAll)

    setSelectedCount(newSelectAll ? Approvals.length : 0)

    if (newSelectAll) {
      setSelectedRows(Approvals)
    } else {
      setSelectedRows([])
    }
  }

  const fetchApprovals = async () => {
    setIsFetching(true)
    try {
      const response = await services.bankingService.getApprovals({
        page: currentPage,
        limit: resultsPerPage,
        approvalStatus: activeTab,
      })

      console.log(`response: `, response)

      setApprovals(
        response.data.approvals.map((approval: Approvals) => ({
          type: approval.transactionType,
          requester: user.nome.toUpperCase(),
          destinatary: approval.recipientName,
          requestDate: approval.createdAt.split(`T`)[0],
          accountNumber: approval.account,
          paymentDate: approval.modifiedAt.split(`T`)[0],
          amount: `R$ ${approval.transactionAmount}`,
          status: `${
            approval.numberApprovalsRequired - approval.approvalsLogCount
          } aprovações pendentes`,
          approvalStatus: approval.approvalStatus,
          id: approval.id,
          transactionIdempotencyKey: approval.transactionIdempotencyKey,
          numberApprovalsRequired: approval.numberApprovalsRequired,
          notCompletedReason: approval.notCompletedReason,
        })),
      )

      setTotalResults(response.data.total)
      setTotalPages(Math.ceil(response.data.total / resultsPerPage))
    } catch (error) {
      console.error(`Erro ao buscar aprovações:`, error)
    }
    setIsFetching(false)
  }

  // Recarrega os dados ao mudar de página, alterar o número de itens por página ou mudar a aba ativa
  useEffect(() => {
    fetchApprovals()
  }, [currentPage, resultsPerPage, activeTab])

  const splitTransactions = (transactions: Transaction[]) => {
    const filteredTransactions = transactions.filter((transaction) => {
      switch (activeTab) {
        case `pending`:
          return transaction.approvalStatus === 0
        case `completed`:
          return (
            transaction.approvalStatus === 1 ||
            transaction.approvalStatus === 4 ||
            transaction.approvalStatus === 5
          )
        case `inProgress`:
          return (
            transaction.approvalStatus === 2 || transaction.approvalStatus === 3
          )
        default:
          return false
      }
    })

    const totalAmount = filteredTransactions.reduce(
      (acc, transaction) =>
        acc + parseFloat(transaction.amount.replace(`R$ `, ``)),
      0,
    )

    return { transactions: filteredTransactions, totalAmount }
  }

  const { transactions, totalAmount } = splitTransactions(Approvals)

  console.log(`selected: `, selectedRows)

  const handleApprove = async () => {
    if (selectedRows.length === 0) {
      setModalErrorMessage(`Nenhuma transação selecionada para aprovação.`)
      setModalException(true)
      console.error(`Nenhuma transação selecionada para aprovação.`)
      return
    }

    if (selectedRows.length > 1) {
      setModalErrorMessage(`Apenas uma transação pode ser aprovada por vez.`)
      setModalException(true)
      console.error(`Apenas uma transação pode ser aprovada por vez.`)
      return
    }

    const selectedTransaction = selectedRows[0]
    setIsApproving(true)
    try {
      await services.bankingService.createApprovalLog({
        transaction_idempotency_key:
          selectedTransaction.transactionIdempotencyKey,
        requiredApprovals: selectedTransaction.numberApprovalsRequired,
        status: `APPROVE`,
      })
      setIsApproving(false)
      fetchApprovals()
      setSelectedRows([])
      setSelectedCount(0)
    } catch (error) {
      const err = error as AxiosError<{ message: string }>
      setModalErrorMessage(err.response?.data?.message || ``)
      setModalException(true)
      setIsApproving(false)
      console.log(`Erro ao aprovar transação:`, err.response?.data?.message)
    }
  }

  const handleReject = async () => {
    if (selectedRows.length === 0) {
      setModalErrorMessage(`Nenhuma transação selecionada para reprovação.`)
      setModalException(true)
      console.error(`Nenhuma transação selecionada para reprovação.`)
      return
    }

    if (selectedRows.length > 1) {
      setModalErrorMessage(`Apenas uma transação pode ser reprovada por vez.`)
      setModalException(true)
      console.error(`Apenas uma transação pode ser reprovada por vez.`)
      return
    }

    const selectedTransaction = selectedRows[0]
    setIsRejecting(true)
    try {
      await services.bankingService.createApprovalLog({
        transaction_idempotency_key:
          selectedTransaction.transactionIdempotencyKey,
        requiredApprovals: selectedTransaction.numberApprovalsRequired,
        status: `REJECT`,
      })
      setIsRejecting(false)
      fetchApprovals()
      setSelectedRows([])
      setSelectedCount(0)
    } catch (error) {
      const err = error as AxiosError<{ message: string }>
      setModalErrorMessage(err.response?.data?.message || ``)
      setModalException(true)
      setIsRejecting(false)
      console.log(`Erro ao aprovar transação:`, err.response?.data?.message)
    }
  }

  // const handleReject = async () => {
  //   setIsApprovement(false)
  //   await handleApprove(false)
  // }

  return (
    <div
      className="approvals-wrapper"
      style={{
        padding: isTabletOrMobile ? `0` : `0 75px`,
        backgroundColor: isTabletOrMobile
          ? `var(--k4n-primary-color)`
          : `transparent`,
      }}
    >
      {isTabletOrMobile && (
        <div className="approvals-container-mobile">
          <div className="approvals-header-mobile">
            <button
              className="approvals-header-mobile-btn f-l"
              onClick={() => navigate(`/banking`)}
            >
              <img src={back} alt="" onLoad={(e) => SVGInject(e.target)} />
            </button>
            <h1 className="approvals-header-h1-mobile"> Aprovações </h1>
          </div>
        </div>
      )}
      <ApprovalsPanel
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        search={search}
        setSearch={setSearch}
        selectedCount={selectedCount}
        setSelectedCount={setSelectedCount}
        selectAll={selectAll}
        currentPage={currentPage}
        totalPages={totalPages}
        totalResults={totalResults}
        resultsPerPage={resultsPerPage}
        Transactions={transactions}
        handlePageChange={handlePageChange}
        handleResultsPerPageChange={handleResultsPerPageChange}
        handleSelectAll={handleSelectAll}
        navigate={navigate}
        isFetching={isFetching}
        totalAmount={totalAmount}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        handleApprove={handleApprove}
        handleReject={handleReject}
        isApproving={isApproving}
        isRejecting={isRejecting}
        setModalErrorMessage={setModalErrorMessage}
        setModalException={setModalException}
      />
      <ErrorModal
        isOpen={isOpenModalException}
        width={`400px`}
        height={`auto`}
        onClose={() => setModalException(false)}
      >
        <h1 className="pix-modal-sucess-h1">
          {modalErrorMessage !== ``
            ? modalErrorMessage
            : `Você não pode efetuar essa ação.`}
        </h1>
      </ErrorModal>
    </div>
  )
}
