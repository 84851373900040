import CustomModal from "../../../../../features/CustomModal"
import { useForm } from "react-hook-form"
import "./styles.scss"

interface EditApprovalModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: any) => void
}

const DeleteApprovalModal = ({
  isOpen,
  onClose,
  onSubmit,
}: EditApprovalModalProps) => {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm({})

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} containerBg={`#ffff`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-content-delete-approval">
          <div className="modal-title-delete-approval">
            Excluir Configuração de Pagamento
          </div>
          <p className="modal-description-delete-approval">
            Atenção, você está prestes a excluir essa configuração de pagamento
            {``}
            essa ação é irreversível.
          </p>
          <div className="modal-buttons-delete-approval">
            <button onClick={onClose} className="button-primary-outlined">
              Cancelar
            </button>
            <button className="button-primary">Confirmar</button>
          </div>
        </div>
      </form>
    </CustomModal>
  )
}

export default DeleteApprovalModal
