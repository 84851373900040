import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"
import html2canvas from "html2canvas"
import { useContext } from "react"
import { BMP_BANK_NUMBER } from "../../../../../assets/constants"
import entradaDinheiro from "../../../../../assets/images/entrada_dinheiro.svg"
import saidaDinheiro from "../../../../../assets/images/saida_dinheiro.svg"
import share from "../../../../../assets/images/share_yellow.svg"
import { AuthContext } from "../../../../../context/AuthProvider"
import { BankingNavigationContext } from "../../../../../context/BankingNavigationContext"
import { TransferContext } from "../../../../../context/TransferContext"
import { formattedCurrency } from "../../../../../services/banking"
import { censorCpf } from "../../../../../utils/validation"
import "./styles.scss"
import SVGInject from "@iconfu/svg-inject"
import clearX from "../../../../../assets/images/clear_x.svg"
import { useResposive } from "../../../../../hooks/useResponsive"
import TransferReceiptMobile from "./mobile"

dayjs.extend(isBetween)

const TransferReceipt = () => {
  const { isTabletOrMobile } = useResposive()
  const {
    favoriteData,
    transferValue,
    transferStep3Data,
    transferTime,
    transactionId,
  } = useContext(TransferContext)
  const { user, current_account } = useContext(AuthContext)
  const { changeTransferStep } = useContext(BankingNavigationContext)
  const scheduleCondition =
    dayjs(transferStep3Data.date).isAfter(dayjs(), `d`) ||
    (dayjs().isBetween(
      dayjs().hour(0).minute(0).second(0).millisecond(0),
      dayjs().hour(7).minute(0).second(0).millisecond(0),
      `ms`,
    ) &&
      favoriteData.numero_banco !== BMP_BANK_NUMBER)

  const handleDownloadImage = async () => {
    html2canvas(document.querySelector(`#capture`)!).then((canvas) => {
      const data = canvas.toDataURL(`image/jpge`)
      const link = document.createElement(`a`)

      if (typeof link.download === `string`) {
        link.href = data
        link.download = `TRANSF_` + transactionId

        link.click()
      } else {
        window.open(data)
      }
    })
  }

  return (
    <>
      {!isTabletOrMobile ? (
        <div className="transfer-container">
          <div className="panel-content">
            <div id="capture" className="transfer-receipt-capture">
              <div className="transfer-receipt-btns">
                <button
                  className="close-btn"
                  onClick={() => changeTransferStep(1)}
                >
                  <img
                    src={clearX}
                    width="24"
                    height="24"
                    alt="/"
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </button>
                <button className="share-btn" onClick={handleDownloadImage}>
                  <img
                    src={share}
                    alt="/"
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </button>
              </div>
              <>
                <h1 className="transfer-receipt-title">
                  Comprovante de Transf&ecirc;ncia
                </h1>
                <div className="payment-receipt">
                  <span className="transfer-span-title-18">
                    {scheduleCondition ? `Agendado para` : `Realizado em`}
                  </span>
                  <span className="row-span-title-18 w700">
                    {scheduleCondition
                      ? dayjs(transferStep3Data.date).format(`DD/MM/YYYY`)
                      : transferTime}
                  </span>
                </div>
              </>
              <div className="section">
                <h2 className="section-title">Valor da transf&ecirc;ncia</h2>
                <span className="section-2-span">
                  {formattedCurrency(transferValue)}
                </span>
              </div>
              <div className="transfer-receipt-message">
                <div className="info-div">
                  <h1 className="info-span-bold-16">Mensagem:</h1>
                  <h1 className="info-span-grey-16">
                    {transferStep3Data.reason}
                  </h1>
                </div>
              </div>
              <div className="transfer-receipt-img-div">
                <img
                  src={entradaDinheiro}
                  alt=""
                  onLoad={(e) => SVGInject(e.target)}
                />
                <span className="section-title padding-left-10">
                  Destinatário
                </span>
              </div>
              <div className="transfer-info-div">
                <div className="info-div">
                  <h1 className="info-span-bold-16">Nome:</h1>
                  <h1 className="info-span-grey-16">{favoriteData.nome}</h1>
                </div>
                <div className="info-div">
                  <h1 className="info-span-bold-16">CPF:</h1>
                  <h1 className="info-span-grey-16">
                    {censorCpf(favoriteData.cpf || ``)}
                  </h1>
                </div>
                <div className="info-div">
                  <h1 className="info-span-bold-16">Instituição:</h1>
                  <h1 className="info-span-grey-16">
                    {favoriteData.numero_banco} - {favoriteData.instituicao}
                  </h1>
                </div>
                <div className="info-div">
                  <h1 className="info-span-bold-16">Agência:</h1>
                  <h1 className="info-span-grey-16">{`${favoriteData.agencia}-${
                    favoriteData.agencia_digito || `0`
                  }`}</h1>
                </div>
                <div className="info-div">
                  <h1 className="info-span-bold-16">Conta:</h1>
                  <h1 className="info-span-grey-16">{`${favoriteData.conta}-${favoriteData.conta_digito}`}</h1>
                </div>
              </div>
              <div className="transfer-receipt-img-div margin-top-30">
                <img
                  src={saidaDinheiro}
                  alt=""
                  onLoad={(e) => SVGInject(e.target)}
                />
                <span className="section-title padding-left-10">Origem</span>
              </div>
              <div className="transfer-info-div">
                <div className="info-div">
                  <h1 className="info-span-bold-16">Nome:</h1>
                  <h1 className="info-span-grey-16">{user?.nome}</h1>
                </div>
                <div className="info-div">
                  <h1 className="info-span-bold-16">CPF:</h1>
                  <h1 className="info-span-grey-16">
                    {censorCpf(user?.cpf || ``)}
                  </h1>
                </div>
                <div className="info-div">
                  <h1 className="info-span-bold-16">Instituição:</h1>
                  <h1 className="info-span-grey-16">BMP Money Plus</h1>
                </div>
                <div className="info-div">
                  <h1 className="info-span-bold-16">Agência:</h1>
                  <h1 className="info-span-grey-16">{`${
                    current_account?.agencia
                  }-${current_account?.agenciaDigito || `0`}`}</h1>
                </div>
                <div className="info-div">
                  <h1 className="info-span-bold-16">Conta:</h1>
                  <h1 className="info-span-grey-16">{`${current_account?.conta}-${current_account?.contaDigito}`}</h1>
                </div>
              </div>
              {!scheduleCondition && (
                <div className="receipt-transaction-id-div">
                  <div className="info-div">
                    <h1 className="info-span-bold-16">ID da transação:</h1>
                    <h1 className="info-span-grey-16">{transactionId}</h1>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="transfer-mobile-wrapper">
          <TransferReceiptMobile handleDownloadImage={handleDownloadImage} />
        </div>
      )}
    </>
  )
}

export default TransferReceipt
