import { Contact, CreateCustomerFavorite } from "k4n-svcmesh-sdk"
import { createContext, ReactElement, ReactNode, useState } from "react"
import { TransferStep3Data } from "../typings/transfer-step3-data.dto"

interface FavoriteData extends Contact {
  instituicao?: string
  accountType?: string
}

type TransferContextType = {
  transferValue: number
  favoriteData: FavoriteData
  transferStep3Data: TransferStep3Data
  newContactTransferStep1Data: CreateCustomerFavorite
  transferTime: string
  transactionId: string
  addTransferValue: (data: number) => void
  addFavoriteData: (data: FavoriteData) => void
  addTransferStep3Data: (data: TransferStep3Data) => void
  addNewContactTransferStep1Data: (data: CreateCustomerFavorite) => void
  addTransferTime: (data: string) => void
  addTransactionId: (data: string) => void
}

const contextDefaultValues: TransferContextType = {
  transferValue: 0,
  favoriteData: {},
  transferStep3Data: {},
  newContactTransferStep1Data: {},
  transferTime: ``,
  transactionId: ``,
  addTransferValue: () => {},
  addFavoriteData: () => {},
  addTransferStep3Data: () => {},
  addNewContactTransferStep1Data: () => {},
  addTransferTime: () => {},
  addTransactionId: () => {},
}

export const TransferContext =
  createContext<TransferContextType>(contextDefaultValues)

const TransferProvider = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const [transferValue, setTransferValue] = useState<number>(
    contextDefaultValues.transferValue,
  )
  const [favoriteData, setFavoriteData] = useState<FavoriteData>(
    contextDefaultValues.favoriteData,
  )
  const [transferStep3Data, setTransferStep3Data] = useState<TransferStep3Data>(
    contextDefaultValues.transferStep3Data,
  )
  const [newContactTransferStep1Data, setNewContactTransferStep1Data] =
    useState<CreateCustomerFavorite>(
      contextDefaultValues.newContactTransferStep1Data,
    )
  const [transferTime, setTransferTime] = useState<string>(
    contextDefaultValues.transferTime,
  )
  const [transactionId, setTransactionId] = useState<string>(
    contextDefaultValues.transactionId,
  )
  const addTransferValue = (data: number) => setTransferValue(data)
  const addFavoriteData = (data: FavoriteData) => setFavoriteData(data)
  const addTransferStep3Data = (data: TransferStep3Data) =>
    setTransferStep3Data(data)
  const addNewContactTransferStep1Data = (data: CreateCustomerFavorite) =>
    setNewContactTransferStep1Data(data)
  const addTransferTime = (data: string) => setTransferTime(data)
  const addTransactionId = (data: string) => setTransactionId(data)

  return (
    <TransferContext.Provider
      value={{
        transferValue,
        favoriteData,
        transferStep3Data,
        newContactTransferStep1Data,
        transferTime,
        transactionId,
        addTransferValue,
        addFavoriteData,
        addTransferStep3Data,
        addNewContactTransferStep1Data,
        addTransferTime,
        addTransactionId,
      }}
    >
      {children}
    </TransferContext.Provider>
  )
}

export default TransferProvider
