import { BmpTipoConta, GetBanksResponse } from "k4n-svcmesh-sdk"
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import InputMask from "react-input-mask"
import { AuthContext } from "../../../../../../context/AuthProvider"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import { TransferContext } from "../../../../../../context/TransferContext"
import { useResposive } from "../../../../../../hooks/useResponsive"
import services from "../../../../../../services/svcmesh"
import {
  formatCpf,
  unformatCpf,
  validateCpf,
  unformatCnpj,
  validateCNPJ,
} from "../../../../../../utils/validation"
import { SecondaryButton, PrimaryButton } from "k4n-components"
import TransferNav from "../../transfer-nav"
import NewContactTransferStep1Mobile from "./mobile"
import "./styles.scss"

const NewContactTransferStep1 = () => {
  const { changeNewContactTransferStep, changeTransferStep } = useContext(
    BankingNavigationContext,
  )
  const { isTabletOrMobile } = useResposive()
  const { user } = useContext(AuthContext)
  const { addFavoriteData } = useContext(TransferContext)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [sameAccountHolder, setSameAccountHolder] = useState<boolean>(false)
  const [isCorporateAccount, setIsCorporateAccount] = useState<boolean>(false)
  const [banks, setBanks] = useState<GetBanksResponse>({ banks: [] })
  const [loadingBanks, setLoadingBanks] = useState<boolean>(true)
  const [bankAgency, setBankAgency] = useState<string>(``)
  const [accountNumber, setAccountNumber] = useState<string>(``)

  const getK4nBanks = async () => {
    try {
      setBanks(await services.bankingService.getBanks())
      setLoadingBanks(false)
    } catch (error) {
      console.log(error)
      setLoadingBanks(false)
    }
  }

  useEffect(() => {
    getK4nBanks()
  }, [])

  const onSubmit = (data: any) => {
    addFavoriteData({
      nome: sameAccountHolder ? user?.nome : data.holder_name,
      cpf: sameAccountHolder ? user?.cpf : unformatCpf(data.holder_cpf),
      cnpj: isCorporateAccount ? unformatCnpj(data.holder_cnpj) : ``,
      accountType: isCorporateAccount ? `PJ` : `PF`,
      numero_banco: data.bank_number.split(` - `)[0],
      agencia: data.bank_agency,
      agencia_digito: ``,
      conta: data.account_number,
      conta_digito: data.account_digit,
      tipo_conta: data.account_type,
      instituicao: data.bank_number
        .split(` - `)
        .filter((_, i) => i !== 0)
        .join(` - `),
    })
    changeTransferStep(3)
    changeNewContactTransferStep(2)
  }

  return (
    <>
      {!isTabletOrMobile ? (
        <div className="transfer-container">
          <TransferNav step={2} stepTitle="Nova transferência" />
          <div className="transfer-new-contact-panel">
            <div className="transfer-new-contact-title">
              <h1 className="title-blue">Transferir para</h1>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="transfer-new-contact-form-div"
            >
              {!sameAccountHolder && (
                <div className="checkbox-transfer-corporate-account-div">
                  <input
                    onClick={() => setIsCorporateAccount(!isCorporateAccount)}
                    className="form-check-input checkbox-transfer-corporate-account"
                    type="checkbox"
                  />
                  <span className="checkbox-transfer-corporate-account-span">
                    Conta Empresarial
                  </span>
                </div>
              )}
              {!isCorporateAccount && (
                <div className="checkbox-transfer-new-contact-div">
                  <input
                    onClick={() => setSameAccountHolder(!sameAccountHolder)}
                    className="form-check-input transfer-new-contact-holder-checkbox"
                    type="checkbox"
                  />
                  <span className="checkbox-transfer-new-contact-span">
                    Mesmo titular da conta
                  </span>
                </div>
              )}
              {sameAccountHolder && !isCorporateAccount && (
                <div className="transfer-new-contact-holder-info">
                  <h1 className="transfer-new-contact-info-name">
                    {user?.nome}
                  </h1>
                  <p className="transfer-new-contact-info-cpf">
                    {formatCpf(user?.cpf || ``)}
                  </p>
                </div>
              )}
              <div className="transfer-new-contact-box-div">
                <div className="transfer-new-contact-form-field bank-name">
                  <span className="transfer-new-contact-form-span">
                    Nome do banco
                  </span>
                  <input
                    list="banks"
                    type="text"
                    className="select-transfer-new-contact"
                    {...register(`bank_number`, {
                      required: true,
                      validate: (data: string) => {
                        return banks.banks.some(
                          (b) => b.number === data.split(` - `)[0],
                        )
                      },
                    })}
                  />
                  <datalist id="banks">
                    {!loadingBanks &&
                      banks.banks.map((b, i) => (
                        <option key={i} value={`${b.number} - ${b.name}`} />
                      ))}
                  </datalist>
                  {errors.bank_number && (
                    <p className="new-contact-transfer-error-msg">
                      Verifique o banco informado.
                    </p>
                  )}
                </div>
              </div>
              <div className="transfer-new-contact-box-div">
                <div className="transfer-new-contact-form-field account-type">
                  <span className="transfer-new-contact-form-span">
                    Selecione o tipo de Conta
                  </span>
                  <select
                    className="select-transfer-new-contact"
                    {...register(`account_type`, {
                      required: true,
                    })}
                  >
                    <option value={BmpTipoConta.Corrente}>
                      Conta corrente
                    </option>
                    <option value={BmpTipoConta.Poupanca}>
                      Conta poupança
                    </option>
                    <option value={BmpTipoConta.Pagamento}>
                      Conta pagamento
                    </option>
                    <option value={BmpTipoConta.Salario}>Conta salário</option>
                  </select>
                  {errors.account_type && (
                    <p className="new-contact-transfer-error-msg">
                      Verifique o tipo de conta informado.
                    </p>
                  )}
                </div>
                <div className="transfer-new-contact-form-field agency">
                  <span className="transfer-new-contact-form-span">
                    Agência
                  </span>
                  <input
                    value={bankAgency}
                    type="text"
                    className="transfer-new-contact-form-input"
                    placeholder="0000"
                    {...register(`bank_agency`, {
                      required: true,
                      onChange: (e) => {
                        setBankAgency(
                          e.currentTarget.value.replace(/[^0-9]/g, ``),
                        )
                      },
                    })}
                  />
                  {errors.bank_agency && (
                    <p className="new-contact-transfer-error-msg">
                      Verifique a agência informada.
                    </p>
                  )}
                </div>
                <div className="transfer-new-contact-form-field account">
                  <span className="transfer-new-contact-form-span">Conta</span>
                  <input
                    value={accountNumber}
                    type="text"
                    className="transfer-new-contact-form-input"
                    placeholder="0000000"
                    {...register(`account_number`, {
                      required: true,
                      onChange: (e) =>
                        setAccountNumber(
                          e.currentTarget.value.replace(/[^0-9]/g, ``),
                        ),
                    })}
                  />
                  {errors.account_number && (
                    <p className="new-contact-transfer-error-msg">
                      Verifique o número da conta informado.
                    </p>
                  )}
                </div>
                <div className="transfer-new-contact-form-field account-digit">
                  <span className="transfer-new-contact-form-span">Digito</span>
                  <input
                    type="text"
                    className="transfer-new-contact-form-input"
                    placeholder="0"
                    {...register(`account_digit`, {
                      required: true,
                    })}
                  />
                  {errors.account_digit && (
                    <p className="new-contact-transfer-error-msg">
                      Verifique o dígito da conta informado.
                    </p>
                  )}
                </div>
              </div>
              {!sameAccountHolder && (
                <div className="transfer-new-contact-box-div">
                  <div className="transfer-new-contact-name-box">
                    <span className="transfer-new-contact-form-span">Nome</span>
                    <input
                      type="text"
                      className="transfer-new-contact-form-input"
                      placeholder="Digite aqui"
                      {...register(`holder_name`, {
                        required: true,
                      })}
                    />
                    {errors.holder_name && (
                      <p className="new-contact-transfer-error-msg">
                        Verifique o nome informado.
                      </p>
                    )}
                  </div>
                  {!isCorporateAccount && (
                    <div className="transfer-new-contact-form-field">
                      <span className="transfer-new-contact-form-span">
                        CPF do titular
                      </span>
                      <InputMask
                        mask="999.999.999-99"
                        type="text"
                        className="transfer-new-contact-form-input"
                        placeholder="000.000.000-00"
                        {...register(`holder_cpf`, {
                          required: true,
                          pattern: /^\d\d\d\.\d\d\d\.\d\d\d-\d\d$/,
                          validate: validateCpf,
                        })}
                      />
                      {errors.holder_cpf && (
                        <p className="new-contact-transfer-error-msg">
                          Verifique o cpf informado.
                        </p>
                      )}
                    </div>
                  )}
                  {isCorporateAccount && (
                    <div className="transfer-new-contact-form-field">
                      <span className="transfer-new-contact-form-span">
                        CNPJ da empresa
                      </span>
                      <InputMask
                        mask="99.999.999/9999-99"
                        type="text"
                        className="transfer-new-contact-form-input"
                        placeholder="00.000.000/0000-00"
                        {...register(`holder_cnpj`, {
                          required: true,
                          pattern: /^\d\d\.\d\d\d\.\d\d\d\/\d\d\d\d-\d\d$/,
                          validate: validateCNPJ,
                        })}
                      />
                      {errors.holder_cnpj && (
                        <p className="new-contact-transfer-error-msg">
                          Verifique o CNPJ informado.
                        </p>
                      )}
                    </div>
                  )}
                </div>
              )}
              <div className="transfer-new-contact-btns-div">
                <SecondaryButton
                  width={`200px`}
                  height={`60px`}
                  text={`Voltar`}
                  OnClick={() => {
                    changeTransferStep(2)
                    changeNewContactTransferStep(0)
                  }}
                />
                <PrimaryButton width={`200px`} height={`60px`}>
                  Próximo
                </PrimaryButton>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="transfer-mobile-wrapper">
          <NewContactTransferStep1Mobile
            onSubmit={onSubmit}
            setSameAccountHolder={setSameAccountHolder}
            sameAccountHolder={sameAccountHolder}
            setIsCorporateAccount={setIsCorporateAccount}
            isCorporateAccount={isCorporateAccount}
            banks={banks}
            loadingBanks={loadingBanks}
            bankAgency={bankAgency}
            setBankAgency={setBankAgency}
            accountNumber={accountNumber}
            setAccountNumber={setAccountNumber}
          />
        </div>
      )}
    </>
  )
}

export default NewContactTransferStep1
