import { useContext, useEffect, useState } from "react"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import { SecondaryButton, PrimaryButton } from "k4n-components"
import PixNav from "../../nav-pix"
import cell from "../../../../../../assets/images/cell.svg"
import userImg from "../../../../../../assets/images/user.svg"
import mail from "../../../../../../assets/images/mail_yellow.svg"
import random_key from "../../../../../../assets/images/random_key.svg"
import copy_yellow from "../../../../../../assets/images/copy_yellow.svg"
import arrow_right from "../../../../../../assets/images/arrow-right-yellow.svg"
import "./styles.scss"
import { useForm } from "react-hook-form"
import InputMask from "react-input-mask"
import {
  PixKeyType,
  PixStorageContext,
} from "../../../../../../context/PixStorageProvider"
import { Step1PixPayment } from "../../../../../../typings/step1-pix-payment.dto"
import { AxiosError } from "axios"
import { AuthContext } from "../../../../../../context/AuthProvider"
import Favorites, { FavoriteScreenEnum } from "../../../../Favorites"
import SVGInject from "@iconfu/svg-inject"
import { useResposive } from "../../../../../../hooks/useResponsive"
import PixPaymentStep1Mobile from "./Mobile"
import services from "../../../../../../services/svcmesh"
import {
  ConsultPixKey,
  GetCustomerFavoritesResponse,
  TipoPagamentoPix,
} from "k4n-svcmesh-sdk"

const PixPaymentStep1 = () => {
  const { changePixEnvironment, changePixPayment } = useContext(
    BankingNavigationContext,
  )
  const { addPixPaymentStep1, addPixPaymentType } =
    useContext(PixStorageContext)
  const [pixKeyType, setPixKeyType] = useState(2)
  const [showPixInput, setShowPixInput] = useState(false)
  const [errorPixKey, setErrorPixKey] = useState(``)
  const [maskCpfCnpj, setMaskCpfCnpj] = useState(``)
  const [btnLoading, setBtnLoading] = useState(false)
  const [favorites, setFavorites] = useState<GetCustomerFavoritesResponse>({
    contacts: [],
  })
  const [loadingFavorites, setLoadingFavorites] = useState(true)
  const { isTabletOrMobile } = useResposive()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({})
  const { user, current_account } = useContext(AuthContext)

  function handleCpfCnpjChange(e: any) {
    setErrorPixKey(``)
    let value = e.currentTarget.value
    console.log(value.length)
    value = value.replace(/\D/g, ``)
    if (value.length <= 11) {
      value = value.replace(/(\d{3})(\d)/, `$1.$2`)
      value = value.replace(/(\d{3})(\d)/, `$1.$2`)
      value = value.replace(/(\d{3})(\d{1,2})$/, `$1-$2`)
    } else {
      value = value.replace(/^(\d{2})(\d)/, `$1.$2`)
      value = value.replace(/^(\d{2})\.(\d{3})(\d)/, `$1.$2.$3`)
      value = value.replace(/\.(\d{3})(\d)/, `.$1/$2`)
      value = value.replace(/(\d{4})(\d)/, `$1-$2`)
    }
    setMaskCpfCnpj(value)
  }

  const onSubmit = (data: any) => {
    if (pixKeyType === PixKeyType.CPF) {
      const cpfOrCnpj = data.documento
        .replaceAll(`.`, ``)
        .replaceAll(`-`, ``)
        .replaceAll(`/`, ``)
      console.log(cpfOrCnpj)
      consultPix(cpfOrCnpj)
    }
    if (pixKeyType === PixKeyType.CNPJ) {
      // sendObj.cnpj = ``
    }
    if (pixKeyType === PixKeyType.CELULAR) {
      const tel =
        data.ddd +
        data.phone
          .replaceAll(` `, ``)
          .replace(`-`, ``)
          .replace(`(`, ``)
          .replace(`)`, ``)
      consultPix(tel)
    }
    if (pixKeyType === PixKeyType.EMAIL) {
      const email = data.email
      console.log(email)
      consultPix(email)
    }
    if (pixKeyType === PixKeyType.EVP) {
      const evp = data.evp
      console.log(evp)
      consultPix(evp)
    }
  }

  const onSubmitFavorite = async (data: any) => {
    setBtnLoading(true)
    try {
      const res = await consultPix(data.chave_pix)
      console.log(res)
      setBtnLoading(false)
    } catch (error) {
      const err = error as AxiosError<any>
      setBtnLoading(false)
      setErrorPixKey(err.response?.data.message)
    }
  }

  const consultPix = async (chave: any) => {
    setBtnLoading(true)
    try {
      let accountLength = 0
      let contaPgt: string | undefined
      if (current_account?.conta && current_account?.contaDigito) {
        accountLength = (current_account?.conta + current_account?.contaDigito)
          .length
      }
      if (accountLength == 5)
        contaPgt = `00` + current_account?.conta + current_account?.contaDigito
      if (accountLength == 6)
        contaPgt = `0` + current_account?.conta + current_account?.contaDigito
      if (accountLength == 7)
        contaPgt = `` + current_account?.conta + current_account?.contaDigito

      const sendObj = {
        Chave: chave,
        ContaDtoAgencia: current_account?.agencia,
        ContaDtoAgenciaDigito: current_account?.agenciaDigito,
        ContaDtoConta: current_account?.conta,
        ContaDtoContaDigito: current_account?.contaDigito,
        ContaDtoContaPgto: contaPgt,
        ContaDtoTipoConta: current_account?.tipoConta,
      } as ConsultPixKey
      const res = await services.bankingService.consultPixKey(sendObj)
      if (res.data.sucesso === true) {
        const saveObj = {
          nome: res.data.nomeCorrentista,
          chave: res.data.chave,
          tipoChave: res.data.tipoChave,
          // cpf: res.data.documentoFederal,
          numeroBanco: res.data.banco.descricao,
          agencia: res.data.conta.agencia,
          conta: res.data.conta.conta,
          valor: 0,
          message: ``,
          ticket: res.data.ticket,
        } as Step1PixPayment
        if (pixKeyType === PixKeyType.CPF || pixKeyType === PixKeyType.CNPJ) {
          saveObj.cpf = res.data.chave
        } else {
          saveObj.cpf = ` `
        }
        console.log(saveObj)
        setBtnLoading(false)
        addPixPaymentType(TipoPagamentoPix.PIX_PAYMENT)
        addPixPaymentStep1(saveObj)
        changePixPayment(2)
      }
    } catch (error: any) {
      const err = error as AxiosError<any>
      setBtnLoading(false)
      setErrorPixKey(err.response?.data.message)
    }
  }

  const onReturn = () => {
    setShowPixInput(false)
  }

  const handlePageChange = (newPage: number) => {
    changePixEnvironment(newPage)
  }

  const getUserFavorites = async () => {
    try {
      const params =
        user?.accounts[0]?.tipoPessoa === 1
          ? { clientePfId: user?.id }
          : { operadorPjId: user?.id }
      const res = await services.bankingService.getCustomerFavorites({
        ...params,
        type: `pix`,
      })
      setFavorites(res)
      setLoadingFavorites(false)
    } catch (error) {
      console.log(error)
      setLoadingFavorites(false)
    }
  }

  useEffect(() => {
    getUserFavorites()
  }, [])

  useEffect(() => {
    console.log(favorites)
  }, [favorites])

  return (
    <>
      {isTabletOrMobile !== true ? (
        <>
          {showPixInput === false ? (
            <div className="pix-payment-container">
              <div className="pix-payment-content">
                <h1 className="pix-payment-content-title">
                  Pagar para um novo contato
                </h1>
                <h1 className="pix-payment-content-txt">Escolha a chave</h1>
                <div
                  onClick={() => {
                    setPixKeyType(2)
                    setShowPixInput(true)
                  }}
                  className="content-table pointer"
                >
                  <div className="align-center">
                    <img
                      className="pix-content-img"
                      src={cell}
                      alt="/"
                      onLoad={(e) => SVGInject(e.target)}
                    />
                    <span className="pix-content-txt">Celular</span>
                  </div>
                  <img
                    className="pix-content-img"
                    src={arrow_right}
                    alt="/"
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </div>
                <div
                  onClick={() => {
                    setPixKeyType(0)
                    setShowPixInput(true)
                  }}
                  className="content-table pointer"
                >
                  <div className="align-center">
                    <img
                      className="pix-content-img"
                      src={userImg}
                      alt="/"
                      onLoad={(e) => SVGInject(e.target)}
                    />
                    <span className="pix-content-txt">CPF ou CNPJ</span>
                  </div>
                  <img
                    className="pix-content-img"
                    src={arrow_right}
                    alt="/"
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </div>
                <div
                  onClick={() => {
                    setPixKeyType(3)
                    setShowPixInput(true)
                  }}
                  className="content-table pointer"
                >
                  <div className="align-center">
                    <img
                      className="pix-content-img"
                      src={mail}
                      alt="/"
                      onLoad={(e) => SVGInject(e.target)}
                    />
                    <span className="pix-content-txt">E-mail</span>
                  </div>
                  <img
                    className="pix-content-img"
                    src={arrow_right}
                    alt="/"
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </div>
                {/*<div
                  onClick={() => {
                    setPixKeyType(5)
                    setShowPixInput(true)
                  }}
                  className="content-table pointer"
                >
                  <div className="align-center">
                    <img
                      className="pix-content-img"
                      src={copy_yellow}
                      alt="/"
                      onLoad={(e) => SVGInject(e.target)}
                    />
                    <span className="pix-content-txt">Pix Copia e Cola</span>
                  </div>
                  <img
                    className="pix-content-img"
                    src={arrow_right}
                    alt="/"
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </div>*/}
                <div
                  onClick={() => {
                    setPixKeyType(4)
                    setShowPixInput(true)
                  }}
                  className="content-table pointer"
                >
                  <div className="align-center">
                    <img
                      className="pix-content-img"
                      src={random_key}
                      alt="/"
                      onLoad={(e) => SVGInject(e.target)}
                    />
                    <span className="pix-content-txt  pix-payment-random">
                      Chave aleatória
                    </span>
                  </div>
                  <img
                    className="pix-content-img"
                    src={arrow_right}
                    alt="/"
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </div>
                {!loadingFavorites ? (
                  <Favorites
                    favorites={favorites}
                    title={`Pagar para um contato`}
                    screen={FavoriteScreenEnum.PIX}
                    changeStep={() => true}
                    addData={onSubmitFavorite}
                  />
                ) : (
                  <h6
                    style={{
                      color: `black`,
                      marginTop: `50px`,
                      textAlign: `center`,
                    }}
                  >
                    Carregando favoritos...
                  </h6>
                )}
                <div className="pix-payment-align-btn">
                  <SecondaryButton
                    width={`141px`}
                    height={`60px`}
                    text={`Voltar`}
                    OnClick={() => handlePageChange(1)}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="pix-payment-step1-container">
              <PixNav step={1} title={`Pagamento`} />
              <div className="pix-payment-step1-content">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {pixKeyType === 0 && (
                    <>
                      <div className="content-table">
                        <h1 className="pix-payment-step1-title">
                          Digite o CPF ou CNPJ
                        </h1>
                      </div>
                      <input
                        value={maskCpfCnpj}
                        type="text"
                        maxLength={18}
                        className="pix-payment-step1-input"
                        {...register(`documento`, {
                          onChange: handleCpfCnpjChange,
                          required: true,
                          pattern:
                            /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/,
                        })}
                        onKeyPress={(event) => {
                          if (event.key === `Enter`) {
                            event.preventDefault()
                          }
                        }}
                      />
                      {errors.documento && (
                        <p className="pix-error-msg">
                          Por favor, verifique o CPF ou CNPJ.
                        </p>
                      )}
                      {errorPixKey && !errors.documento && (
                        <p className="pix-error-msg">{errorPixKey}</p>
                      )}
                    </>
                  )}
                  {pixKeyType === 2 && (
                    <>
                      <div className="content-table">
                        <h1 className="pix-payment-step1-title">
                          Digite o celular
                        </h1>
                      </div>
                      <select
                        className="pix-payment-step1-select"
                        {...register(`ddd`, { required: true })}
                      >
                        <option value="+55">+55</option>
                      </select>
                      <InputMask
                        mask="(99) 99999-9999"
                        maskPlaceholder={null}
                        type="text"
                        className="pix-payment-step1-input"
                        {...register(`phone`, {
                          required: true,
                          pattern:
                            /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/,
                        })}
                        onKeyPress={(event) => {
                          if (event.key === `Enter`) {
                            event.preventDefault()
                          }
                        }}
                      />
                      {errors.phone && (
                        <p className="pix-error-msg">
                          Por favor, verifique o celular.
                        </p>
                      )}
                      {errorPixKey && !errors.phone && (
                        <p className="pix-error-msg">{errorPixKey}</p>
                      )}
                    </>
                  )}

                  {pixKeyType === 3 && (
                    <>
                      <div className="content-table">
                        <h1 className="pix-payment-step1-title">
                          Digite o e-mail
                        </h1>
                      </div>
                      <input
                        type="text"
                        maxLength={300}
                        className="pix-payment-step1-input wd-450"
                        {...register(`email`, {
                          required: true,
                          pattern:
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        })}
                        onKeyPress={(event) => {
                          if (event.key === `Enter`) {
                            event.preventDefault()
                          }
                        }}
                      />
                      {errors.email && (
                        <p className="pix-error-msg">
                          Por favor, verifique o email.
                        </p>
                      )}
                      {errorPixKey && !errors.email && (
                        <p className="pix-error-msg">{errorPixKey}</p>
                      )}
                    </>
                  )}
                  {pixKeyType === 4 && (
                    <>
                      <div className="content-table">
                        <h1 className="pix-payment-step1-title">
                          Insira a chave aleatória a ser paga
                        </h1>
                      </div>
                      <input
                        type="text"
                        className="pix-payment-step1-input wd-450"
                        {...register(`evp`, {
                          required: true,
                        })}
                        onKeyPress={(event) => {
                          if (event.key === `Enter`) {
                            event.preventDefault()
                          }
                        }}
                      />
                      {errors.evp && (
                        <p className="pix-error-msg">
                          Por favor, verifique a chave.
                        </p>
                      )}
                      {errorPixKey && !errors.evp && (
                        <p className="pix-error-msg">{errorPixKey}</p>
                      )}
                    </>
                  )}
                  {pixKeyType === 5 && (
                    <>
                      <div className="content-table">
                        <h1 className="pix-payment-step1-title">
                          Insira o código a ser pago
                        </h1>
                      </div>
                      <input
                        type="text"
                        className="pix-payment-step1-input wd-450"
                        {...register(`copyPasteCode`, {
                          required: true,
                        })}
                        onKeyPress={(event) => {
                          if (event.key === `Enter`) {
                            event.preventDefault()
                          }
                        }}
                      />
                      {errors.copyPasteCode && (
                        <p className="pix-error-msg">
                          Por favor, verifique o código.
                        </p>
                      )}
                      {errorPixKey && !errors.copyPasteCode && (
                        <p className="pix-error-msg">{errorPixKey}</p>
                      )}
                    </>
                  )}
                  <div className="pix-payment-step1-btn">
                    <SecondaryButton
                      width={`141px`}
                      height={`60px`}
                      text={`Voltar`}
                      margin_right={`50px`}
                      OnClick={() => onReturn()}
                    />
                    <PrimaryButton
                      width={`264px`}
                      height={`60px`}
                      onClick={() => true}
                    >
                      {!btnLoading ? (
                        `Próximo`
                      ) : (
                        <div
                          className="spinner-border spinner-pix"
                          role="status"
                        ></div>
                      )}
                    </PrimaryButton>
                  </div>
                </form>
              </div>
            </div>
          )}
        </>
      ) : (
        <PixPaymentStep1Mobile
          onSubmit={onSubmit}
          pixKeyType={pixKeyType}
          setPixKeyType={setPixKeyType}
          btnLoading={btnLoading}
          errorPixKey={errorPixKey}
          favorites={favorites}
          onSubmitFavorite={onSubmitFavorite}
          loadingFavorites={loadingFavorites}
        />
      )}
    </>
  )
}
export default PixPaymentStep1
