import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import "./styles.scss"
import Header from "../../../features/Header"
import { useResposive } from "../../../../hooks/useResponsive"
import arrowDown from "../../../../assets/images/arrow_yellow_down.svg"
import SVGInject from "@iconfu/svg-inject"
import contaDigitalIcon from "../../../../assets/images/transfer.svg"
import { t } from "i18next"
import services from "../../../../services/svcmesh"
import { AuthorizationProfile } from "k4n-svcmesh-sdk/dist/typings/authorization-profile.dto"
import SuccessModal from "../../../features/SuccesModal"
import ErrorModal from "../../../features/ErrorModal"
import { AuthContext } from "../../../../context/AuthProvider"
import { useNavigate, useParams } from "react-router-dom"

interface GrantCategory {
  groupName: string
  icon: string
  categories: { categoryName: string; grants: string[] }[]
}

export const ProfileManagement = () => {
  const { id } = useParams()

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const { isMobile } = useResposive()
  const [inputFocus, setInputFocus] = useState(0)
  const [collapsedCategories, setCollapsedCategories] = useState({})
  const [selectedGrants, setSelectedGrants] = useState<
    { group: string; category: string; grant: string }[]
  >([])
  const [grantCategories, setGrantCategories] = useState<GrantCategory[]>([])
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const { user } = useContext(AuthContext)

  useEffect(() => {
    if (id) {
      const getProfile = async () => {
        try {
          const res = await services.bankingService.getAuthorizationProfileById(
            Number(id),
          )
          const profile = res.data

          reset({ profileName: profile.nome })

          const grants = profile.grants.split(`,`).map((grant) => {
            const parts = grant.split(`.`)
            return {
              group: parts[1],
              category: parts[1],
              grant: grant,
            }
          })

          setSelectedGrants(grants)

          console.log(`profile: `, profile)
        } catch (error) {
          console.error(`Error fetching profile:`, error)
        }
      }
      getProfile()
    }
  }, [id])

  useEffect(() => {
    console.log(`selectedGrants atualizado: `, selectedGrants)
  }, [selectedGrants])

  const handleFocus = (e) => {
    if (e.target.id === `profileName`) {
      setInputFocus(inputFocus === 1 ? 0 : 1)
    }
  }

  const onSubmit = async (data) => {
    if (selectedGrants.length === 0) {
      alert(`Selecione pelo menos uma permissão`)
    } else {
      console.log(`Form Submitted`, { ...data, selectedGrants })
      const profile: AuthorizationProfile = {
        nome: data.profileName,
        grants: selectedGrants.map((grant) => grant.grant).join(`,`),
      }
      try {
        if (id) {
          await services.bankingService.updateAuthorizationProfile(
            Number(id),
            profile,
          )
          setShowSuccessModal(true)
          return
        } else {
          await services.bankingService.createAuthorizationProfile(profile)
          setShowSuccessModal(true)
        }
      } catch (error) {
        console.log(error)
        setShowErrorModal(true)
      }
    }
  }

  const getAllGrants = async () => {
    try {
      const res = await services.bankingService.getAllDistinctGrantsForRole()

      // format of allowed features: { banking: true, cards: false, withdraw: true, insurance: true}
      const allowedFeatures = user.allowed_features

      // Filter out grants that belong to not allowed features
      const filteredData = res.data.filter((grant) => {
        const parts = grant.split(`.`)
        if (parts.length > 1) {
          const featureName = parts[1].toLowerCase()
          return allowedFeatures[featureName] === true
        }
        return true
      })

      const groupedGrants = groupGrants(filteredData)
      setGrantCategories(groupedGrants)
    } catch (error) {
      console.error(`Error fetching grants:`, error)
    }
  }

  useEffect(() => {
    getAllGrants()
  }, [])

  const groupGrants = (grants: string[]): GrantCategory[] => {
    const grouped: { [key: string]: string[] } = {}

    const filteredGrants = grants.filter((grant) => {
      if (!grant.includes(`internal`) && !grant.includes(`public`)) {
        if (user && user.allowed_features && !user.allowed_features.withdraw) {
          if (!grant.includes(`withdraw`)) {
            return grant
          }
        } else return grant
      }
    })

    filteredGrants.forEach((grant) => {
      const parts = grant.split(`.`)
      if (parts.length > 1) {
        const groupName = parts[1]
        if (!grouped[groupName]) {
          grouped[groupName] = []
        }
        grouped[groupName].push(grant)
      }
    })

    const result: GrantCategory[] = Object.entries(grouped).map(
      ([groupName, grants]) => ({
        groupName: groupName,
        icon: contaDigitalIcon, // Replace with appropriate icon
        categories: [
          {
            categoryName: groupName,
            grants: grants,
          },
        ],
      }),
    )
    return result
  }

  const toggleCollapse = (categoryName) => {
    setCollapsedCategories((prev) => ({
      ...prev,
      [categoryName]: !prev[categoryName],
    }))
  }

  const handleGrantChange = (group, category, grant) => {
    setSelectedGrants((prev: any) => {
      const exists = prev.some(
        (item) =>
          item.group === group &&
          item.category === category &&
          item.grant === grant,
      )

      if (exists) {
        return prev.filter(
          (item) =>
            !(
              item.group === group &&
              item.category === category &&
              item.grant === grant
            ),
        )
      } else {
        return [...prev, { group, category, grant }]
      }
    })
  }

  return (
    <div className="pm-page-container">
      <Header />
      <div className="pm-management-container">
        <div className="pm-management-content">
          <div className="pm-management-header">
            <h1>{t(`profilePage.title`)}</h1>
          </div>
          <span className="description">
            Crie perfis de permissão para facilitar o controle sobre as
            funcionalidades dos usuários de sua Conta Digital PJ
          </span>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pm-manager">
              <div className="options">
                <div className="pm-input-container">
                  <label
                    className={
                      inputFocus === 1
                        ? `pm-input-label pm-show-input`
                        : `pm-input-label`
                    }
                    htmlFor="profileName"
                  >
                    Nome do perfil:
                  </label>
                  <input
                    id="profileName"
                    type="text"
                    placeholder="Digite o nome do perfil"
                    className="pm-input"
                    onFocus={handleFocus}
                    {...register(`profileName`, {
                      required: `Por favor, preencha o nome do perfil.`,
                      onBlur: () => setInputFocus(0),
                      minLength: {
                        value: 8,
                        message: `O nome do perfil deve ter pelo menos 8 caracteres.`,
                      },
                      maxLength: {
                        value: 120,
                        message: `O nome do perfil deve ter no máximo 120 caracteres.`,
                      },
                    })}
                  />
                  {errors.profileName && (
                    <span className="pm-error-msg">
                      {errors.profileName.message?.toString()}
                    </span>
                  )}
                </div>
                {/* <div className="pm-select-container">
                  <label
                    className="pm-input-label pm-show-input"
                    htmlFor="profileStatus"
                  >
                    Perfil em funcionamento?
                  </label>
                  <select
                    className="pm-select pm-select-value-txt"
                    id="profileStatus"
                    {...register(`profileStatus`, {})}
                  >
                    <option value="1">Ativo</option>
                    <option value="2">Inativo</option>
                  </select>
                </div> */}
                {!isMobile && (
                  <div className="pm-buttons-actions">
                    <button
                      type="button"
                      className="pm-back-btn"
                      onClick={() => navigate(-1)}
                    >
                      Voltar
                    </button>
                    <button type="submit" className="pm-save-btn">
                      Salvar
                    </button>
                  </div>
                )}
              </div>
            </div>
          </form>
          <div className="profile-grants">
            <div className="grants-header">
              <h2>Permissões</h2>
              <span>
                {isMobile ? <>Todas</> : <>Selecionar todas as permissões</>}
              </span>
              <div className="pm-checkbox">
                <input
                  type="checkbox"
                  id="selectAll"
                  onChange={(e) => {
                    const checked = e.target.checked
                    setSelectedGrants(() => {
                      if (checked) {
                        return grantCategories.flatMap((group) =>
                          group.categories.flatMap((category) =>
                            category.grants.map((grant) => ({
                              group: group.groupName,
                              category: category.categoryName,
                              grant: grant,
                            })),
                          ),
                        )
                      } else {
                        return []
                      }
                    })
                  }}
                />
                <label htmlFor="selectAll"></label>
              </div>
            </div>
            {grantCategories.map((group) => {
              return (
                <div key={group.groupName} className="grant-group">
                  <div className="group-name">
                    <img
                      src={group.icon}
                      onLoad={(e) => SVGInject(e.target)}
                      alt="Group Icon"
                    />
                    <h3>{t(`profilePage.` + group.groupName)}</h3>
                  </div>
                  {group.categories.map((category) => {
                    return (
                      <div
                        key={category.categoryName}
                        className="grant-category"
                      >
                        <div
                          className="grant-category-header"
                          onClick={() => toggleCollapse(category.categoryName)}
                        >
                          <div
                            style={
                              collapsedCategories[category.categoryName]
                                ? { transform: `rotate(0deg)` }
                                : { transform: `rotate(180deg)` }
                            }
                          >
                            <img
                              src={arrowDown}
                              onLoad={(e) => SVGInject(e.target)}
                              alt="arrow"
                              className="pm-arrow-down"
                            />
                          </div>
                          <h5>{t(`profilePage.` + group.groupName)}</h5>
                        </div>
                        {!collapsedCategories[category.categoryName] && (
                          <div className="grants-list">
                            {category.grants.map((grant, index) => {
                              const isSelected = selectedGrants.some(
                                (selectedGrant) =>
                                  selectedGrant.group === group.groupName &&
                                  selectedGrant.category ===
                                    category.categoryName &&
                                  selectedGrant.grant === grant,
                              )

                              return (
                                <div key={index} className="grant">
                                  <div className="pm-checkbox">
                                    <input
                                      type="checkbox"
                                      id={`grant-${category.categoryName}-${grant}`}
                                      checked={isSelected}
                                      onChange={() =>
                                        handleGrantChange(
                                          group.groupName,
                                          category.categoryName,
                                          grant,
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={`grant-${category.categoryName}-${grant}`}
                                    ></label>
                                  </div>
                                  <span>
                                    {grant ===
                                    `grant.banking.authorization-profile`
                                      ? t(`grant.banking.authorizationProfile`)
                                      : t(grant)}
                                  </span>
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              )
            })}
            {isMobile && (
              <div className="pm-buttons-actions">
                <button
                  type="button"
                  className="pm-back-btn"
                  onClick={() => navigate(-1)}
                >
                  Voltar
                </button>
                <button type="submit" className="pm-save-btn">
                  Salvar
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <SuccessModal
        isOpen={showSuccessModal}
        width={`500px`}
        height={`350`}
        onClose={() => {
          setShowSuccessModal(false)
          setSelectedGrants([])
          reset()
          navigate(`/operators-management`)
        }}
      >
        <h1 className="profile-modal-msg">
          {id ? `Perfil editado com sucesso` : `Perfil Criado Com Sucesso`}
        </h1>
      </SuccessModal>
      <ErrorModal
        isOpen={showErrorModal}
        width={`300px`}
        height={`auto`}
        onClose={() => setShowErrorModal(false)}
      >
        <h1 className="profile-modal-msg">Houve um erro ao criar o perfil</h1>
      </ErrorModal>
    </div>
  )
}
