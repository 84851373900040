import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"
import { useContext, useEffect, useState } from "react"
import { BMP_BANK_NUMBER } from "../../../../../assets/constants"
import { AuthContext } from "../../../../../context/AuthProvider"
import { BankingNavigationContext } from "../../../../../context/BankingNavigationContext"
import { TransferContext } from "../../../../../context/TransferContext"
import { formattedCurrency } from "../../../../../services/banking"
import { censorCnpj, censorCpf } from "../../../../../utils/validation"
import { CustomModal, SecondaryButton, PrimaryButton } from "k4n-components"
import ErrorModal from "../../../../features/ErrorModal"
import SuccessModal from "../../../../features/SuccesModal"
import TransferNav from "../transfer-nav"
import "./styles.scss"
import SVGInject from "@iconfu/svg-inject"
import DigitalSignature from "../../../DigitalSignature/Form"
import key from "../../../../../assets/images/key.svg"
import { BalanceContext } from "../../../../../context/BalanceProvider"
import { useResposive } from "../../../../../hooks/useResponsive"
import TransferStep4Mobile from "./mobile"
import services from "../../../../../services/svcmesh"
import { BmpTipoProcessamento } from "k4n-svcmesh-sdk"

dayjs.extend(isBetween)

const TransferStep4 = () => {
  const { isTabletOrMobile } = useResposive()
  const { transferStep, changeTransferStep, isScheduling } = useContext(
    BankingNavigationContext,
  )
  const { refreshBalance } = useContext(BalanceContext)

  useEffect(() => {
    console.log(isScheduling)
  }, [isScheduling])

  const {
    favoriteData,
    transferValue,
    transferStep3Data,
    addTransferTime,
    addTransactionId,
  } = useContext(TransferContext)
  const { user, current_account } = useContext(AuthContext)

  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [failureModalAlert, setFailureModalAlert] = useState(``)
  const [customModalAlert, setCustomModalAlert] = useState(``)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [digitalSignatureModal, setDigitalSignatureModal] = useState(false)
  const scheduleCondition =
    dayjs(transferStep3Data.date).isAfter(dayjs(), `d`) ||
    (dayjs().isBetween(
      dayjs().hour(0).minute(0).second(0).millisecond(0),
      dayjs().hour(7).minute(0).second(0).millisecond(0),
      `ms`,
    ) &&
      favoriteData.numero_banco !== BMP_BANK_NUMBER)

  const handleScheduleResSuccess = () => {
    setSubmitLoading(false)
    setShowSuccessModal(true)
    refreshBalance()
  }

  const handleResSuccess = (transactionId: string) => {
    console.log(`handleResSuccess transactionId ${transactionId}`)
    addTransactionId(transactionId)
    addTransferTime(dayjs().format(`DD/MM/YYYY - HH:mm:ss`))
    setSubmitLoading(false)
    setShowSuccessModal(true)
    refreshBalance()
  }

  const handleReqFail = (error: any) => {
    setFailureModalAlert(
      error?.response?.data?.message || error?.message || error,
    )
    setSubmitLoading(false)
  }

  const handleScheduledTed = async () => {
    try {
      if (user && user.id) {
        const res = await services.bankingService.scheduledTed({
          customer_pf: { id: user.id },
          vlr_transacao: transferValue,
          origem_agencia: current_account?.agencia,
          origem_agencia_digito: ``,
          origem_conta: current_account?.conta,
          origem_conta_digito: current_account?.contaDigito,
          origem_conta_pgto: `${current_account?.conta}${current_account?.contaDigito}`,
          origem_tipo_conta: current_account?.tipoConta,
          favorecido_numero_banco: Number(favoriteData.numero_banco),
          favorecido_documento_federal: favoriteData.cpf
            ? favoriteData.cpf
            : favoriteData.cnpj,
          favorecido_nome: favoriteData.nome,
          favorecido_agencia: favoriteData?.agencia,
          favorecido_agencia_digito: favoriteData?.agencia_digito,
          favorecido_conta: favoriteData?.conta,
          favorecido_conta_digito: favoriteData?.conta_digito,
          favorecido_conta_pgto: `${favoriteData?.conta}${favoriteData?.conta_digito}`,
          favorecido_tipo_conta: Number(favoriteData?.tipo_conta),
          tipo_processamento: BmpTipoProcessamento.STR,
          cod_operacao_cli: ``,
          desc_cliente: transferStep3Data.reason,
          finldd_if: 10,
          scheduled_date: dayjs(transferStep3Data.date).format(`YYYY-MM-DD`),
        })
        if (res?.sucesso) {
          handleScheduleResSuccess()
        }
      }
    } catch (error: any) {
      handleReqFail(error)
    }
  }

  const handleScheduledTransfer = async () => {
    try {
      if (user && user.id) {
        const res = await services.bankingService.scheduledTransfer({
          customer_pf: { id: user?.id },
          vlr_transacao: transferValue,
          origem_agencia: current_account?.agencia,
          origem_agencia_digito: ``,
          origem_conta: current_account?.conta,
          origem_conta_digito: current_account?.contaDigito,
          origem_conta_pgto: `${current_account?.conta}${current_account?.contaDigito}`,
          origem_tipo_conta: current_account?.tipoConta,
          destino_agencia: favoriteData?.agencia,
          destino_agencia_digito: favoriteData?.agencia_digito,
          destino_conta: favoriteData?.conta,
          destino_conta_digito: favoriteData?.conta_digito,
          destino_conta_pgto: `${favoriteData?.conta}${favoriteData?.conta_digito}`,
          destino_tipo_conta: Number(favoriteData?.tipo_conta),
          cod_operacao_cli: ``,
          desc_cliente: transferStep3Data.reason,
          finldd_cli: 0,
          scheduled_date: dayjs(transferStep3Data.date).format(`YYYY-MM-DD`),
        })
        if (res?.sucesso) {
          handleScheduleResSuccess()
        }
      }
    } catch (error: any) {
      handleReqFail(error)
    }
  }

  const handleTransfer = async () => {
    console.log(`handleTransfer user: `, user)
    console.log(`handleTransfer current_account: `, current_account)

    try {
      const res = await services.bankingService.transferBetweenBmp({
        origem: {
          agencia: current_account?.agencia,
          agenciaDigito: ``,
          conta: current_account?.conta,
          contaDigito: current_account?.contaDigito,
          contaPgto: `${current_account?.conta}${current_account?.contaDigito}`,
          tipoConta: current_account?.tipoConta,
        },
        destino: {
          agencia: favoriteData?.agencia,
          agenciaDigito: favoriteData?.agencia_digito,
          conta: favoriteData?.conta,
          contaDigito: favoriteData?.conta_digito,
          contaPgto: `${favoriteData?.conta}${favoriteData?.conta_digito}`,
          tipoConta: Number(favoriteData?.tipo_conta),
        },
        vlrTransacao: transferValue,
        finlddCli: 0,
        codOperacaoCli: ``,
        descCliente: transferStep3Data.reason,
      })
      console.log(`transferBetweenBmp res: `, res)
      if (current_account?.tipoPessoa === 1) {
        if (res?.sucesso) {
          handleResSuccess(res.codigoTransacao)
        }
      } else {
        // here we're dealing with PJ and the success represents an approval request instead
        // of a transaction success
        if (res?.sucesso) {
          setSubmitLoading(false)
          setShowSuccessModal(true)
        }
      }
    } catch (error: any) {
      handleReqFail(error)
    }
  }

  const handleTed = async () => {
    try {
      favoriteData.accountType = favoriteData.cpf ? `PF` : `PJ`
      const res = await services.bankingService.anotherBankTransfer({
        origem: {
          agencia: current_account?.agencia,
          agenciaDigito: ``,
          conta: current_account?.conta,
          contaDigito: current_account?.contaDigito,
          contaPgto: `${current_account?.conta}${current_account?.contaDigito}`,
          tipoConta: current_account?.tipoConta,
        },
        favorecido: {
          documentoFederal:
            favoriteData.accountType === `PF`
              ? favoriteData.cpf
              : favoriteData.cnpj,
          nome: favoriteData.nome,
          numeroBanco: Number(favoriteData.numero_banco),
          conta: {
            agencia: favoriteData?.agencia,
            agenciaDigito: favoriteData?.agencia_digito,
            conta: favoriteData?.conta,
            contaDigito: favoriteData?.conta_digito,
            contaPgto: `${favoriteData?.conta}${favoriteData?.conta_digito}`,
            tipoConta: Number(favoriteData?.tipo_conta),
          },
        },
        vlrTransacao: transferValue,
        finlddIF: 10,
        tipoProcessamento: BmpTipoProcessamento.STR,
        codOperacaoCli: ``,
        descCliente: transferStep3Data.reason,
      })
      console.log(`favoriteData.accountType`, favoriteData)
      if (res?.sucesso) {
        handleResSuccess(res.codigoTransacao)
      }
    } catch (error: any) {
      handleReqFail(error)
    }
  }

  const handleSubmit = async () => {
    setSubmitLoading(true)
    if (favoriteData.numero_banco === BMP_BANK_NUMBER) {
      if (dayjs(transferStep3Data.date).isAfter(dayjs(), `d`)) {
        await handleScheduledTransfer()
      } else {
        await handleTransfer()
      }
    } else {
      // outros bancos
      // se for PJ, desviar para fila de aprovação, mas isso quem faz é o backend...
      if (
        current_account?.tipoPessoa != 2 /**not PJ*/ &&
        dayjs(transferStep3Data.date).isAfter(dayjs(), `d`)
      ) {
        await handleScheduledTed()
      } else if (
        current_account?.tipoPessoa != 2 /**not PJ*/ &&
        dayjs().isBetween(
          dayjs().hour(0).minute(0).second(0).millisecond(0),
          dayjs().hour(7).minute(0).second(0).millisecond(0),
          `ms`,
        )
      ) {
        setSubmitLoading(false)
        setCustomModalAlert(
          `Dado o horário, sua transferência será agendada para o dia ${dayjs(
            transferStep3Data.date,
          ).format(
            `DD/MM/YYYY`,
          )} dentro do intervalo de 07:00 às 16:30. Deseja continuar?`,
        )
        return
      } else {
        await handleTed() //backend knows how to deal with PJ and approvals
      }
    }
  }

  const authorize = async () => {
    setDigitalSignatureModal(false)
    handleSubmit()
  }

  return (
    <>
      {!isTabletOrMobile ? (
        <div className="transfer-container">
          <TransferNav step={transferStep} stepTitle="Nova transferência" />
          <CustomModal
            isOpen={digitalSignatureModal}
            onClose={() => {
              setDigitalSignatureModal(false)
            }}
            isBanking={true}
          >
            <div style={{ width: `120px`, margin: `0 auto` }}>
              <img
                src={key}
                alt="/"
                width="120"
                height="120"
                className="key-vector"
                onLoad={(e) => SVGInject(e.target)}
              />
            </div>
            <DigitalSignature handleSuccess={authorize} />
          </CustomModal>
          <div className="panel-content">
            <div className="panel-title">
              <span className="title-blue">Pagar</span>
            </div>
            <div className="step-4-info-div">
              <div className="step-4-info-div-flex">
                <div className="steps-value-info">
                  <h1 className="info-span-bold-16">Valor a pagar</h1>
                  <span className="info-span-grey-24">
                    {formattedCurrency(transferValue)}
                  </span>
                </div>
                <div className="info-div">
                  <h1 className="info-span-bold-16">Forma de pagamento:</h1>
                  <h1 className="info-span-grey-16">
                    {`Agência ${current_account?.agencia}-${
                      current_account?.agenciaDigito || `0`
                    } - Conta ${current_account?.conta}-${
                      current_account?.contaDigito
                    }`}
                  </h1>
                </div>
              </div>
              <div className="info-div">
                <h1 className="info-span-bold-16">Data do pagamento:</h1>
                <h1 className="info-span-grey-16">
                  {dayjs(transferStep3Data.date).format(`DD/MM/YYYY`)}
                </h1>
              </div>
            </div>
            <div className="transfer-contact-div">
              <div className="info-div">
                <h1 className="info-span-bold-16">Para</h1>
                <h1 className="info-span-grey-16">{favoriteData.nome}</h1>
              </div>
              <div className="info-div">
                <h1 className="info-span-bold-16">
                  {favoriteData.cpf ? `CPF:` : `CNPJ:`}
                </h1>
                <h1 className="info-span-grey-16">
                  {favoriteData.cpf
                    ? censorCpf(favoriteData.cpf)
                    : censorCnpj(favoriteData.cnpj || ``)}
                </h1>
              </div>
              <div className="info-div">
                <h1 className="info-span-bold-16">Agencia:</h1>
                <h1 className="info-span-grey-16">{`${favoriteData.agencia}-${
                  favoriteData.agencia_digito || `0`
                }`}</h1>
              </div>
              <div className="info-div">
                <h1 className="info-span-bold-16">Conta:</h1>
                <h1 className="info-span-grey-16">{`${favoriteData.conta}-${favoriteData.conta_digito}`}</h1>
              </div>
              <div className="info-div">
                <h1 className="info-span-bold-16">Instituição:</h1>
                <h1 className="info-span-grey-16">
                  {favoriteData.numero_banco} - {favoriteData.instituicao}
                </h1>
              </div>
            </div>
            <div className="transfer-step-3-btns-div">
              <PrimaryButton
                width={`245px`}
                height={`60px`}
                onClick={() => setDigitalSignatureModal(true)}
                disabled={submitLoading}
              >
                {submitLoading ? (
                  <div
                    className="spinner-border spinner-pix"
                    role="status"
                  ></div>
                ) : isScheduling ? (
                  `Agendar`
                ) : (
                  `Pagar`
                )}
              </PrimaryButton>
              <button
                className="cancel-btn"
                onClick={() => changeTransferStep(3)}
              >
                <span className="cancel-span">Voltar</span>
              </button>
            </div>
          </div>
          {showSuccessModal && (
            <SuccessModal
              isOpen={showSuccessModal}
              width={`700px`}
              height={`400px`}
              onClose={() => {
                changeTransferStep(1)
                setShowSuccessModal(false)
              }}
            >
              <h1 className="pix-modal-sucess-h1">
                {scheduleCondition || isScheduling ? (
                  <span>
                    Transferência{` `}
                    {current_account?.tipoPessoa == 2
                      ? `enviada para fila de aprovações`
                      : `agendada`}
                    {` `}
                    com <br /> sucesso!
                  </span>
                ) : (
                  <span>
                    Transferência{` `}
                    {current_account?.tipoPessoa == 2
                      ? `enviada para fila de aprovações`
                      : `realizada`}
                    {` `}
                    com <br /> sucesso!
                  </span>
                )}
              </h1>
              <div className="boleto-justify-center">
                {current_account?.tipoPessoa != 2 && (
                  <button
                    className="boleto-receipt-btn"
                    onClick={() => changeTransferStep(5)}
                  >
                    Ver comprovante
                  </button>
                )}
              </div>
            </SuccessModal>
          )}
          <ErrorModal
            isOpen={!!failureModalAlert}
            width={`937px`}
            height={`350px`}
            onClose={() => {
              setFailureModalAlert(``)
            }}
          >
            <h1 className="pix-modal-sucess-h1">{failureModalAlert}</h1>
          </ErrorModal>
          <CustomModal
            isOpen={!!customModalAlert}
            onClose={() => {
              setCustomModalAlert(``)
            }}
          >
            <div className="modal-message transfer-custom-modal">
              {customModalAlert}
            </div>
            <div className="transfer-schedule-alert">
              <SecondaryButton
                text="Não"
                width="200px"
                height="40px"
                OnClick={() => {
                  setCustomModalAlert(``)
                }}
              />
              <PrimaryButton
                width="200px"
                height="40px"
                onClick={() => {
                  setSubmitLoading(true)
                  setCustomModalAlert(``)
                  handleScheduledTed()
                }}
              >
                Sim
              </PrimaryButton>
            </div>
          </CustomModal>
        </div>
      ) : (
        <div className="transfer-mobile-wrapper">
          <TransferStep4Mobile
            digitalSignatureModal={digitalSignatureModal}
            setDigitalSignatureModal={setDigitalSignatureModal}
            authorize={authorize}
            submitLoading={submitLoading}
            showSuccessModal={showSuccessModal}
            scheduleCondition={scheduleCondition}
            setShowSuccessModal={setShowSuccessModal}
            failureModalAlert={failureModalAlert}
            setFailureModalAlert={setFailureModalAlert}
            customModalAlert={customModalAlert}
            setCustomModalAlert={setCustomModalAlert}
            setSubmitLoading={setSubmitLoading}
            handleScheduledTed={handleScheduledTed}
          />
        </div>
      )}
    </>
  )
}

export default TransferStep4
